<template>
  <div class="pt-1 pb-1">
    <!-- <v-label v-if="!haveToDecrypt">
      <span>
        <span v-if="!isPassword" :class="getDataTypeColoring()"> {{ translatedDataType }} </span>{{ ' ' + label }}</span>
    </v-label> -->
    <v-label>
      <span>
        <span v-if="!isPassword" :class="getDataTypeColoring()"> {{ translatedDataType }} </span>{{ ' ' + label }}</span>
    </v-label>
    <div class="d-flex justify-start align-left">
      <template v-if="!haveToDecrypt">
        <!-- <v-responsive class="ma-0 pa-0" :width="`${input.length}.5rem`"> -->

        <v-text-field
          ref="input"
          class="shrink"
          dense
          :type="type"
          :readonly="mode === 'view' || !canEdit"
          :value="value"
          @input="onInput"
          @blur="blur"
          @keydown="onInputKeydown"
          @click=";(isPassword ? showPassword : canEdit) && edit()"
        ></v-text-field>

        <template v-if="mode === 'view'">
          <!-- EVERYTHING ELSE -->
          <v-btn v-if="isPassword" v-tooltip="showPassword ? $t('password.lockHidePassword') : $t('password.lockShowPassword')" fab small class="d-inline ml-3" @click="togglePassword">
            <v-icon>{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }} </v-icon>
          </v-btn>
          <v-btn
            v-if="canRefresh && ((canGenerateWhenProvided && currentDataType.value === 'PROVIDED') || (canGenerate && currentDataType.value === 'GENERATED'))"
            v-tooltip="$t('account.identities.dialog.generateHint')" class="d-inline ml-3" fab small @click="forge"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <!-- <v-btn v-tooltip="'Specify value directly'" class="ml-3 d-inline" fab small @click="edit">
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->
        </template>

        <template v-if="!haveToDecrypt && canEdit">
          <v-btn v-if="inEditMode" ref="btnSave" v-tooltip="$t('account.identities.dialog.saveHint')" class="ml-3" fab small right top @click="save">
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn v-if="inEditMode" ref="btnCancel" v-tooltip="$t('account.identities.dialog.cancelHint')" class="ml-3" fab small right top @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </template>

      <template v-if="haveToDecrypt">
        <div class="decrypt-needed-text">
          <span v-html="$t('account.identities.dialog.authoriseAccessPrivateDate')"></span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!value"
          v-tooltip="$t('account.identities.dialog.decrypHint')" class="decrypt-needed-btn"
          fab small right top
          @click="decrypt"
        >
          <v-icon>mdi-key</v-icon>
        </v-btn>
      </template>

      <!-- 
      <v-btn
        v-if="canGenerate && currentDataType.value !== generatedMode.value"
        v-tooltip="$t(generatedMode.tooltipText)" fab small right
        top
        class="data-type-generated ml-3"
        @click="switchDataType(generatedMode)"
      >
        <v-icon>{{ generatedMode.icon }}</v-icon>
      </v-btn>

      
      <v-btn
        v-if="canProvide && currentDataType.value !== providedMode.value"
        v-tooltip="$t(providedMode.tooltipText)" fab small right
        top
        class="data-type-provided ml-3"
        @click="switchDataType(providedMode)"
      >
        <v-icon>{{ providedMode.icon }}</v-icon>
      </v-btn>

      
      <v-btn
        v-if="canBeReal && currentDataType.value !== realMode.value"
        v-tooltip="$t(realMode.tooltipText)" fab small right
        top
        class="data-type-real ml-3"
        @click="switchDataType(realMode)"
      >
        <v-icon>{{ realMode.icon }}</v-icon>
      </v-btn> -->

      <data-type-toggle-outline v-if="!forceHiddenTypeToggle"
        class="pl-3"
        :default-data-type="currentDataType.value"
        :can-be-generated="canGenerate"
        :can-be-provided="canProvide"
        :can-be-real="canBeReal"
        @datatypechanged="switchDataType"
      ></data-type-toggle-outline>

      <v-spacer></v-spacer>
    </div>
  </div>
</template>

<script>
import identityDataTypes from './identityDataTypes.js'
export default {
  name: 'IdentityCardItem',
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: String,
      default: '',
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      required: true
    },
    canGenerate: {
      type: Boolean,
      default: true
    },
    canGenerateWhenProvided: {
      type: Boolean,
      default: false
    },
    canProvide: {
      type: Boolean,
      default: false
    },
    canBeReal: {
      type: Boolean,
      default: false
    },
    canForge: {
      type: Boolean,
      default: false
    },
    isPassword: {
      type: Boolean,
      default: false
    },
    identityEncrypted: {
      type: Boolean,
      default: false
    },
    defaultDataType: {
      type: String,
      required: false,
      default: 'GENERATED'
    },    
    canRefresh: {
      type: Boolean,
      required: false,
      default: true
    },
    forceHiddenTypeToggle: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: ['forge', 'save', 'datatypechanged', 'decrypt'],
  data() {
    return {
      // underEdit: this.value,
      underEdit: '',
      mode: 'view',
      inEditMode: false,
      showPassword: false,
      generatedMode: identityDataTypes[0],
      providedMode: identityDataTypes[1],
      realMode: identityDataTypes[2],
      customMode: identityDataTypes[3],
      noneMode: identityDataTypes[4],
      currentDataType: identityDataTypes.find(x => x.value === this.defaultDataType)
    }
  },
  computed: {
    type() {
      // console.log('computed type', !this.isPassword || this.mode === 'edit' || this.showPassword)
      // console.log('computed type ispassword: ' + this.isPassword + ' showPassword: ' + this.showPassword)
      if (!this.isPassword) {
        return 'text'
      }
      /* return !this.isPassword || this.mode === 'edit' || this.showPassword
        ? 'text'
        : 'password' */
      return this.mode === 'edit' || this.showPassword ? 'text' : 'password'
    },
    haveToDecrypt() {
      const have2decrypt = this.currentDataType ? (this.currentDataType.value === 'REAL' && this.identityEncrypted) : false
      // console.log('haveToDecrypt', have2decrypt)
      return have2decrypt
    },
    translatedDataType() {
      if (this.currentDataType.value === this.providedMode.value) {
        return this.$t('account.identities.dialog.providedDatatype')
      }
      if (this.currentDataType.value === this.generatedMode.value) {
        return this.$t('account.identities.dialog.generatedDatatype')
      }
      if (this.currentDataType.value === this.realMode.value) {
        return this.$t('account.identities.dialog.realDatatype')
      }
      if (this.currentDataType.value === this.customMode.value) {
        return this.$t('account.identities.dialog.customDatatype')
      }
      if (this.currentDataType.value === this.noneMode.value) {
        return this.$t('account.identities.dialog.noneDatatype')
      }
      return ''
    }
  },
  watch: {
    defaultDataType(newType) {
      // console.log('defaultDataType watch, newType', newType)
      this.currentDataType = identityDataTypes.find(x => x.value === newType)
    }
  },
  /* async mounted() {
    console.log('identycarditem mounte, label: ' + this.label + ' value: ' + this.value + ' mode: ' + this.mode + ' canEdit: ' + this.canEdit + ' canGenerate: ' + this.canGenerate + ' canProvide: ' + this.canProvide + ' canBeReal: ' + this.canBeReal + ' identityEncrypted: ' + this.identityEncrypted)
  }, */
  methods: {
    async edit() {
      this.mode = 'edit'
      this.underEdit = ''
      this.inEditMode = true
      await this.focusInput()
    },
    blur(event) {
      // console.log('blur event', event)
      /* console.log('event.relatedTarget', event.relatedTarget)
      console.log('btnSave ref', this.$refs.btnSave)
      console.log('btnCancel ref', this.$refs.btnCancel) */
      const isSaveButton = (this.$refs.btnSave ? event.relatedTarget === this.$refs.btnSave.$el : false)
      const isCancelButton = (this.$refs.btnCancel ? event.relatedTarget === this.$refs.btnCancel.$el : false)
      /* if (isSaveButton) {
        console.log('target is btnSave')
      } else {
        console.log('target is not btnSave')
      }
      if (isCancelButton) {
        console.log('target is btnCancel')
      } else {
        console.log('target is not btnCancel')
      } */
      if (
        isSaveButton ||
        isCancelButton
      ) {
        return
      }

      this.mode = 'view'
    },
    async forge() {
      // console.log('field forge')
      this.$emit('forge')
    },
    async save() {
      // console.log('field save', this.underEdit)
      this.$emit('save', this.underEdit)
      this.underEdit = ''
      this.inEditMode = false
      this.mode = 'view'
      if (this.showPassword) this.showPassword = false
    },
    cancel() {
      this.mode = 'view'
      this.inEditMode = false
      this.underEdit = ''
    },
    onInput(value) {
      // console.log('onInput event', value)
      if (value) {
        this.underEdit = value
      }
    },
    onInputKeydown(event) {
      // console.log('onInputKeydown event', event)
      if (this.mode === 'view') {
        return
      }

      if (event.code === 'Enter') {
        this.save()
      } else if (event.code === 'Escape') {
        event.preventDefault()
        this.cancel()
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    focusInput() {
      this.$nextTick(() => {
        const theElement = this.$refs.input
        const input = theElement.$el.querySelector('input:not([type=hidden]),textarea:not([type=hidden])')

        /* this.$refs.input.focus()
        const end = this.underEdit.length
        this.$refs.input.setSelectionRange(end, end) */

        // const end = this.underEdit.length
        const end = input.value.length
        input.setSelectionRange(end, end)
        input.focus()
      })
    },
    switchDataType(mode) {
      // console.log('switchDataType', mode)
      this.currentDataType = mode
      this.$emit('datatypechanged', this.currentDataType.value)
    },
    getDataTypeColoring() {
      if (!this.currentDataType) { return 'tertiary' }
      switch (this.currentDataType.value) {
        case 'REAL':
          return 'data-type-real'
        case 'PROVIDED':
          return 'data-type-provided'
        case 'GENERATED':
          return 'data-type-generated'
        case 'CUSTOM':
          return 'data-type-custom'
        case 'NONE':
          return 'data-type-none'
        default:
          return 'tertiary'  
      }
    },
    decrypt() {
      // console.log('decrypt requested')
      this.$emit('decrypt')
      // console.log('decrypt emitted')
    }
  }
}
</script>

<style scoped>
  p {
    margin: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 34px;
    white-space: pre;
  }

  v-fab {
    --color: var(--v-text-color);
    --background: none;
    --box-shadow: none;
    margin-left: 0;
    margin-right: 0;
  }

</style>

<style>
  .data-type-real {
    --color: var(--v-dataTypeReal-base);
    color: var(--v-dataTypeReal-base);
    z-index: 100;
  }
  .data-type-generated {
    --color: var(--v-generated-base);
    color: var(--v-generated-base);
    z-index: 100;
  }
  .data-type-provided {
    --color: var(--v-provided-base);
    color: var(--v-provided-base);
    z-index: 100;
  }
  .data-type-custom {
    --color: var(--v-tertiary-base);
    color: var(--v-tertiary-base);
    z-index: 100;
  }  
  .data-type-none {
    --color: var(--v-none-base);
    color: var(--v-none-base);
    z-index: 100;
  }  
  .decrypt-needed-btn {
    z-index: 100;
    color: var(--v-secondary) !important;
  }
  .decrypt-needed-text {
    color: var(--v-step350-base);
    font-size: 14px;
    margin: auto auto;
  }
</style>
