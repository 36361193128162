import Roles from '../../Roles'

export default [{
  value: 'IDENTITY',
  text: 'Only have access to your forged identities',
  roles: [Roles.ADMIN, Roles.USER]

}, {
  value: 'USER',
  text: 'Have access for all your identities',
  roles: [Roles.ADMIN, Roles.USER]

}, {
  value: 'SERVICE',
  text: ' be a Service client',
  roles: [Roles.ADMIN]
}]
