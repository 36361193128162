import createAxios from './api'

const PATH = 'server'
export default {
  async ping() {
    const axios = await createAxios(false)
    await axios.get(`${PATH}/ping`)
  },
  async getCurrentTime() {
    const axios = await createAxios(false)

    const resp = await axios.get(`${PATH}/currentTime`)
    return resp.data
  },
  async getMetrics() {
    const axios = await createAxios(false)
    const resp = await axios.get(`${PATH}/metrics`)
    return resp.data
  },
  async getOpenIdConfig() {
    const axios = await createAxios(false)
    const resp = await axios.get('/.well-known/openid-configuration')
    return resp.data
  },
  async getCountries() {
    const axios = await createAxios(false)
    const resp = await axios.get(`${PATH}/countries`)
    return resp.data
  },
  async getVersion() {
    const axios = await createAxios(false)
    const resp = await axios.get(`${PATH}/version`)
    return resp.data
  }
  
}
