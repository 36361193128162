<template>
  <v-container fluid>
    <v-dialog v-model="visible" max-width="900px" @input="backToParent($event)">
      <v-card v-if="selectedIdentity" class="px-3 py-3">
        <v-card-title v-if="!loading && selectedIdentity" primary-title class="justify-center pt-8">
          <v-row>
            <v-col cols="12" sm="6" align="center" justify="center" class="pt-0">
              <v-badge v-tooltip="$t('account.identities.dialog.forgeImageHint')" avatar bordered overlap
                class="newImageBadge pb-3" offset-x="20" icon="mdi-refresh" @click.native="forgeNewImage">
                <v-avatar v-if="!loading && selectedIdentity" size="128" class="avatar mr-3">
                  <v-img :key="imageKey" :src="`https://i.pravatar.cc/64?u=${selectedIdentity.imageId}`" />
                </v-avatar>
              </v-badge>

              <v-btn v-if="selectedIdentity.phoneNumberType === 'PROVIDED'"
                v-tooltip="$t('account.identities.dialog.clickHereToGoSMSWall')" elevation="6" class="ml-3 mr-3" fab icon
                small :to="smswallUrl">
                <v-icon>mdi-message-text</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0">
              <v-text-field ref="displayNameInput" v-tooltip="$t('account.identities.dialog.clickHereToChange')"
                class="shrink display-name-title text-h3" dense flat solo type="text" :readonly="!canEdit"
                :value="selectedIdentity.displayName" @input="onInput" @blur="blur"
                @keydown="onInputKeydown"></v-text-field>
              <v-btn v-if="underEdit" ref="btnSave" class="ml-3" fab small right top @click="saveDisplayName">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn v-if="underEdit" ref="btnCancel" class="ml-3" fab small right top @click="cancel">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-card-subtitle>
                {{ $t("account.identities.dialog.editInstructions") }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text v-if="!loading" class="pt-0 mt-0">
          <!-- Identity details -->
          <v-list class="item-list">
            <!-- Team -->
            <v-list-item v-if="selectedIdentity && selectedIdentity.team" class="pl-0">
              <v-list-item-title>
                <v-label>Team</v-label>
                <p>{{ selectedIdentity.team.displayName }}</p>
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="pl-0 pb-3 mt-0 mb-0">
              <v-list-item-title>
                <v-row>
                  <v-col cols="12">
                    <v-label>{{
                      $t("account.identities.dialog.domains")
                    }}</v-label>
                    <div>
                      <div v-if="!isEditing" class="d-flex pt-3 pb-3 wrapped">
                        <template v-if="selectedIdentity &&
                          selectedIdentity.domains &&
                          selectedIdentity.domains.length > 0
                          ">
                          <v-chip v-for="(chipText, dindex) in domainToShow" :key="dindex" class="ma-1"
                            @click="changeToEditMode()">
                            {{ chipText.url }}
                          </v-chip>
                        </template>
                        <template v-if="haveToShowMore">
                          <div class="justify-center align-center">
                            <a style="text-decoration: none !important" @click="
                              viewMoreDomainsVisible = !viewMoreDomainsVisible
                              ">{{
    viewMoreDomainsVisible
    ? $t("main.showLess")
    : $t("main.showAll")
  }}</a>
                            <v-btn size="small" icon @click="
                            viewMoreDomainsVisible = !viewMoreDomainsVisible
                              ">
                              <v-icon color="text" size="small">
                                {{
                                  !viewMoreDomainsVisible
                                  ? "mdi-chevron-down"
                                  : "mdi-chevron-up"
                                }}
                              </v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </div>
                      <v-text-field v-if="isEditing" ref="identityDomainInput" v-model="editedDomain" readonly
                        @blur="OnDomainEditBlur">
                        <template #prepend-inner>
                          <div class="d-flex wrapped">
                            <div v-for="(
                                chipText, dindex
                              ) in selectedIdentity.domains" :key="dindex">
                              <v-chip class="ma-1" close @click:close="deleteDomain(chipText, dindex)">
                                {{ chipText }}
                              </v-chip>
                            </div>
                          </div>
                        </template>
                      </v-text-field>
                      <v-menu v-model="menuAddDomain" :close-on-content-click="false" :open-on-click="true"
                        nudge-width="200" max-width="400" offset-x>
                        <template #activator="{ on, attrs }">
                          <v-btn v-if="canEdit" ref="btnDomainAddButton" v-tooltip="$t('account.identities.dialog.addDomainHint')
                            " elevation="6" class="ml-3 mb-3" fab icon small v-bind="attrs" v-on="on">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title primary-title class="text-center pt-8">
                            {{ $t("account.identities.dialog.addDomainTitle") }}
                          </v-card-title>
                          <v-card-subtitle>{{
                            $t(
                              "account.identities.dialog.addDomainInstructions"
                            )
                          }}</v-card-subtitle>
                          <v-card-text class="text-center">
                            <v-text-field v-model="newDomainName" :label="$t('account.identities.dialog.domainName')
                              "></v-text-field>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn text @click="
                              (menuAddDomain = false), (newDomainName = '')
                              ">
                              {{ $t("main.cancel") }}
                            </v-btn>
                            <v-btn color="primary" :disabled="!newDomainName" text @click="addNewDomain(newDomainName)">
                              {{ $t("account.identities.dialog.addDomain") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>

            <v-row class="mb-0 pb-0">
              <v-col cols="12" sm="6" class="pb-0 mb-0">
                <!-- Name Type -->
                <IdentityCardItem  :key="nameKey"
                  :label="$t('account.identities.dialog.userinfo.dataTypeName')" :value="selectedIdentity.name"
                  :can-edit="canEdit && nameType === 'GENERATED'" :can-generate="true" :can-refresh="gender != null" :can-be-real="userMeta.hasRealName"
                  :default-data-type="selectedIdentity.nameType" :identity-encrypted="selectedIdentity.encrypted" toggle-name="name"
                  @forge="forgeNewName" @save="saveName" @datatypechanged="changeNameType" @decrypt="decrypt" />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0 mb-0">
                <!-- Username -->
                <IdentityCardItem :key="usernameKey" :label="$t('account.identities.dialog.userinfo.dataTypeUsername')
                  " :value="selectedIdentity.username" :can-edit="canEdit" :can-generate="true" toggle-name="username"
                  :identity-encrypted="selectedIdentity.encrypted" @forge="forgeNewUsername" @save="saveUsername" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="pt-0 pb-0 mt-0 mb-0">
                <!-- Gender -->
                <v-list-item class="pl-0">
                  <v-list-item-title>
                    <v-label>{{
                      $t("account.identities.dialog.userinfo.gender")
                    }}</v-label>
                    <template v-if="canEdit">
                      <div class="d-flex justify-start align-left">
                        <v-select v-model="gender" class="shrink" :items="genderOptions" @change="genderChanged">
                        </v-select>
                      </div>
                    </template>
                    <template v-else>
                      <p class="text-capitalize">
                        {{ selectedIdentity.gender }}
                      </p>
                    </template>
                  </v-list-item-title>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0 mt-0 mb-0">
                <!-- Birthdate -->
                <v-list-item class="pl-0">
                  <v-list-item-title>
                    <BirthDateSelection :key="birthDateKey" :default-data-type="selectedIdentity.birthdateType"
                      :label="$t('account.identities.dialog.birthdate')" :value="selectedIdentity.birthdate" :can-edit="canEdit &&
                        (birthdateType === 'GENERATED' ||
                          birthdateType === 'NONE')
                        " :can-generate="true" :can-provide="false" :can-be-real="userMeta.hasRealBirthdate"
                      :identity-encrypted="selectedIdentity.encrypted" @datatypechanged="changeBirthDateType"
                      @forge="forgeNewBirthDate" @save="saveBirthDate" @decrypt="decrypt"></BirthDateSelection>

                    <!-- <data-type-toggle-outline
                          :default-data-type="selectedIdentity.birthdateType"
                          :can-be-generated="true"
                          :can-be-provided="false"
                          :can-be-real="true"
                          @datatypechanged="changeBirthDateType"
                        ></data-type-toggle-outline> -->
                  </v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row class="mt-0 mb-0 pb-0">
              <!-- Email Type -->
              <v-col cols="12" sm="6" class="pt-0 pb-0 mt-0 mb-0">
                <IdentityCardItem  :key="emailKey" :label="$t('account.identities.dialog.userinfo.dataTypeEmail')
                  " :value="selectedIdentity.email" :can-edit="canEdit &&
    (emailType === 'PROVIDED' || emailType === 'CUSTOM')
    " :can-generate="false" :can-provide="true" :can-generate-when-provided="true"
                  :can-be-real="userMeta.hasRealEmail" :default-data-type="selectedIdentity.emailType"
                  :identity-encrypted="selectedIdentity.encrypted" @forge="forgeNewEmail"
                  @datatypechanged="changeEmailType" @save="saveEmail" @decrypt="decrypt" />
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0 mt-0 mb-0">
                <!-- Password -->
                <IdentityCardItem :key="passwordKey" :label="$t('account.identities.dialog.userinfo.dataTypePassword')
                  " :value="selectedIdentity.password" :can-edit="canEdit" :is-password="true" :can-generate="false" toggle-name="password"
                  @save="savePassword" />
              </v-col>
            </v-row>

            <v-banner single-line height="10" class="mb-6 mt-0 pt-0">
            </v-banner>

            <!-- Phone Number Type -->

            <v-row>
              <v-col cols="12">
                <v-list-item class="pl-0">
                  <v-list-item-title>
                    <v-row>
                      <v-col cols="6" sm="4" md="3">
                        <v-label>{{
                          $t(
                            "account.identities.dialog.userinfo.phoneNumberType"
                          )
                        }}</v-label>
                        <template v-if="canEdit">
                          <v-select v-model="phoneNumberType" :items="phoneNumberTypeOptions" @change="phoneTypeChanged">
                          </v-select>
                        </template>
                        <template v-else>
                          <p class="text-capitalize">
                            {{ phoneNumberType.toLowerCase() }}
                          </p>
                        </template>
                      </v-col>
                      <v-col v-if="!phoneDetailsVisible" align="bottom" justify="center" cols="8">
                        <div v-if="haveToDecryptPhone" class="d-flex align-center">
                          <div class="decrypt-needed-text-inline">
                            <span v-html="$t(
                              'account.identities.dialog.authoriseAccessPrivateDate'
                            )
                              "></span>
                          </div>
                          <v-btn v-tooltip="$t('account.identities.dialog.decrypHint')
                            " class="ml-3 mt-3 mb-3 decrypt-needed-btn" fab small right top @click="decrypt">
                            <v-icon>mdi-key</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col v-if="phoneDetailsVisible && phoneIsProvidedOrREAL" cols="12" sm="7" md="5">
                        <template v-if="phoneNumberType !== 'NONE'">
                          <div v-if="phoneNumberType !== 'GENERATED'" class="pl-0">
                            <v-label :color="!phoneNumberId ? 'red darken-4' : null">
                              {{
                                $t(
                                  "account.identities.dialog.userinfo.phoneNumberLabel"
                                )
                              }}
                            </v-label>
                            <div class="d-flex justify-start align-left">
                              <template v-if="canEdit">
                                <v-select v-model="phoneNumberId" class="shrink" :items="phoneNumbers" item-text="label"
                                  item-value="id" :placeholder="$t('account.identities.dialog.selectOne')
                                    " menu-props="auto" @change="phoneNumberLabelChanged"></v-select>
                              </template>
                              <template v-else>
                                <p>{{ selectedIdentity.phoneNumberLabel }}</p>
                              </template>

                              <v-btn v-if="phoneNumberType === 'PROVIDED' &&
                                phoneNumberId
                                " v-tooltip="$t(
    'account.identities.dialog.clickHereToGoSMSWall'
  )
    " elevation="6" class="ml-3 mr-3" fab icon small target="_blank" :to="smswallUrl">
                                <v-icon>mdi-message-text</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </template>
                      </v-col>
                      <v-col v-if="phoneDetailsVisible &&
                        ((phoneIsProvidedOrREAL && phoneNumberId) ||
                          (!phoneIsProvidedOrREAL && phoneNumber))
                        " :cols="phoneNumberType == 'GENERATED' ? 6 : 12" md="4">
                        <IdentityCardItem v-if="phoneNumberType === 'GENERATED' || phoneNumberId
                          " :key="phoneNumberKey" :label="$t('account.identities.dialog.userinfo.phoneNumber')" :value="phoneNumber" :can-edit="canEdit && phoneNumberType === 'GENERATED'"
                          :can-generate="phoneNumberType === 'GENERATED'" :default-data-type="phoneNumberType" :force-hidden-type-toggle="true" toggle-name="phoneNumberType"
                          @forge="forgeNewPhoneNumber" @save="savePhoneNumber" />
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>

            <!-- Address Type -->

            <v-row>
              <v-col cols="12" align-center>
                <v-list-item class="pl-0">
                  <v-list-item-title>
                    <v-row>
                      <v-col cols="6" sm="4">
                        <v-label>{{
                          $t("account.identities.dialog.userinfo.addressType")
                        }}</v-label>
                        <template v-if="canEdit">
                          <v-select v-model="addressType" :items="addressTypeOptions" @change="addresTypeChanged">
                          </v-select>
                        </template>
                        <template v-else>
                          <p class="text-capitalize">
                            {{ addressType.toLowerCase() }}
                          </p>
                        </template>
                      </v-col>
                      <v-col v-if="!(
                          addressType !== 'REAL' ||
                          (addressType === 'REAL' &&
                            !selectedIdentity.encrypted)
                        )
                        " align="bottom" justify="center" cols="8">
                        <div v-if="haveToDecryptAddress" class="d-flex align-center">
                          <div class="decrypt-needed-text-inline">
                            <span v-html="$t(
                              'account.identities.dialog.authoriseAccessPrivateDate'
                            )
                              "></span>
                          </div>
                          <v-btn v-tooltip="$t('account.identities.dialog.decrypHint')
                            " class="ml-3 mt-3 mb-3 decrypt-needed-btn" fab small right top @click="decrypt">
                            <v-icon>mdi-key</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col v-if="addressType !== 'REAL' ||
                        (addressType === 'REAL' &&
                          !selectedIdentity.encrypted)
                        " align="bottom" justify="center" :cols="addressType === 'GENERATED' ? null : 6"
                        :sm="addressType === 'GENERATED' ? null : 3">
                        <template v-if="addressType !== 'NONE'">
                          <v-list-item v-if="addressType !== 'GENERATED'" class="pl-0">
                            <v-list-item-title :color="!selectedIdentity.addressId
                                ? 'red darken-4'
                                : null
                              ">
                              <v-label>{{
                                $t("account.identities.dialog.addressLabel")
                              }}</v-label>
                              <template v-if="canEdit">
                                <v-select v-model="selectedIdentity.addressId" :items="addresses" item-text="label"
                                  item-value="id" :placeholder="$t('account.identities.dialog.selectOne')
                                    " menu-props="auto" @change="addressIdChanged($event)"></v-select>
                              </template>
                              <template v-else>
                                <p>{{ selectedIdentity.addressLabel }}</p>
                              </template>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-col>
                      <v-col v-if="addressDetailsVisible" :cols="addressType != 'GENERATED' ? 6 : 6" sm="4" align="left"
                        justify="left">
                        <v-list-item class="pl-0">
                          <v-list-item-title>
                            <v-label v-if="addressDataType.value !== 'NONE'">
                              <span>
                                <span :class="getAddressDataTypeColoring()">
                                  {{
                                    translatedDataType(addressDataType.value)
                                  }} </span>{{
  " " + $t("account.personalSettings.country") 
}}
                              </span>
                            </v-label>

                            <template v-if="canEdit && addressType === 'GENERATED'">
                              <v-select id="country_name" v-model="selectedIdentity.countryCode" :label="$t('account.personalSettings.countrySelect')
                                " :items="countries" item-text="text" item-value="value" prepend-icon="mdi-map"
                                single-line required :rules="[rules.required]"
                                @change="saveAddress($event, 'COUNTRY_CODE')">
                              </v-select>
                            </template>
                            <template v-else>
                              <p>{{ selectedIdentity.country }}</p>
                            </template>
                          </v-list-item-title>
                        </v-list-item>
                      </v-col>
                      <v-col v-if="addressDetailsVisible" cols="4" sm="4" md="3">
                        <IdentityCardItem :key="postalCodeKey" :label="$t('account.identities.dialog.userinfo.postalCode')
                          " :value="selectedIdentity.postalCode" :can-edit="canEdit && addressType === 'GENERATED'"
                          :can-generate="false" :can-be-real="false" :default-data-type="addressType" toggle-name="postalCode"
                          @save="saveAddress($event, 'POSTAL_CODE')" />
                      </v-col>
                      <v-col v-if="addressDetailsVisible" cols="6" sm="4" md="3">
                        <IdentityCardItem :key="regionKey" :label="$t('account.identities.dialog.userinfo.region')
                          " :value="selectedIdentity.region" :can-edit="canEdit && addressType === 'GENERATED'"
                          :can-generate="false" :can-be-real="false" :default-data-type="addressType" toggle-name="region"
                          @save="saveAddress($event, 'REGION')" />
                      </v-col>
                      <v-col v-if="addressDetailsVisible" cols="6" sm="4" md="3">
                        <template v-if="addressType === 'GENERATED' ||
                          selectedIdentity.addressId
                          ">
                          <IdentityCardItem :key="localityKey" :label="$t('account.identities.dialog.userinfo.city')
                            " :value="selectedIdentity.locality" :can-edit="canEdit && addressType === 'GENERATED'"
                            :can-generate="false" :can-be-real="false" :default-data-type="addressType" toggle-name="locality"
                            @save="saveAddress($event, 'LOCALITY')" />
                        </template>
                      </v-col>
                      <v-col v-if="addressDetailsVisible" cols="12" md="3">
                        <template v-if="addressType === 'GENERATED' ||
                          selectedIdentity.addressId
                          ">
                          <IdentityCardItem :key="streetAddressKey" :label="$t(
                            'account.identities.dialog.userinfo.streetAddress'
                          )
                            " :value="selectedIdentity.streetAddress"
                            :can-edit="canEdit && addressType === 'GENERATED'" :can-generate="addressType === 'GENERATED'"
                            :can-be-real="false" :default-data-type="addressType" :force-hidden-type-toggle="true" toggle-name="streetAddress" @forge="forgeNewAddress"
                            @save="saveAddress($event, 'STREET_ADDRESS')" />
                        </template>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>

        <v-card-actions class="pt-3">
          <v-btn class="v-btn-back text-center" color="primary" @click="backClick">
            {{ $t("main.close") }}
          </v-btn>
        </v-card-actions>

        <v-spacer />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import server from "@/services/api/server.js";
import user from "@/services/api/user.js";
import identities from "@/services/api/identities.js";
import {
  showToastSuccess,
  showToastError,
  showConfirmationDialog,
  showConfirmationDialogWithCheckBox,
} from "@/event-bus";
// import IdentityCardItem from '@views/identity/IdentityCardItem.vue'
import IdentityCardItem from "@/components/IdentityCardItem.vue";
import identityDataTypes from "@/components/identityDataTypes.js";
import DataTypeToggleOutline from "@/components/DataTypeToggleOutline.vue";
import BirthDateSelection from "@/components/BirthDateSelection.vue";

export default {
  name: "UpdateIdentity",

  components: {
    IdentityCardItem,
  },

  data() {
    return {
      visible: false,
      mode: "view",
      underEdit: "",
      btnLoading: false,
      loading: true,
      updatingIdentity: false,
      displayName: "",
      displayNameInvalidAnimation: false,
      userhasRealData: false,
      menuAddDomain: false,
      userDataFormValid: false,
      imageKey: 0,
      usernameKey: 0,
      nameKey: 0,
      passwordKey: 0,
      genderKey: 0,
      birthDateKey: 0,
      emailKey: 0,
      phoneNumberKey: 0,
      streetAddressKey: 0,
      localityKey: 0,
      regionKey: 0,
      postalCodeKey: 0,
      newDomainName: "",
      minCharacters: 1,
      maxCharacters: 255,
      rules: {
        required: (v) =>
          !!v ||
          !isNaN(parseFloat(v)) ||
          this.$t("validation.form.requiredField"),
        pwdsMatches: (value) =>
          value === this.password || this.$t("validation.form.pwdsMustMatcH"),
      },
      teamId: "NONE",
      userMeta: {
        hasRealName: false,
        realAddresses: [],
        realPhoneNumbers: [],
      },
      genderOptions: [
        {
          text: this.$t("account.personalSettings.male"),
          value: "male",
          disabled: false,
        },
        {
          text: this.$t("account.personalSettings.female"),
          value: "female",
          disabled: false,
        },
      ],

      nameTypeOptions: [
        {
          text: "None",
          value: "NONE",
          disabled: false,
        },
        {
          text: "Generated",
          value: "GENERATED",
          disabled: false,
        },
        {
          text: "Real",
          value: "REAL",
          disabled: this.userMeta && !this.userMeta.hasRealName,
        },
      ],
      emailTypeOptions: [
        {
          text: "None",
          value: "NONE",
          disabled: false,
        },
        {
          text: "Provided",
          value: "PROVIDED",
          disabled: false,
        },
        {
          text: "Generated",
          value: "GENERATED",
          disabled: false,
        },
        {
          text: "Real",
          value: "REAL",
          disabled: this.userMeta && !this.userMeta.hasRealName,
        },
        {
          text: "Custom",
          value: "CUSTOM",
          disabled: true,
        },
      ],

      generatedMode: identityDataTypes[0],
      providedMode: identityDataTypes[1],
      realMode: identityDataTypes[2],
      customMode: identityDataTypes[3],
      countries: [],
      countrySelectorVisible: false,
      isEditing: false,
      editedDomain: "",
      nameType: "",
      emailType: "",
      phoneNumber: null,
      phoneNumberType: "",
      phoneNumberId: "",
      phoneNumberTypePrevious: null,
      addressType: "",
      addressDataType: identityDataTypes[0],
      addressId: "",
      gender: null,
      birthdate: "",
      birthdateModal: false,
      // canEdit: false,
      birthdateType: "",
      // birthdateType: identityDataTypes[0],
      viewMoreDomainsVisible: false,
      haveRealDatas: false,
      selectedIdentity: {
        nameType: "",
        emailType: "",
        phoneNumberType: "",
        phoneNumberId: "",
        addressType: "",
        addressId: "",
        id: "",
        canEdit: false,
        name: "",
        username: "",
        email: "",
        gender: null,
        birthdate: "",
        birthdateType: "",
        phoneNumber: null,
        postalCode: "",
      },
    };
  },
  computed: {
    canEdit() {
      return (
        this.mode === "view" &&
        this.selectedIdentity &&
        this.selectedIdentity.canEdit
      );
    },
    smswallUrl() {
      return "/smswall/" + this.selectedIdentity.phoneNumber;
    },
    haveToShowMore() {
      return (
        this.selectedIdentity &&
        this.selectedIdentity.domains &&
        this.selectedIdentity.domains.length > 4
      );
    },
    domainToShow() {
      if (!this.selectedIdentity.domains) {
        return undefined;
      }
      const domains = this.selectedIdentity.domains.map((x) => ({
        url: x,
        isClicked: false,
      }));

      if (domains.length <= 4 || this.viewMoreDomainsVisible) {
        return domains;
      }

      return [domains[0], domains[1], domains[2], domains[3]];
    },
    phoneIsProvidedOrREAL() {
      return (
        this.phoneNumberType === "PROVIDED" || this.phoneNumberType === "REAL"
      );
    },
    phoneNumbers() {
      if (this.userMeta) {
        return this.phoneNumberType === "PROVIDED"
          ? this.userMeta.providedPhoneNumbers
          : this.userMeta.realPhoneNumbers;
      }
      return [];
    },
    addresses() {
      if (this.userMeta) {
        /* return this.addressType === 'PROVIDED'
          ? this.userMeta.providedAddresses
          : this.userMeta.realAddresses */
        return this.userMeta.realAddresses;
      }
      return [];
    },
    haveToDecryptAddress() {
      // console.log('haveToDecryptAddress', this.addressType, this.selectedIdentity.encrypted)
      const have2decrypt =
        this.addressType === "REAL" && this.selectedIdentity.encrypted;
      // console.log('haveToDecrypt', have2decrypt)
      return have2decrypt;
    },
    haveToDecrypBirthdate() {
      // console.log('haveToDecryptAddress', this.addressType, this.selectedIdentity.encrypted)
      const have2decrypt =
        this.birthdateType === "REAL" && this.selectedIdentity.encrypted;
      // console.log('haveToDecrypt', have2decrypt)
      return have2decrypt;
    },
    haveToDecryptPhone() {
      //console.log("haveToDecryptPhone", "phoneNumberType",  this.phoneNumberType,  "encrypted",  this.selectedIdentity.encrypted );
      const have2decrypt =
        this.phoneNumberType === "REAL" && this.selectedIdentity.encrypted;
      // console.log('haveToDecrypt', have2decrypt)
      return have2decrypt;
    },
    addressDetailsVisible() {
      return (
        this.addressType !== "REAL" ||
        (this.addressType === "REAL" &&
          !this.selectedIdentity.encrypted &&
          this.selectedIdentity.addressId)
      );
    },
    phoneDetailsVisible() {
      // console.log('phoneDetailsVisible', this.phoneNumberType !== 'REAL' || (this.phoneNumberType === 'REAL' && !this.selectedIdentity.encrypted))
      return (
        this.phoneNumberType !== "REAL" ||
        (this.phoneNumberType === "REAL" && !this.selectedIdentity.encrypted)
      );
    },
    addressTypeOptions() {
      return [
        /* {
          text: 'None',
          value: 'NONE',
          disabled: false
        }, */
        {
          text: this.$t("account.identities.dialog.generatedDatatype"),
          value: "GENERATED",
          disabled: false,
        },
        /* {
          text: this.$t('account.identities.dialog.providedDatatype'),
          value: 'PROVIDED',
          disabled: this.userMeta && !this.userMeta.providedAddresses.length
        }, */
        {
          text: this.$t("account.identities.dialog.realDatatype"),
          value: "REAL",
          disabled: this.userMeta && this.userMeta.realAddresses.length === 0,
        },
      ];
    },
    phoneNumberTypeOptions() {
      return [
        /* {
          text: 'None',
          value: 'NONE',
          disabled: false
        }, */
        {
          text: this.$t("account.identities.dialog.generatedDatatype"),
          value: "GENERATED",
          disabled: false,
        },
        {
          text: this.$t("account.identities.dialog.providedDatatype"),
          value: "PROVIDED",
          disabled: this.userMeta && !this.userMeta.providedPhoneNumbers.length,
        },
        {
          text: this.$t("account.identities.dialog.realDatatype"),
          value: "REAL",
          disabled: this.userMeta && !this.userMeta.realPhoneNumbers.length,
        },
      ];
    },
  },
  watch: {
    /* 'selectedIdentity.canEdit': {
      async handler(newCanEdit) {
        //console.log('CanEdit changed, newNameType: ' + newCanEdit)
        this.canEdit = this.mode === 'view' && this.selectedIdentity.canEdit
      },
      deep: true
    }, */
    nameType: {
      async handler(newNameType, oldValue) {
        //console.log("WATCH nameType", newNameType, oldValue);
        const callDecrypt = this.shouldDecrypt(oldValue)
        if (newNameType !== "CUSTOM" && callDecrypt) {
          const updatedIdentity = await identities.updateName(
            this.selectedIdentity.id,
            {
              type: newNameType,
            },
            callDecrypt
          );
          if (updatedIdentity) {
            this.selectedIdentity = updatedIdentity;
          }
        }
      },
      deep: true,
    },
    emailType: {
      async handler(newEmailType, oldValue) {
        if (newEmailType !== "CUSTOM" ) {
          //console.log("WATCH emailtype handler", newEmailType, oldValue);
          const callDecrypt = this.shouldDecrypt(oldValue)
          if (callDecrypt) {
            const updatedIdentity = await identities.updateEmail(
              this.selectedIdentity.id,
              {
                type: newEmailType,
              },
              callDecrypt
            );
            if (updatedIdentity) {
              this.selectedIdentity = updatedIdentity;
            }
          }
        }
      },
      deep: true,
    },
    phoneNumberType: {
      async handler(newPhoneNumberType, oldValue) {
        //console.log( "WATCH phoneNumberType: " + newPhoneNumberType + " old: " + oldValue);
        const callDecrypt = this.shouldDecrypt(oldValue)
        //console.log( "WATCH phoneNumberType  this.phoneNumberId",this.phoneNumberId);
        //console.log( "WATCH phoneNumberType  this.selectedIdentity.phoneNumberId", this.selectedIdentity.phoneNumberId);
        //console.log( "WATCH phoneNumberType  this.phoneNumber",this.phoneNumber);
        //console.log( "WATCH phoneNumberType  this.selectedIdentity.phoneNumber",this.selectedIdentity.phoneNumber);
        if (
          newPhoneNumberType === "NONE" ||
          newPhoneNumberType === "GENERATED"
        ) {
          if (callDecrypt) {
            const updatedIdentity = await identities.updatePhoneNumber(
              this.selectedIdentity.id,
              {
                type: newPhoneNumberType,
              },
              callDecrypt
            );
            if (updatedIdentity) {
              this.selectedIdentity = updatedIdentity;
            }
          }
        } else {
          if (this.phoneNumbers.length === 1) {
            //console.log("WATCH phoneNumberType, changing to first item in combo",newPhoneNumberType);
            this.phoneNumberId = this.phoneNumbers[0].id;
          } else {
            // console.log('WATCH phoneNumberType changing phoneNumberId to', this.selectedIdentity.phoneNumberId)
            // this.phoneNumberId = this.selectedIdentity.phoneNumberId
            // changing from real to provided or inverse
            if (
              this.phoneNumberTypePrevious &&
              this.phoneNumberTypePrevious !== this.phoneNumberType
            ) {
              if (
                this.phoneNumberType === "REAL" ||
                this.phoneNumberType === "PROVIDED"
              ) {
                this.phoneNumberId = "";
              }
            } else {
              // initial value
              this.phoneNumberId = this.selectedIdentity.phoneNumberId;
            }
            this.phoneNumberTypePrevious = this.phoneNumberType;
          }
        }
      },
      deep: true,
    },
    phoneNumberId: {
      async handler(newPhoneNumberId, oldValue) {
        //console.log("WATCH phoneNumberId  this.selectedIdentity",this.selectedIdentity);
        //console.log( "WATCH phoneNumberId: " + newPhoneNumberId + " old: " + oldValue);
        /*console.log(
          "WATCH phoneNumberId  this.selectedIdentity.phoneNumberId",
          this.selectedIdentity.phoneNumberId
        );
        console.log(
          "WATCH phoneNumberId, newPhoneNumberId",
          newPhoneNumberId,
          oldValue
        );*/
        if (!newPhoneNumberId) {
          return;
        }

        const callDecrypt = this.shouldDecrypt(oldValue)
        //console.log("WATCH phoneNumberId  callDecrypt", callDecrypt);
        if (callDecrypt) {
          const updatedIdentity = await identities.updatePhoneNumber(
            this.selectedIdentity.id,
            {
              type: this.phoneNumberType,
              id: newPhoneNumberId,
            },
            callDecrypt
          );
          if (updatedIdentity) {
            this.selectedIdentity = updatedIdentity;
          }
        }
        /*console.log(
          "WATCH phoneNumberId  after change this.selectedIdentity",
          this.selectedIdentity
        );*/

        if (
          this.phoneNumberType === "REAL" ||
          this.phoneNumberType === "PROVIDED"
        ) {
          if (this.selectedIdentity)
            this.phoneNumber = this.selectedIdentity.phoneNumber;
        }
      },
    },
    deep: true,
  },
  addressType: {
    async handler(newAddressType, oldValue) {
      //console.log('WATCH addresType', newAddressType)
      /* if (newAddressType === 'NONE' || newAddressType === 'GENERATED') {
        this.selectedIdentity = await identities.updateAddress(this.selectedIdentity.id, {
          type: newAddressType
        })
      } else {
        if (this.addresses.length === 1) {
          this.selectedIdentity.addressId = this.addresses[0].id
        } else {
          this.selectedIdentity.addressId = ''
        }
      } */
      const callDecrypt = this.shouldDecrypt(oldValue)
      if (callDecrypt) {
        const updatedIdentity = await identities.updateAddress(
          this.selectedIdentity.id,
          {
            type: newAddressType,
            id: this.addressId.id,
          },
          callDecrypt
        );        
        if (updatedIdentity) {
          this.selectedIdentity = updatedIdentity
        }
      }
      //if (updatedIdentity) {
          this.postalCodeKey += 1
          this.regionKey += 1
          this.localityKey += 1
          this.streetAddressKey += 1
      //}
    },
    deep: true,
  },
  addressId: {
    async handler(newAddressId, oldValue) {
      if (!newAddressId) {
        return;
      }
      const callDecrypt = this.shouldDecrypt(oldValue)
      if (callDecrypt) {
        const updatedIdentity = await identities.updateAddress(
          this.selectedIdentity.id,
          {
            type: this.addressType,
            id: newAddressId,
          },
          callDecrypt
        );
        if (updatedIdentity) {
          this.selectedIdentity = updatedIdentity;
        }
      }
    },
    deep: true,
  },
  gender: {
    async handler(newGender, oldValue) {
      const callDecrypt = this.shouldDecrypt(oldValue)
      //console.log("GENDER WATCHER callDecrypt", callDecrypt)
      if (callDecrypt) {
        const updatedIdentity = await identities.updateGender(
          this.selectedIdentity.id,
          {
            type: "GENERATED",
            value: newGender,
          },
          callDecrypt
        );
        if (updatedIdentity) {
          this.selectedIdentity = updatedIdentity;
        }
      }
    },
    deep: true,
  },
  birthdate: {
    async handler(newBirthdate, oldValue) {
      const callDecrypt = this.shouldDecrypt(oldValue)
      if (callDecrypt) {
        const updatedIdentity = await identities.updateBirthdate(
          this.selectedIdentity.id,
          {
            type: "GENERATED",
            value: newBirthdate,
          },
          callDecrypt
        );
        if (updatedIdentity) {
          this.selectedIdentity = updatedIdentity;
        }
      }
    },
    deep: true,
  },
  async mounted() {
    this.userMeta = await user.getMyUserMeta();
    console.debug("meta", this.userMeta);
    this.haveRealDatas = this.userMeta.hasRealBirthdate || this.userMeta.hasRealEmail || this.userMeta.hasRealGender || this.userMeta.hasRealName || (this.userMeta.realPhoneNumbers.length > 0) || (this.userMeta.realAddresses.length > 0)
    this.selectedIdentity = await identities.getUserIdentity(
      this.$route.params.identityId
    );
    if (!this.selectedIdentity) {
      showToastError(this.$t("account.identities.identityLoadError"));
      return;
    }
    //console.log("identity mounted", this.selectedIdentity);
    const entries = await server.getCountries();
    this.countries = entries.flatMap((e) => {
      return { text: e.name, value: e.code };
    });
    // console.log('countries', this.countries)
    // console.log('canEdit', this.canEdit)

    this.nameType = this.selectedIdentity.nameType;
    this.emailType = this.selectedIdentity.emailType;
    this.phoneNumberType = this.selectedIdentity.phoneNumberType;
    this.phoneNumberTypePrevious = this.phoneNumberType;
    this.phoneNumberId = this.selectedIdentity.phoneNumberId;
    this.phoneNumber = this.selectedIdentity.phoneNumber;
    this.addressType = this.selectedIdentity.addressType;
    // console.log('Selected address datatype for', this.selectedIdentity.addressType)
    this.addressDataType = identityDataTypes.find(
      (x) => x.value === this.selectedIdentity.addressType
    );
    // console.log('this.addressDataType', this.addressDataType)
    this.addressId = this.selectedIdentity.addressId;
    this.gender = this.selectedIdentity.gender;
    this.birthdate = this.selectedIdentity.birthdate;
    this.birthdateType = this.selectedIdentity.birthdateType;
    this.loading = false;
    this.visible = true;

    //console.log("identy mounted, this.phoneNumberType", this.phoneNumberType);
    //console.log("identy mounted, this.phoneNumberId", this.phoneNumberId);
  },
  /* async beforeMount() {
    console.log('add identy before mounted')
  }, */
  methods: {
    back() {
      this.visible = false;
      this.$emit("identityRefresh");
      this.$router.back();
    },
    backClick() {
      this.visible = false;
      this.back();
    },

    backToParent(e) {
      if (!e) {
        this.$emit("identityRefresh");
        this.$router.go(-1);
      }
    },
    async getIdentity() {
      try {
        this.selectedUserInfo = identities.getUserIdentity(
          this.$route.params.identityId
        );
      } catch (error) {
        showToastError(this.getErrorMessage(error));
      }
    },

    async savePassword(value) {
      this.selectedIdentity = await identities.updatePassword(
        this.selectedIdentity.id,
        {
          value,
        },
        this.haveRealDatas
      );
    },
    async forgeNewName() {
      // console.log('forgeNewName called', this)
      //const decryptNeeded = this.selectedIdentity.encrypted
      this.selectedIdentity = await identities.updateName(
        this.selectedIdentity.id,
        {
          type: "GENERATED",
        },
        this.haveRealDatas
      );
      this.nameKey += 1;
    },

    async saveName(value) {
      //console.log("saveName", value);
      this.selectedIdentity = await identities.updateName(
        this.selectedIdentity.id,
        {
          type: "CUSTOM",
          value,
        },
        this.haveRealDatas
      );
    },

    async forgeNewUsername() {
      // console.log('forgeNewUsername called')
      this.selectedIdentity = await identities.updateUsername(
        this.selectedIdentity.id,
        {},
        this.haveRealDatas
      );
      this.usernameKey += 1;
    },

    async saveUsername(value) {
      this.selectedIdentity = await identities.updateUsername(
        this.selectedIdentity.id,
        {
          value,
        },
        this.haveRealDatas
      );
      // console.log('saveUsername after save', this.selectedIdentity)
    },

    async forgeNewEmail() {
      this.selectedIdentity = await identities.updateEmail(
        this.selectedIdentity.id,
        {
          type: "PROVIDED",
          // type: 'GENERATED'
        },
        this.haveRealDatas
      );
      this.emailKey += 1;
    },

    async saveEmail(value) {
      // console.log('saveEmail called', value)
      this.selectedIdentity = await identities.updateEmail(
        this.selectedIdentity.id,
        {
          type: "CUSTOM",
          value,
        },
        this.haveRealDatas
      );

      this.emailType = "CUSTOM";
    },

    async forgeNewImage() {
      this.selectedIdentity = await identities.updateImage(
        this.selectedIdentity.id,
        {},
        this.haveRealDatas
      );
      this.imageKey += 1;
    },

    async forgeNewAddress() {
      this.selectedIdentity = await identities.updateAddress(
        this.selectedIdentity.id,
        {
          type: "GENERATED",
        },
        this.haveRealDatas
      );
      this.streetAddressKey += 1
    },

    async saveAddress(value, field) {
      this.selectedIdentity = await identities.updateAddress(
        this.selectedIdentity.id,
        {
          type: "GENERATED",
          field,
          value,
        },
        this.haveRealDatas
      );
    },
    async genderChanged(newGender) {
      //console.log('genderChanged', newGender)
      const updatedIdentity = await identities.updateGender(
          this.selectedIdentity.id,
          {
            type: "GENERATED",
            value: newGender,
          },
          this.haveRealDatas
        );
        if (updatedIdentity) {
          this.selectedIdentity = updatedIdentity;
        }
    },
    async phoneTypeChanged(newPhoneNumberType) {
      //console.log('phoneTypeChanged type', newPhoneNumberType)
      if (
          newPhoneNumberType === "NONE" ||
          newPhoneNumberType === "GENERATED"
        ) {
            const updatedIdentity = await identities.updatePhoneNumber(
              this.selectedIdentity.id,
              {
                type: newPhoneNumberType,
              },
              this.haveRealDatas
            );
            if (updatedIdentity) {
              this.selectedIdentity = updatedIdentity;
              this.phoneNumber = this.selectedIdentity.phoneNumber;
            }
        } else {
          if (this.phoneNumbers.length === 1) {
            //console.log("WATCH phoneNumberType, changing to first item in combo",newPhoneNumberType);
            this.phoneNumberId = this.phoneNumbers[0].id;
            const updatedIdentity = await identities.updatePhoneNumber(
              this.selectedIdentity.id,
              {
                type: newPhoneNumberType,
                id: this.phoneNumberId
              },
              this.haveRealDatas
            );
            if (updatedIdentity) {
              this.selectedIdentity = updatedIdentity;
              this.phoneNumber = this.selectedIdentity.phoneNumber;
              this.phoneNumberTypePrevious = newPhoneNumberType
            }
          } else {
            // console.log('WATCH phoneNumberType changing phoneNumberId to', this.selectedIdentity.phoneNumberId)
            //console.log('phoneNumberType this.phoneNumberTypePrevious', this.phoneNumberTypePrevious)
            // this.phoneNumberId = this.selectedIdentity.phoneNumberId
            // changing from real to provided or inverse
            if (
              this.phoneNumberTypePrevious &&
              this.phoneNumberTypePrevious !== newPhoneNumberType
            ) {
              if (
                newPhoneNumberType === "REAL" ||
                newPhoneNumberType === "PROVIDED"
              ) {
                //console.log('phoneNumberType chaning to null')
                this.phoneNumberId = null;
                this.phoneNumber = ''
              }
            } else {
              //console.log('phoneNumberType initial value')
              // initial value
              this.phoneNumberId = this.selectedIdentity.phoneNumberId;
              //this.phoneNumber = ''
            }
            //this.phoneNumberTypePrevious = this.phoneNumberType;
            this.phoneNumberTypePrevious = newPhoneNumberType
          }
        }
    },    
    async addresTypeChanged(mode) {
      //console.log('addresTypeChanged type', mode)
      if (mode === this.generatedMode.value) {
        try {
          this.selectedIdentity = await identities.updateAddress(
            this.selectedIdentity.id,
            {
              type: "GENERATED",
            },
            this.haveRealDatas
          );
        } catch (error) { }
      } else {
        // real
        if (mode === this.realMode.value && this.addressId) {
          this.selectedIdentity = await identities.updateAddress(
            this.selectedIdentity.id,
            {
              type: "REAL",
              id: this.addressId,
            },
            this.haveRealDatas
          );
          this.addressId = this.selectedIdentity.addressId;
        }
      }
      if (mode === this.generatedMode.value) {
        this.addressDataType = this.generatedMode;
      }
      if (mode === this.realMode.value) {
        this.addressDataType = this.realMode;
      }
      // console.log('addresTypeChanged addressDataType', this.addressDataType.value)
      // console.log('addresTypeChanged selectedIdentity', this.selectedIdentity)
      // selectedIdentity.value.addressType = mode.value
    },
    async addressIdChanged(newAddressId) {
      //console.log('addressIdChanged', newAddressId)
      if (!newAddressId) {
        return;
      }

      this.selectedIdentity = await identities.updateAddress(
        this.selectedIdentity.id,
        {
          type: this.addressType,
          id: newAddressId,
        },
        this.haveRealDatas
      );
      // selectedIdentity.value.encrypted = false
    },
    async forgeNewBirthDate() {
      //console.log("forgeNewBirthDate");
      this.selectedIdentity = await identities.updateBirthdate(
        this.selectedIdentity.id,
        {
          type: "GENERATED",
        },
        this.haveRealDatas
      );
      this.birthDateKey += 1;
      //console.log(this.selectedIdentity);
    },
    async forgeNewPhoneNumber() {
      this.selectedIdentity = await identities.updatePhoneNumber(
        this.selectedIdentity.id,
        {
          type: "GENERATED",
        },
        this.haveRealDatas
      );
      this.phoneNumber = this.selectedIdentity.phoneNumber;
      this.phoneNumberKey += 1;
    },
    async changeBirthDateType(mode) {
      //console.log("changeBirthDateType", mode);
      this.birthdateType = mode;
      this.selectedIdentity = await identities.updateBirthdate(
        this.selectedIdentity.id,
        {
          type: this.birthdateType,
          value: this.selectedIdentity.birthdate,
        },
        this.haveRealDatas
      );
      this.birthDateKey += 1;
      this.birthdate = this.selectedIdentity
        ? this.selectedIdentity.birthdate
        : null;
    },
    async changePhoneType(newPhoneNumberType) {
      /*console.log("phonetyp changed", newType);
      console.log("phonetyp changed this.phoneNumberId", this.phoneNumberId);
      console.log(
        "phonetyp changed this.selectedIdentity",
        this.selectedIdentity
      );*/

      if (
        newPhoneNumberType === "NONE" ||
        newPhoneNumberType === "GENERATED"
      ) {
        const updatedIdentity = await identities.updatePhoneNumber(
          this.selectedIdentity.id,
          {
          type: newPhoneNumberType,
          },
          this.haveRealDatas
        );
        if (updatedIdentity) {
          this.selectedIdentity = updatedIdentity;
        }
      } else {
        if (this.phoneNumbers.length === 1) {
        //console.log("WATCH phoneNumberType, changing to first item in combo",newPhoneNumberType);
        this.phoneNumberId = this.phoneNumbers[0].id;
        } else {
        // console.log('WATCH phoneNumberType changing phoneNumberId to', this.selectedIdentity.phoneNumberId)
        // this.phoneNumberId = this.selectedIdentity.phoneNumberId
        // changing from real to provided or inverse
        if (
          this.phoneNumberTypePrevious &&
          this.phoneNumberTypePrevious !== this.phoneNumberType
        ) {
          if (
          this.phoneNumberType === "REAL" ||
          this.phoneNumberType === "PROVIDED"
          ) {
          this.phoneNumberId = "";
          }
        } else {
          // initial value
          this.phoneNumberId = this.selectedIdentity.phoneNumberId;
        }
        this.phoneNumberTypePrevious = this.phoneNumberType;
        }
      }

    },    
    async phoneNumberLabelChanged(newPhoneNumberId) {
      //console.log('phoneNumberLabelChanged changed', newPhoneNumberId)
      //this.phoneNumber = $event;
      this.selectedIdentity = await identities.updatePhoneNumber(
        this.selectedIdentity.id,
        {
          type: this.phoneNumberType,
          //id: this.selectedIdentity.phoneNumberId,
          id: newPhoneNumberId
        },
        this.haveRealDatas
      );
      this.phoneNumber = this.selectedIdentity.phoneNumber;
    },
    async saveBirthDate(value) {
      // console.log('saving phone number')
      this.selectedIdentity = await identities.updateBirthdate(
        this.selectedIdentity.id,
        {
          type: "GENERATED",
          value: value,
        },
        this.haveRealDatas
      );
    },
    async savePhoneNumber(value) {
      // console.log('saving phone number')
      this.selectedIdentity = await identities.updatePhoneNumber(
        this.selectedIdentity.id,
        {
          type: "GENERATED",
          value,
        },
        this.haveRealDatas
      );
    },
    async saveDisplayName() {
      this.selectedIdentity = await identities.updateDisplayName(
        this.selectedIdentity.id,
        {
          value: this.underEdit,
        },
        this.haveRealDatas
      );
      // console.log(underEdit.value)
      this.underEdit = ""
      this.mode = "view"
    },
    async edit() {
      this.mode = "edit"
      this.underEdit = ""
      this.inEditMode = true
      await this.focusInput()
    },
    cancel() {
      this.mode = "view";
      this.inEditMode = false;
      this.underEdit = "";
    },
    onInput(value) {
      // console.log('onInput event', value)
      if (value) {
        this.underEdit = value
      }
    },
    onInputKeydown(event) {
      // console.log('onInputKeydown event', event)
      if (this.mode === "view") {
        return;
      }

      if (event.code === "Enter") {
        this.saveDisplayName();
      } else if (event.code === "Escape") {
        event.preventDefault();
        this.cancel();
      }
    },
    focusInput() {
      this.$nextTick(() => {
        const theElement = this.$refs.displayNameInput;
        const input = theElement.$el.querySelector(
          "input:not([type=hidden]),textarea:not([type=hidden])"
        );

        /* this.$refs.input.focus()
        const end = this.underEdit.length
        this.$refs.input.setSelectionRange(end, end) */

        // const end = this.underEdit.length
        const end = input.value.length;
        input.setSelectionRange(end, end);
        input.focus();
      });
    },
    blur(event) {
      //console.log('blur event', event)
      const isSaveButton = this.$refs.btnSave
        ? event.relatedTarget === this.$refs.btnSave.$el
        : false;
      const isCancelButton = this.$refs.btnCancel
        ? event.relatedTarget === this.$refs.btnCancel.$el
        : false;
      /* if (isSaveButton) {
        console.log('target is btnSave')
      } else {
        console.log('target is not btnSave')
      }
      if (isCancelButton) {
        console.log('target is btnCancel')
      } else {
        console.log('target is not btnCancel')
      } */
      if (isSaveButton || isCancelButton) {
        return;
      }

      this.mode = "view";
    },
    OnDomainEditBlur(event) {
      // console.log('OnDomainEditBlur event', event)
      const isDomainAddButton = this.$refs.btnDomainAddButton
        ? event.relatedTarget === this.$refs.btnDomainAddButton.$el
        : false;
      // console.log('isDomainAddButton', isDomainAddButton)
      this.isEditing = !this.isEditing;
      if (isDomainAddButton) {
        // Open the menu after a small delay to ensure the field's blur event completes
        setTimeout(() => {
          this.menuAddDomain = true;
        }, 100);
      }
    },
    async changeNameType(newNameType) {
      console.log('changeNameType', newNameType)
      this.nameType = newNameType;
      if (newNameType !== "CUSTOM") {
          const updatedIdentity = await identities.updateName(
            this.selectedIdentity.id,
            {
              type: newNameType,
            },
            this.haveRealDatas
          );
          if (updatedIdentity) {
            this.selectedIdentity = updatedIdentity;
          }
        }
    },
    async changeEmailType(newEmailType) {
      console.log('changeEmailType', newEmailType)
      this.emailType = newEmailType;

      const updatedIdentity = await identities.updateEmail(
        this.selectedIdentity.id,
        {
          type: newEmailType,
        },
        this.haveRealDatas
      );
      if (updatedIdentity) {
        this.selectedIdentity = updatedIdentity;
      }
    },
    async decrypt() {
      // console.log('decrypting in progress')
      const updatedIdentity = await identities.getIdentity(
        this.$t("decryption.dataDecrypt"),
        this.selectedIdentity.id,
        true
      );
      if (updatedIdentity) {
        this.selectedIdentity = updatedIdentity

        /*console.log("after decrypt", this.selectedIdentity);
        // console.log({ this.selectedIdentity.phoneNumberType })
        console.log(
          "this.selectedIdentity.phoneNumberType",
          this.selectedIdentity.phoneNumberType
        );
        console.log(
          "this.selectedIdentity.phoneNumberId",
          this.selectedIdentity.phoneNumberId
        );
        console.log(
          "this.selectedIdentity.phoneNumber",
          this.selectedIdentity.phoneNumber
        );*/
        // this.phoneNumberType = this.selectedIdentity.phoneNumberType
        this.phoneNumberId = this.selectedIdentity.phoneNumberId;
        this.phoneNumber = this.selectedIdentity.phoneNumber;
        // console.log(this.selectedIdentity)
      } else {
        console.error("decrypt didn't returned identity object")
      }
    },

    async deleteDomain(domain, domainIndex) {
      // console.log('deleting domain: ' + domain)
      try {
        await identities.deleteDomain(this.selectedIdentity.id, domain);
        this.selectedIdentity.domains.splice(domainIndex, 1);
        this.isEditing = false;
        showToastSuccess(this.$t("account.identities.domainDeleteSucces"));
      } catch (error) {
        console.error(error);
        // console.log(error)
        showToastError(this.$t("account.personalSettings.errorWhileUpdating"));
      } finally {
        this.btnLoading = false;
      }
    },
    async addNewDomain(domain) {
      // console.log('adding domain: ' + domain)
      this.menuAddDomain = false;
      try {
        const req = {
          domain: domain,
        };
        await identities.addDomain(this.selectedIdentity.id, req);
        if (!this.selectedIdentity.domains) {
          // this.selectedIdentity.domains = []
          this.$set(this.selectedIdentity, "domains", []);
        }
        this.selectedIdentity.domains.push(domain);
        this.newDomainName = "";
        showToastSuccess(this.$t("account.identities.domainAddSucces"));
      } catch (error) {
        console.error(error);
        // console.log(error)
        showToastError(this.$t("account.personalSettings.errorWhileUpdating"));
      } finally {
        this.btnLoading = false;
      }
    },
    getDataTypeColoring(dataType) {
      // console.log('getAddressDataTypeColoring()', this.addressDataType)
      if (!dataType) {
        return "tertiary";
      }
      switch (dataType) {
        case "REAL":
          return "data-type-real";
        case "PROVIDED":
          return "data-type-provided";
        case "GENERATED":
          return "data-type-generated";
        case "CUSTOM":
          return "data-type-custom";
        default:
          return "tertiary";
      }
    },
    getAddressDataTypeColoring() {
      this.getDataTypeColoring(this.addressDataType.value);
    },
    translatedDataType(dataType) {
      if (!dataType) {
        return "";
      }
      if (dataType === this.providedMode.value) {
        return this.$t("account.identities.dialog.providedDatatype");
      }
      if (dataType === this.generatedMode.value) {
        return this.$t("account.identities.dialog.generatedDatatype");
      }
      if (dataType === this.realMode.value) {
        return this.$t("account.identities.dialog.realDatatype");
      }
      if (dataType === this.customMode.value) {
        return this.$t("account.identities.dialog.customDatatype");
      }
      return "";
    },
    changeToEditMode(item) {
      // console.log('changeToEditMode', item, index)
      this.isEditing = !this.isEditing

      this.$nextTick(function () {
        // console.log('element', this.$refs.identityDomainInput)
        const theElement = this.$refs.identityDomainInput;
        if (theElement) {
          const input = theElement.$el.querySelector(
            "input:not([type=hidden]),textarea:not([type=hidden])"
          );
          // console.log(input)
          if (input) {
            setTimeout(() => {
              input.focus();
            }, 0);
          }
        }
      });
    },
    shouldDecrypt(oldValue) {
      if (
        !(
          oldValue === "NONE" ||
          oldValue === "GENERATED" ||
          oldValue === "REAL" ||
          oldValue === "PROVIDED"
        )
      ) {
        //console.log("WATCH phoneNumberType: called first time");
        return false
      }
      return this.haveRealDatas
    },
  },
};
</script>

<style scoped>
.newImageBadge {
  cursor: pointer;
}

.decrypt-needed-text-inline {
  color: var(--v-step350-base);
  font-size: 14px;
  /*margin: auto auto;*/
}

.wrapped {
  flex-wrap: wrap;
}
</style>
