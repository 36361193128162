<template>
  <div v-if="shouldBeVisible">
    <div
      :class="{
        real: false && currentDataType.value === realMode.value,
        provided: false && currentDataType.value === providedMode.value,
        generated: false && currentDataType.value === generatedMode.value,
        custom: false && currentDataType.value === customMode.value,
        none: false && currentDataType.value === noneMode.value,
        selecting: isSelecting || true,
        selected: !isSelecting
      }"
      class="data-type-toggle"
    >
      <div v-if="canBeGenerated && (currentDataType.value === generatedMode.value || isSelecting)" 
      v-tooltip="!isSelecting ? defaultText :$t(generatedMode.tooltipText)" 
      :class="{ 'dice-circle': currentDataType.value === generatedMode.value }" class="img-wrapper">
        <button @click="switchDataType(generatedMode)">
          <img class="ion-dice-outline" alt="dice outline" src="/public/images/ion-dice-outline.svg" />
        </button>
      </div>
      <div v-if="canBeReal && (currentDataType.value === realMode.value || isSelecting)" 
      v-tooltip="!isSelecting ? defaultText : $t(realMode.tooltipText)" :class="{ 'dice-circle': currentDataType.value === realMode.value }" class="img-wrapper">
        <button @click="switchDataType(realMode)">
          <img class="img" alt="shield checkmark" src="/public/images/ion-shield-checkmark-outline.svg" />
        </button>
      </div>
      <div v-if="canBeProvided && (currentDataType.value === providedMode.value || isSelecting)" 
      v-tooltip="!isSelecting ? defaultText : $t(providedMode.tooltipText)" :class="{ 'dice-circle': currentDataType.value === providedMode.value }" class="img-wrapper">
        <button @click="switchDataType(providedMode)">
          <img class="img" alt="color wand" src="/public/images/ion-color-wand-outline.svg" />
        </button>
      </div>
      <div v-if="(currentDataType.value === customMode.value)" 
      v-tooltip="!isSelecting ? defaultText : $t(customMode.tooltipText)" :class="{ 'dice-circle': currentDataType.value === customMode.value }" class="img-wrapper">
        <button @click="switchDataType(customMode)">
          <img class="img" alt="color wand" src="/public/images/ion-pencil-outline.svg" />
        </button>
      </div>
      <div v-if="(currentDataType.value === noneMode.value)" 
      v-tooltip="!isSelecting ? defaultText : $t(noneMode.tooltipText)" :class="{ 'dice-circle': currentDataType.value === noneMode.value }" class="img-wrapper">
        <button @click="switchDataType(noneMode)">
          <img class="img" alt="color wand" src="/public/images/ion-close-circle.svg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import identityDataTypes from './identityDataTypes.js'

export default {
  name: 'DataTypeToggleOutline',
  props: {
    defaultDataType: {
      type: String,
      required: true,
      default: 'GENERATED'
    },
    canBeReal: {
      type: Boolean,
      required: false,
      default: false
    },
    canBeProvided: {
      type: Boolean,
      required: false,
      default: false
    },
    canBeGenerated: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleName: {
      type: String,
      required: false,
      default: 'UNKNOWN'
    },
  },
  emits: ['datatypechanged'],
  data() {
    return {
      generatedMode: identityDataTypes[0],
      providedMode: identityDataTypes[1],
      realMode: identityDataTypes[2],
      customMode: identityDataTypes[3],
      noneMode: identityDataTypes[4],
      defaultText: this.$t('account.identities.dialog.changeDataType'),
      currentDataType: identityDataTypes.find(x => x.value === this.defaultDataType),
      isSelecting: false
    }
  },
  computed: {
    shouldBeVisible() {
      //const howManyTrue = +this.canBeGenerated + +this.canBeProvided + +this.canBeReal
      //console.log("togglename DATATYPE shouldBeVisible howManyTrue", this.toggleName, this.currentDataType.value, howManyTrue)
      //return howManyTrue >= 2 || (this.currentDataType.value === this.noneMode.value)
      const shouldBe = this.canBeGenerated || this.canBeProvided || this.canBeReal
      //console.log("togglename DATATYPE shouldBeVisible shouldBe", this.toggleName, this.currentDataType.value, shouldBe)
      //return shouldBe || (this.currentDataType.value === this.noneMode.value)
      return shouldBe
    },
    getToggleClass() {
      return {
        real: false && this.currentDataType.value === this.realMode.value,
        provided: false && this.currentDataType.value === this.providedMode.value,
        generated: false && this.currentDataType.value === this.generatedMode.value
      }
    }
  },
  mounted() {
    /*console.log("toggle mounted togglename, DATATYPE", this.toggleName, this.currentDataType.value)
    console.log("canBeReal", this.canBeReal)
    console.log("canBeProvided", this.canBeProvided)
    console.log("canBeGenerated", this.canBeGenerated)*/
    
    window.addEventListener('click', this.clickOutsideListener)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOutsideListener);
  },
  methods: {
    switchDataType(value) {
      if (!this.isSelecting) {
        this.isSelecting = true
        return
      } else {
        if (value.value !== this.noneMode.value) {
          this.currentDataType = value
          this.$emit('datatypechanged', value)
          //console.log('dataType changed', value)
        }
        this.isSelecting = false
      }     
    },
    clickOutsideListener(event) {
      if (event.target !== this.$el && !this.$el.contains(event.target)) {
        this.isSelecting = false;
      }
    }
  }
}
</script>

  <style scoped>
  .data-type-toggle {
    align-items: center;
    background-color: var(--v-step100-base);
    border: 3px solid transparent;
    display: flex;
    gap: 5px;
    height: 48px;
    padding: 3px;
    position: relative;
    width: auto;
    border-radius: 50px;
  }
  .real {
    border-color: var(--v-dataTypeReal-base);
  }
  .generated {
    border-color: var(--v-generated-base);
  }
  .provided {
    border-color: var(--v-provided-base);
  }
  .custom {
    border-color: var(--v-custom-base);
  }
  .none {
    border-color: var(--v-none-base);
  }
  

  .dice-circle {
    background-color: var(--v-providedContrast-base);
    border-radius: 22px;
    height: 42px;
    margin-bottom: -0.5px;
    margin-top: -0.5px;
    position: relative;
    width: 42px;
  }
  .ion-dice-outline {
    height: 28px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 28px;
  }
  .img-wrapper {
    border-radius: 22px;
    height: 42px;
    margin-bottom: -0.5px;
    margin-top: -0.5px;
    position: relative;
    width: 42px;
  }
  .img {
    height: 26px;
    left: 9px;
    position: absolute;
    top: 9px;
    width: 26px;
  }
  </style>
