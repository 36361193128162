<template>
  <v-app>
    <component :is="layout"></component>

    <!-- confirmationDialog -->
    <v-row align="center" justify="center">
      <v-dialog v-model="confirmationDialogShowed" persistent max-width="450">
        <v-card>
          <v-card-title class="headline yellow darken-3">
            <v-icon left large>mdi-alert-circle</v-icon>
            {{ confirmationTitle }}
          </v-card-title>
          <v-card-text v-if="!confirmationCheckboxText" class="pt-3">
            {{ confirmationText }}
          </v-card-text>
          <v-card-text v-if="confirmationCheckboxText" class="pt-3 mb-0 pb-0">
            <p class="pa-0" v-html="confirmationText"></p>
          </v-card-text>
          <v-checkbox v-if="confirmationCheckboxText" v-model="confirmationCheckboxChecked" class="ml-6 pt-3" :label="confirmationCheckboxText"></v-checkbox>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-3" text @click="confirmationAccept(false)">{{ $t('main.no') }}</v-btn>

            <v-btn v-if="confirmationCheckboxText" text color="blue darken-3" :disabled="!confirmationCheckboxChecked" @click="closeConfirm">{{ $t('main.yes') }}</v-btn>
            <v-btn v-if="!confirmationCheckboxText" class="primary" @click="closeConfirm">{{ $t('main.yes') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- requestLoadingDialog -->
    <v-row align="center" justify="center">
      <v-dialog v-model="requestLoadingDialogShowed" persistent max-width="350">
        <v-card>
          <v-card-title class="headline green lighten-1">
            <v-icon dark left large>mdi-bell-ring-outline</v-icon>
          </v-card-title>
          <v-card-text>
            <div class="text-center ma-2">
              {{ $t('dialog.request.requestAuthorization') }}
            </div>
            <div class="text-center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row align="center" justify="center">
      <v-dialog v-model="otpDialogShowed" persistent max-width="450">
        <v-card>
          <v-card-title class="headline yellow darken-3">
            <v-icon left large>mdi-key</v-icon>
            {{ otpTitle }}
          </v-card-title>
          <div class="pa-6">
            <v-label>
              {{ $t('decryption.otpCodeEnter') }}
            </v-label>
            <v-otp-input
              v-model="OTP"
              length="6"
              class="mt-3 mb-3"
              @finish="onFinish"
            >
            </v-otp-input>
            <v-label class=" mb-6">
              {{ $t('decryption.otpExplainer') }}
            </v-label>
            <v-progress-linear

              :active="decryptIsLoading"
              :indeterminate="decryptIsLoading"
              :height="10"
            ></v-progress-linear>
            <v-btn v-if="otpCancelVisible" class="mt-6 justify-center" color="blue darken-3" text @click="onFinish('')">{{ $t('main.cancel') }}</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- snackbar -->
    <v-snackbar v-model="toastShowed" top :color="toastColor">
      <v-icon dark left large>{{ toastIcon }}</v-icon>
      {{ toastMessage }}
      <v-btn text @click="toastShowed = false">
        {{ $t('main.close') }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import LayoutBlank from './layouts/Blank.vue'
import LayoutContent from './layouts/Content.vue'
import eventBus from './event-bus'

export default {
  components: {
    LayoutBlank,
    LayoutContent
  },

  data() {
    return {
      toastShowed: false,
      toastIcon: null,
      toastColor: null,
      toastMessage: null,
      toastDescription: null,
      confirmationDialogShowed: false,
      confirmationTitle: null,
      confirmationText: null,
      confirmationCheckboxText: null,
      confirmationCheckboxChecked: false,
      yesButtonActive: true,
      requestLoadingDialogShowed: false,
      otpDialogShowed: false,
      OTP: '',
      otpTitle: '',
      decryptIsLoading: false,
      otpCancelVisible: true
    }
  },

  computed: {
    layout() {
      if (!this.$route.name) return null
      if (this.$route.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    }
  },

  mounted() {
    eventBus.$on('showToast', ({ icon, type, message, desc }) => {
      this.toastShowed = true
      this.toastIcon = icon
      this.toastColor = type
      this.toastMessage = message
      this.toastDescription = desc
    })

    eventBus.$on('showConfirmationDialog', ({ title, text, yesButtonActive = false }) => {
      this.confirmationDialogShowed = true
      this.confirmationTitle = title
      this.confirmationText = text
      this.confirmationCheckboxText = null
      this.yesButtonActive = yesButtonActive
    })

    eventBus.$on('showConfirmationDialogWithCheckBox', ({ title, text, checkboxText }) => {
      this.confirmationDialogShowed = true
      this.confirmationTitle = title
      this.confirmationText = text
      this.confirmationCheckboxText = checkboxText
      this.confirmationCheckboxChecked = false
    })

    eventBus.$on('showRequestLoadingDialog', ({ status }) => {
      this.requestLoadingDialogShowed = status
    })

    eventBus.$on('showOTPDialog', ({ title }) => {
      // console.debug('showOTPDialog called')
      this.OTP = ''
      this.otpTitle = title
      this.otpDialogShowed = true
      this.decryptIsLoading = false
      this.otpCancelVisible = true
    })
  },

  methods: {
    confirmationAccept(value) {
      eventBus.$emit('confirmationAccept', { value })
      this.confirmationDialogShowed = false
    },
    closeConfirm() {
      if (this.confirmationCheckboxText && this.confirmationCheckboxChecked) {
        this.confirmationAccept(true)
      } else if (!this.confirmationCheckboxText) {
        this.confirmationAccept(true)
      }
    },
    onFinish(value) {
      // console.debug('OTP filled', value)
      this.OTP = value
      this.otpCancelVisible = false
      if (value.length > 0) {
        this.decryptIsLoading = true
        setTimeout(() => {
          this.decryptIsLoading = false
          eventBus.$emit('otpTyped', { value })
          this.otpDialogShowed = false
        }, 2000)
      } else {
        eventBus.$emit('otpTyped', { value })
        this.otpDialogShowed = false
      }
    }
  }
}
</script>
<style>
@import './styles/main.css'
</style>
