// import { colorWandOutline, diceOutline, shieldCheckmarkOutline } from 'ionicons/icons'

const dataTypes = [
  // {
  //   value: 'NONE',
  //   icon: banOutline,
  //   text: ''
  // },
  {
    value: 'GENERATED',
    // icon: diceOutline,
    icon: 'mdi-dice-6',
    text: 'Generated',
    tooltipText: 'account.identities.dialog.generatedHint'
  },
  {
    value: 'PROVIDED',
    // icon: colorWandOutline,
    icon: 'mdi-auto-fix',
    text: 'Provided',
    tooltipText: 'account.identities.dialog.providedHint'
  },
  {
    value: 'REAL',
    // icon: shieldCheckmarkOutline,
    icon: 'mdi-shield-check',
    text: 'Your real',
    tooltipText: 'account.identities.dialog.realHint'
  },
  {
    value: 'CUSTOM',
    icon: 'mdi-pencil',
    text: 'Custom',
    tooltipText: 'account.identities.dialog.customHint'
  },
  {
    value: 'NONE',
    icon: 'mdi-alert-circle',
    text: 'account.identities.dialog.noneHint'
  }
]
export default dataTypes
