import createAxios from './api'

const WAREHOUSE = 'warehouses'
// const CLIENT = 'clients'

export default {
  async createWarehouse(warehouseDto) {
    const axios = await createAxios()
    const resp = await axios.post(WAREHOUSE, warehouseDto)
    return resp.data
  }
  // update user by id
  // delete user by id
}
