import createAxios from './api'

const PATH = 'clients'

export default {
  async createClient(clientDto) {
    const axios = await createAxios()
    const resp = await axios.post(PATH, clientDto)
    return resp.data
  },
  async updateClient() {
    console.log('TODO')
  },
  async deleteClient() {
    console.log('TODO')
  }

}
