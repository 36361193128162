<template>
  <div>
    <v-btn to="/identities">
      <v-icon left color="text" size="small">
        mdi-chevron-left
      </v-icon>
      {{ $t('main.goToIdentities') }}
    </v-btn>
    <div v-if="profileDataAuthorised" class="mt-6">
      <v-card id="userDataFields" class="ml-0 mb-6 pa-6">
        <v-card-title class="pl-0 pt-0">{{ $t('account.personalSettings.title') }}</v-card-title>
        <v-form ref="userDataForm" v-model="userDataFormValid" @submit.prevent="updateUserProfile">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="email" :label="$t('account.personalSettings.email')" :counter="maxCharacters"
                type="email" :rules="[rules.emailCouldBeEmpty]">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="contactEmail" :label="$t('account.personalSettings.contactEmail')"
                :counter="maxCharacters" type="email" :rules="[rules.required, rules.email, rules.minMax]">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="name" :label="$t('account.personalSettings.name')" :counter="maxCharacters"
                :rules="[]">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-label>{{ $t('account.personalSettings.birthdate') }}</v-label>
              <div class="d-flex justify-start align-left">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="birthdate"
                  transition="scale-transition" offset-y min-width="auto">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="birthdate" prepend-icon="mdi-calendar" class="shrink" dense readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="birthdate" no-title scrollable :max="maxDate">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      {{ $t('main.cancel') }}
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(birthdate)">
                      {{ $t('main.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-btn class="ml-2" :disabled="!birthdate" @click="birthdate = null">
                  {{ $t('main.clear') }}
                </v-btn>
              </div>
            </v-col>
            <!-- <v-col cols="6">

            </v-col> -->
            <v-col cols="6">
              <v-label>{{ $t('account.personalSettings.gender') }}</v-label>
              <div class="d-flex justify-start align-left">
                <v-radio-group v-model="gender" row>
                  <v-radio :label="$t('account.personalSettings.male')" value="male"></v-radio>
                  <v-radio :label="$t('account.personalSettings.female')" value="female"></v-radio>
                </v-radio-group>
                <v-btn class="ml-2" :disabled="!gender" @click="gender = null">
                    {{ $t('main.clear') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-1 mt-8 mb-2">
            <v-btn color="success" type="submit" :disabled="!userDataFormValid" :loading="btnLoading">
              {{ $t('main.update') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card>

      <v-card id="addressFields" class="ml-0 mb-6 pa-6">
        <v-card-title class="pl-0 pt-0">{{ $t('account.personalSettings.address') }}</v-card-title>
        <v-row align="start" no-gutters>
          <v-col cols="12" md="6">
            <v-btn outlined color="primary" @click="createNewAddress">{{ $t('account.personalSettings.createNewAddress')
            }}</v-btn>
            <v-list dense two-line class="mt-3">
              <v-list-item-group v-model="selectedAddressIndex" color="primary" dense>
                <v-list-item v-for="(address, index) in addresses" :id="index" :key="index"
                  :class="selectedAddressIndex === index ? 'v-item--active' : ''" @click="editAddress(address)">
                  <v-list-item-content>
                    <v-list-item-title>{{ address.label }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ address.locality }} {{ address.postalCode }} {{ address.streetAddress }} {{ address.countryCode
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="primary" outlined @click.stop="editAddress(address)">{{ $t('main.edit') }}</v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn color="error" outlined @click.stop="deleteAddress(address.id)">{{ $t('main.delete') }}</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col v-if="isAddressprofileDataAuthorised" cols="12" md="6" class="pl-6">
            <!-- <div v-if="selectedAddressIndex !== -1" class="flex-fill w-100"> -->
            <v-form ref="saveAddressForm" v-model="saveAddressValid" class="flex-fill w-100"
              @submit.prevent="saveAddress">
              <v-text-field v-model="editedAddress.label" :label="$t('account.personalSettings.label')" required
                :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="editedAddress.postalCode" :label="$t('account.personalSettings.postalCode')" required
                :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="editedAddress.region" :label="$t('account.personalSettings.region')"
                :rules="[]"></v-text-field>
              <v-text-field v-model="editedAddress.streetAddress" :label="$t('account.personalSettings.streetAddress')"
                required :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="editedAddress.locality" :label="$t('account.personalSettings.city')" required
                :rules="[rules.required]"></v-text-field>
              <v-select id="country_name" v-model="editedAddress.countryCode"
                :label="$t('account.personalSettings.countryCode')" :items="countries" item-text="text" item-value="value"
                prepend-icon="mdi-map" single-line required :rules="[rules.required]">
              </v-select>

              <v-btn type="submit" color="success" class="mr-6" :disabled="!saveAddressValid">{{ isNewAddress ?
                $t('main.create') : $t('main.save') }} </v-btn>

              <v-btn outlined @click="isAddressprofileDataAuthorised = false">{{ $t('main.cancel') }} </v-btn>
            </v-form>
            <!-- </div> -->
          </v-col>
        </v-row>
      </v-card>

      <v-card id="phoneFields" class="ml-0 mb-6 pa-6">
        <v-card-title class="pl-0 pt-0">{{ $t('account.personalSettings.phone') }}</v-card-title>
        <v-row align="start" no-gutters>
          <v-col cols="12" md="6">
            <v-btn color="primary" outlined @click="createNewPhoneNumber">{{
              $t('account.personalSettings.createNewPhoneNumber') }}</v-btn>
            <v-list dense two-line class="mt-3">
              <v-list-item-group v-model="selectedPhoneIndex" color="primary" dense>
                <v-list-item v-for="(phoneNumber, index) in phoneNumbers" :id="index" :key="index"
                  :class="selectedPhoneIndex === index ? 'v-item--active' : ''" @click="editPhoneNumber(phoneNumber)">
                  <v-list-item-content>
                    <v-list-item-title>{{ phoneNumber.label }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ phoneNumber.number }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="primary" outlined @click.stop="editPhoneNumber(phoneNumber)">{{ $t('main.edit')
                    }}</v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn color="error" outlined @click.stop="deletePhoneNumber(phoneNumber)">{{ $t('main.delete')
                    }}</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col v-if="isPhoneprofileDataAuthorised" cols="12" md="6" class="pl-6">
            <v-form ref="savePhoneNumberForm" v-model="savePhoneValid" class="flex-fill w-100"
              @submit.prevent="savePhoneNumber">
              <v-text-field v-model="editedPhoneNumber.label" :label="$t('account.personalSettings.label')" required
                :rules="[rules.required]"></v-text-field>
              <vue-tel-input v-bind="phone.options" v-model="editedPhoneNumber.number" class="mb-6" label="Number"
                mode="international" required :rules="[rules.required]" @validate="phoneValidate" @input="onPhoneInput"
                @blur="editedPhoneNumber.isBlurred = true"></vue-tel-input>
              <p v-if="!phoneFieldValid" class="error">
                {{ $t('account.personalSettings.invalidPhoneNumber') }}
              </p>
              <v-btn type="submit" color="success" class="mr-6 mb-6" :disabled="!savePhoneValid || !phoneFieldValid"> {{
                isNewPhone ? $t('main.create') : $t('main.save') }} </v-btn>
              <v-btn class="mb-6" @click="isPhoneprofileDataAuthorised = false">{{ $t('main.cancel') }} </v-btn>
            </v-form>
            <!-- </div> -->
          </v-col>
        </v-row>
      </v-card>

      <v-card id="passwordFields" class="ml-0 mb-6 pa-6">
        <v-card-title class="pl-0 pt-0">{{ $t('account.personalSettings.changePassword') }}</v-card-title>
        <v-row align="start" class="ml-0 pl-0">
          <v-form ref="passwordForm" v-model="passwordFormValid" class="ml-0 pl-0" @submit.prevent="changePwd">
            <v-col class="ml-0 pl-0">
              <div class="ml-0 pl-0 d-flex justify-start align-center">
                <vuetifyPassword id="oldPassword" v-model="oldPassword"
                  :rules="[rules.required, rules.passwordMustBeLongerThan, rules.onlyNumber]" name="password"
                  :label="$t('account.personalSettings.password')" input-class="ml-0 mr-3" prepend-icon="mdi-lock">
                </vuetifyPassword>
                <vuetifyPassword id="password" v-model="password"
                  :rules="[rules.required, rules.passwordMustBeLongerThan, rules.onlyNumber]" name="password"
                  :label="$t('account.personalSettings.newPassword')" input-class="mx-6"></vuetifyPassword>
                <v-btn type="submit" color="success" input-class="ml-0 mr-6" :disabled="!passwordFormValid"
                  :loading="btnPasswordLoading">
                  {{ $t('main.update') }}
                </v-btn>
              </div>
            </v-col>
          </v-form>
        </v-row>
      </v-card>

      <v-card id="csvUpload" class="ml-0 mb-6 pa-6">
        <v-card-title class="pl-0 pt-0">{{ $t('account.personalSettings.importCSVTitle') }}</v-card-title>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="selectedCsvType"
              :items="csvTypes.map(item => item.display)"
              :label="$t('account.personalSettings.selectCSVType')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              v-model="selectedFile"
              :label="$t('account.personalSettings.selectCSVFile')"
              outlined
              accept=".csv"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn @click="uploadCsv" color="primary">{{ $t('account.personalSettings.importCSVFileButton') }}</v-btn>
        </v-card-actions>
      </v-card>

      <v-card id="authFields" class="ml-0 mb-6 pa-6">
        <v-card-title class="pl-0 pt-0">{{ $t('account.personalSettings.2FAMethods.title') }}</v-card-title>

        <v-tabs
          v-model="tab"
          background-color="accent-4"
          centered          
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            {{ $t('account.personalSettings.2FAMethods.TOTPtitle') }}
            <v-icon>mdi-onepassword</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            {{ $t('account.personalSettings.2FAMethods.recoveryCodeTitle') }}
            <v-icon>mdi-lock</v-icon>
          </v-tab>

          <v-tab href="#tab-3">
            {{ $t('account.personalSettings.2FAMethods.emailOTPTitle') }}
            <v-icon>mdi-email</v-icon>
          </v-tab>

          <v-tab href="#tab-4">
            {{ $t('account.personalSettings.2FAMethods.webAuthTitle') }}
            <v-icon>mdi-web</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item            
            value="tab-1"
          >
            <v-card flat>
              <v-card-text>
                <v-row align="start" class="ml-0 pl-0">
                  <v-col class="ml-0 pl-0">
                    <div class="ml-0 pl-0 d-flex justify-start align-center">
                      <v-btn input-class="ml-0 mr-6" @click="generateTOTP">
                        {{ $t('account.personalSettings.2FAMethods.generateTOTP') }}
                      </v-btn>

                    </div>
                  </v-col>
                  <v-col class="ml-0 pl-0">
                    <div class="ml-0 pl-0 d-flex justify-start align-center">
                      <v-btn v-if="twoFactorInfo && twoFactorInfo.totp  && twoFactorInfo.totp.credentialId" class="ml-6" @click="deleteCredential(twoFactorInfo.totp.credentialId)">
                          {{ $t('account.personalSettings.2FAMethods.deleteAuthMethodButton') }}                               
                      </v-btn>
                    </div>
                  </v-col>                  
                    
                  <v-col class="ml-0 pl-0" cols="10">
                    <div class="ml-0 pl-0 d-flex justify-start align-left">
                      <div v-if="TwoFA_TOTP_QRCode_ImageData">
                        <v-text-field v-model="TwoFA_TOTPSecret"
                          :label="$t('account.personalSettings.2FAMethods.secretCodeLabel')"></v-text-field>
                        <!-- <qrcode-vue :value="TwoFA_TOTPQRCodeValue" :size="qrCodeSize" :level="qrCodeLevel"></qrcode-vue> -->
                        <v-img :src="TwoFA_TOTP_QRCode_ImageData" alt="TOTP QR Code" contain></v-img>
                      </div>
                    </div>
                  </v-col>            
                </v-row>
                <v-row v-if="TwoFA_TOTPSecret" align="start" class="ml-0 pl-0">
                  <v-label>{{ $t('account.personalSettings.2FAMethods.OTPQRCodeActionNeeded') }} </v-label>
                </v-row>
                <v-row v-if="TwoFA_TOTPSecret" align="start" class="ml-0 pl-0">                
                  <v-col class="ml-0 pl-0" cols="10">
                    <v-text-field v-model="TwoFA_TOTPCode"
                          :label="$t('account.personalSettings.2FAMethods.codeLabel')"></v-text-field>
                    <v-text-field v-model="TwoFA_TOTPDevice"
                          :label="$t('account.personalSettings.2FAMethods.deviceLabel')"></v-text-field>
                  </v-col>    
                </v-row>
                <v-row v-if="TwoFA_TOTPSecret" align="start" class="ml-0 pl-0">
                  <v-col class="ml-0 pl-0" cols="2">
                    <v-btn class="ml-6" @click="activateTOTP">
                          {{ $t('account.personalSettings.2FAMethods.activateTOTPButton') }}                               
                    </v-btn>        
                  </v-col>
                </v-row>

                
                <v-row v-if="twoFactorInfo && twoFactorInfo.totp.length > 0" align="start" class="ml-0 pl-0">
                  <v-col cols="12">
                    <v-label>{{ $t('account.personalSettings.2FAMethods.registeredTOTPdevices') }} </v-label>
                  </v-col>
                  <v-col cols="6">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in twoFactorInfo.totp"
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.device }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn @click="deleteCredential(item.credentialId)">
                            Delete TOTP
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-tab-item>


          <v-tab-item            
            value="tab-2"
          >
            <v-card flat>
              <v-card-text>
                <v-row align="start" class="ml-0 pl-0">
                  <v-col class="ml-0 pl-0">
                    <div class="ml-0 pl-0 d-flex justify-start align-center">
                      <v-btn input-class="ml-0 mr-6" @click="generateRecoveryCodes">
                        {{ $t('account.personalSettings.2FAMethods.generateRecoveryCode') }}
                      </v-btn>
                      <v-btn v-if="twoFactorInfo && twoFactorInfo.recoveryCode && twoFactorInfo.recoveryCode.credentialId" class="ml-6" @click="deleteCredential(twoFactorInfo.recoveryCode.credentialId)">
                        {{ $t('account.personalSettings.2FAMethods.deleteAuthMethodButton') }}                               
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col class="ml-0 pl-0" cols="9">
                    <div class="ml-0 pl-0 d-flex justify-start align-left">
                      <div v-if="recoveryCodesGenerated">
                        <v-simple-table dense >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  Code
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in recoveryCodesGenerated">
                                <td>{{ item }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>                  

                      </div>

                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item            
            value="tab-3"
          >
            <v-card flat>
              <v-card-text>
                <v-row align="start" class="ml-0 pl-0">
                  <v-col class="ml-0 pl-0">
                    <div class="ml-0 pl-0 d-flex justify-start align-center">
                      <v-btn v-if="twoFactorInfo && !twoFactorInfo.emailOtpCredentialId" input-class="ml-0 mr-6" @click="enableOTP">
                        {{ $t('account.personalSettings.2FAMethods.enableAuthMethodButton') }}
                      </v-btn>
                      <v-btn v-if="twoFactorInfo && twoFactorInfo.emailOtpCredentialId" input-class="ml-0 mr-6" @click="deleteCredential(twoFactorInfo.emailOtpCredentialId)">
                        {{ $t('account.personalSettings.2FAMethods.deleteAuthMethodButton') }}                               
                      </v-btn>
                    </div>
                  </v-col>                  
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item            
            value="tab-4"
          >
            <v-card flat>
              <v-card-text>
                <v-row align="start" class="ml-0 pl-0">
                  <v-col class="ml-0 pl-0">
                    <div class="ml-0 pl-0 d-flex justify-start align-center">
                      <v-btn input-class="ml-0 mr-6" @click="webauthnStartRegistration">
                        {{ $t('account.personalSettings.2FAMethods.registerWebAuth') }}
                      </v-btn>

                    </div>
                  </v-col>                  
                </v-row>                   
                  
                <v-row v-if="twoFactorInfo && twoFactorInfo.webAuthnCredentialIds.length > 0" align="start" class="ml-0 pl-0">
                  <v-col cols="12">
                    <v-label>{{ $t('account.personalSettings.2FAMethods.registeredWebAuths') }} </v-label>
                  </v-col>
                  <v-col cols="8">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in twoFactorInfo.webAuthnCredentialIds"
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn @click="deleteCredential(encodeURIComponent(item))">
                            {{ $t('account.personalSettings.2FAMethods.deleteItem') }}
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>        
          

       
      </v-card> 


      <v-card id="subscribeToNewsletterBox" class="ml-0 mb-6 pa-6">
        <v-card-title class="pl-0 pt-0">{{ $t('account.personalSettings.newsletter') }}</v-card-title>
        <v-row align="start">
          <v-col>
            <v-checkbox v-model="subscribeToNewsletter" name="subscribeToNewsletter"
              :label="$t('account.personalSettings.subscribeToNewsletter')" @change="saveNewsLetter($event)">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mx-1 mt-8 mb-2">
        <v-btn class="ml-3 mt-6" color="error" :loading="btnLoading" @click="deletUserProfile">
          {{ $t('account.personalSettings.deletUserProfile') }}
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { showToastSuccess, showToastError, showConfirmationDialogWithCheckBox } from '@/event-bus'
import user from '@/services/api/user.js'
import identities from '@/services/api/identities.js'
import server from '@/services/api/server.js'
import VuetifyPassword from '../../../shared/plugins/components/VuetifyPassword.vue'
import {
  create,
  parseCreationOptionsFromJSON
} from '@github/webauthn-json/browser-ponyfill'

export default {

  components: {
    VuetifyPassword
  },

  data() {
    return {
      userDataFormValid: false,
      passwordFormValid: false,
      profileDataAuthorised: false,
      isAddressprofileDataAuthorised: false,
      btnLoading: false,
      btnPasswordLoading: false,
      // props for validation
      emailRegExpression: /^\S+@\S+\.\S+$/,
      minCharacters: 1,
      maxCharacters: 255,
      saveAddressValid: false,
      password: '',
      phone: '',
      menu: false,
      gender: '',
      birthdate: '',
      passwordMinCharacters: 4,
      showPassword: false,
      showOldPassword: false,
      phoneFieldValid: false,
      subscribeToNewsletter: false,
      oldPassword: '',
      countries: [],
      twoFactorInfo: null,
      TwoFA_TOTPSecret: null,
      TwoFA_TOTPCode: null,
      TwoFA_TOTPDevice: null,
      /*TwoFA_TOTPQRCode: null,
      TwoFA_TOTPQRCodeValue: '1234',
      qrCodeSize: 250,
      qrCodeLevel: 'M',*/
      TwoFA_TOTP_QRCode_ImageData: null,
      recoveryCodesGenerated: null,
      recoveryCodesId: null,
      tab: null,
      selectedCsvType: null,
      selectedFile: null,
      csvTypes: [
        { display: '1password', value: 'ONEPASSWORD' },
        { display: 'bitwarden', value: 'BITWARDEN' },
        { display: 'chrome', value: 'CHROME' },
        { display: 'dashlane', value: 'DASHLANE' },
        { display: 'keeper', value: 'KEEPER' },
        { display: 'lastpass', value: 'LASTPASS' },
        { display: 'logmeonce', value: 'LOGMEONCE' },
        { display: 'nordpass', value: 'NORDPASS' },
        { display: 'roboform', value: 'ROBOFORM' },
        { display: 'truekey', value: 'TRUEKEY' },
      ],
      rules: {
        // required: (v) => !!v || !isNaN(parseFloat(v)) || this.$t('validation.form.requiredField'),
        required: (v) => !!v || this.$t('validation.form.requiredField'),
        minMax: (v) => ((v && v.length >= this.minCharacters) && (v && v.length <= this.maxCharacters)) || this.$t('validation.form.mustBeBetween', { min: this.minCharacters, max: this.maxCharacters }),
        email: (v) => (this.emailRegExpression.test(v)) || this.$t('validation.form.emailMustBeValid'),
        emailCouldBeEmpty: (v) => !v || (this.emailRegExpression.test(v)) || this.$t('validation.form.emailMustBeValid'),
        onlyNumber: v => (/^[0-9]+$/.test(v)) || this.$t('validation.form.onlyNumbers'),
        passwordMustBeLongerThan: v => (v && v.length >= this.passwordMinCharacters) || this.$t('validation.form.passwordMustBeLongerThan', { min: this.passwordMinCharacters })

      },
      name: '',
      email: '',
      contactEmail: '',
      addresses: [],
      phoneNumbers: [],
      selectedAddressIndex: -1,
      editedAddress: {
        id: '',
        label: '',
        streetAddress: '',
        locality: '',
        postalCode: '',
        countryCode: '',
        region: ''
      },
      editedPhoneNumber: {
        id: '',
        label: '',
        number: '',
        inputValue: '',
        isBlurred: false
      },
      isPhoneprofileDataAuthorised: false,
      savePhoneValid: false,
      isNewAddress: false,
      selectedPhoneIndex: -1,
      isNewPhone: false
    }
  },
  computed: {
    maxDate() {
      // Calculate today's date
      const today = new Date()

      // Set the maximum date to today's date
      return today.toISOString().substr(0, 10)
    }
  },
  async mounted() {
    this.fetchUserData()
    const entries = await server.getCountries()
    this.countries = entries.flatMap(e => {
      return { text: e.name, value: e.code }
    })
  },

  methods: {
    async fetchUserData() {
      const userMeta = await user.getMyUserMeta()
      //console.log('meta', userMeta)
      const needDecrypt = userMeta.hasRealBirthdate || userMeta.hasRealEmail || userMeta.hasRealGender || userMeta.hasRealName || (userMeta.realPhoneNumbers.length > 0) || (userMeta.realAddresses.length > 0)
      //console.log('need to decrypt user profile', needDecrypt)
      const userDto = await user.getUserData(this.$t('decryption.profileDecrypt'), needDecrypt)
      if (userDto === null) {
        return
      }
      this.profileDataAuthorised = true
      // console.debug(userDto)
      this.name = userDto.name
      this.email = userDto.email
      this.contactEmail = userDto.contactEmail
      this.phoneNumbers = userDto.phoneNumbers
      this.addresses = userDto.addresses
      this.birthdate = userDto.birthdate
      this.gender = userDto.gender
      try {
        const resp = await user.getProp('subscribedToNewsletter')
        const NEWSLETTER_SUBSCRIBED = resp.subscribedToNewsletter
        this.subscribeToNewsletter = NEWSLETTER_SUBSCRIBED === 'true'
      } catch {
        this.subscribeToNewsletter = false
      }
      this.get2FAInfos()      
    },
    async get2FAInfos() {
      try {
        this.twoFactorInfo = await user.twoFactorInfo()
        //console.log(this.twoFactorInfo) 
      } catch (error) {
        console.error(error)
        // console.log(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }    
    },
    async updateUserProfile() {
      this.btnLoading = true
      const dto = {
        contactEmail: this.contactEmail,
        birthdate: this.birthdate,
        gender: this.gender
      }
      if (this.name && this.name.length > 0) {
        dto.name = this.name
      }
      if (this.email && this.email.length > 0) {
        dto.email = this.email
      }
      try {
        await user.updateUser(dto)
        showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
      } catch (error) {
        console.error(error)
        // console.log(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      } finally {
        this.btnLoading = false
      }
    },
    async deletUserProfile() {
      this.btnLoading = true

      // todo add it later const deletionEffect = await user.getdeletionEffect()
      // console.log(deletionEffect)

      const conf = await showConfirmationDialogWithCheckBox(this.$t('account.personalSettings.confirmDelete'), this.$t('account.personalSettings.deleteDetails'), this.$t('account.personalSettings.deleteDetailsCheckbox'))

      if (!conf) {
        this.btnLoading = false
        return
      }
      const dto = {
        name: this.name,
        email: this.email,
        phoneNumber: this.phoneNumber,
        address: this.address
      }
      try {
        await user.deleteUser(dto)
        await this.$auth.logout()
        showToastSuccess(this.$t('account.personalSettings.successfullDelete'))
        this.$router.push('/welcome')
      } catch (error) {
        console.error(error)
        // console.log(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      } finally {
        this.btnLoading = false
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword

      // Start a timer to hide the password after 5 seconds
      if (this.showPassword) {
        setTimeout(() => {
          this.showPassword = false
        }, 5000) // 5000 milliseconds = 5 seconds
      }
    },
    toggleOldPasswordVisibility() {
      this.showOldPassword = !this.showOldPassword

      // Start a timer to hide the password after 5 seconds
      if (this.showOldPassword) {
        setTimeout(() => {
          this.showOldPassword = false
        }, 5000) // 5000 milliseconds = 5 seconds
      }
    },
    toggleprofileDataAuthorised() {
      if (this.profileDataAuthorised) {
        // Perform save operation (e.g., send data to the server)
        // this.saveIdentity()
      }
      this.profileDataAuthorised = !this.profileDataAuthorised
    },
    async changePwd() {
      this.btnPasswordLoading = true
      const dto = {
        oldPassword: this.oldPassword,
        newPassword: this.password
      }
      try {
        await user.changePassword(dto)
        showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      } finally {
        this.btnPasswordLoading = false
      }
    },
    createNewAddress() {
      this.isAddressprofileDataAuthorised = true
      this.isNewAddress = true
      this.selectedAddressIndex = -1
      this.editedAddress = {
        id: '',
        label: '',
        streetAddress: '',
        locality: '',
        postalCode: '',
        countryCode: '',
        region: ''
      }
    },
    editAddress(address) {
      this.isAddressprofileDataAuthorised = true
      this.isNewAddress = false
      // this.selectedAddressIndex = address.id
      this.selectedAddressIndex = this.addresses.findIndex(a => a.id === address.id)
      this.editedAddress = { ...address }
    },
    async saveAddress() {
      if (this.isNewAddress) {
        try {
          const dto = {
            label: this.editedAddress.label,
            streetAddress: this.editedAddress.streetAddress,
            locality: this.editedAddress.locality,
            postalCode: this.editedAddress.postalCode,
            countryCode: this.editedAddress.countryCode,
            region: this.editedAddress.region
          }
          const newAddress = await user.createAddress(dto)
          this.isAddressprofileDataAuthorised = false
          // console.debug(newAddress)
          if (newAddress.id !== undefined) {
            if (!this.addresses) {
              this.addresses = []
            }
            this.addresses.push(newAddress)
            showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
          } else {
            showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
            // console.log(newAddress.message)
          }
        } catch (error) {
          console.error(error)
          showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
        } finally {
          // this.btnPasswordLoading = false
        }
      } else {
        try {
          const dto = {
            label: this.editedAddress.label,
            streetAddress: this.editedAddress.streetAddress,
            locality: this.editedAddress.locality,
            postalCode: this.editedAddress.postalCode,
            countryCode: this.editedAddress.countryCode,
            region: this.editedAddress.region
          }
          const updateRes = await user.updateAddress(this.editedAddress.id, dto)
          // console.log(updateRes)
          this.isAddressprofileDataAuthorised = false
          this.addresses.splice(this.selectedAddressIndex, 1, { ...this.editedAddress })
          showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
        } catch (error) {
          console.error(error)
          showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
        } finally {
          // this.btnPasswordLoading = false
        }
      }
      this.selectedAddressIndex = -1
      this.isNewAddress = false
      this.editedAddress = {
        id: '',
        label: '',
        streetAddress: '',
        locality: '',
        postalCode: '',
        countryCode: '',
        region: ''
      }
    },
    async deleteAddress(addressId) {
      const indexToDelete = this.addresses.findIndex(address => address.id === addressId)
      // console.log('deleting addres: ' + addressId + ' indexToDelete: ' + indexToDelete)
      // this.btnPasswordLoading = true
      try {
        await user.deleteAddress(addressId)
        showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      } finally {
        // this.btnPasswordLoading = false
      }

      if (indexToDelete !== -1) {
        this.addresses.splice(indexToDelete, 1)
      }
    },
    selectAddress(index) {
      this.selectedAddressIndex = index
    },

    createNewPhoneNumber() {
      this.isPhoneprofileDataAuthorised = true
      this.isNewPhone = true
      this.selectedPhoneIndex = -1
      this.editedPhoneNumber = {
        id: '',
        label: '',
        number: '',
        inputValue: '',
        isBlurred: false
      }
    },
    phoneValidate(phoneObject) {
      // console.log('phoneValidate', phoneObject)
      this.editedPhoneNumber.inputValue = phoneObject
      this.phoneFieldValid = phoneObject.valid
    },
    onPhoneInput(formattedNumber, input) {
      // console.log('onPhoneInput', input)
      this.editedPhoneNumber.inputValue = input
      this.phoneFieldValid = input.valid
    },
    editPhoneNumber(phoneNumber) {
      this.isPhoneprofileDataAuthorised = true
      this.isNewPhone = false
      this.selectedPhoneIndex = this.phoneNumbers.findIndex(a => a.id === phoneNumber.id)
      this.editedPhoneNumber = { ...phoneNumber }
    },
    async savePhoneNumber() {
      if (this.isNewPhone) {
        try {
          const dto = {
            label: this.editedPhoneNumber.label,
            number: this.editedPhoneNumber.inputValue.number
          }
          const newPhoneNumber = await user.createPhoneNumber(dto)
          this.isPhoneprofileDataAuthorised = false
          // console.debug(newPhoneNumber)
          if (newPhoneNumber.id !== undefined) {
            if (!this.phoneNumbers) {
              this.phoneNumbers = []
            }
            this.phoneNumbers.push(newPhoneNumber)
            showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
          } else {
            showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
            // console.log(newPhoneNumber.message)
          }
        } catch (error) {
          console.error(error)
          showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
        } finally {
          // this.btnPasswordLoading = false
        }
      } else {
        try {
          // console.debug('number', this.editedPhoneNumber.number)
          // console.debug('number', this.editedPhoneNumber.inputValue.number)
          const dto = {
            label: this.editedPhoneNumber.label,
            number: this.editedPhoneNumber.inputValue.number
          }
          const updateRes = await user.updatePhoneNumber(this.editedPhoneNumber.id, dto)
          // console.debug(updateRes)
          this.isPhoneprofileDataAuthorised = false
          this.phoneNumbers.splice(this.selectedPhoneIndex, 1, { ...this.editedPhoneNumber })
          showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
        } catch (error) {
          console.error(error)
          showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
        } finally {
          // this.btnPasswordLoading = false
        }
      }
      this.selectedPhoneIndex = -1
      this.isNewPhone = false
      this.editedPhoneNumber = {
        id: '',
        label: '',
        number: '',
        inputValue: '',
        isBlurred: false
      }
    },
    async deletePhoneNumber(phoneNumber) {
      const indexToDelete = this.phoneNumbers.findIndex(a => a.id === phoneNumber.id)

      // this.btnPasswordLoading = true
      try {
        await user.deletePhoneNumber(phoneNumber.id)
        if (indexToDelete !== -1) {
          this.phoneNumbers.splice(indexToDelete, 1)
        }
        showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      } finally {
        // this.btnPasswordLoading = false
      }
    },
    selectPhoneNumber(index) {
      this.selectedPhoneIndex = index
    },

    setDisabledRecursive(element, disabled) {
      // Set the disabled property for the current element
      element.disabled = disabled

      // Recursively process child elements
      for (let i = 0; i < element.children.length; i++) {
        this.setDisabledRecursive(element.children[i], disabled)
      }
    },
    async saveNewsLetter(value) {
      // console.debug('saveNewsLetter', value)
      const dto = {
        subscribedToNewsletter: value ? 'true' : 'false'
      }
      try {
        await user.setProps(dto)
        showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    async generateTOTP() {
      try {
        const res = await user.credentialTOTPGenerate()
        //console.log("totp generated", res)
        //showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
        this.TwoFA_TOTPSecret = res.secret
        this.TwoFA_TOTP_QRCode_ImageData = res.qrCode        
        showToastSuccess(this.$t('account.personalSettings.2FAMethods.OTPQRCodeGenerated'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    async activateTOTP() {
      try {        
        const dto = {
          secret: this.TwoFA_TOTPSecret,
          code: this.TwoFA_TOTPCode,
          device: this.TwoFA_TOTPDevice,
        }
        const res2 = await user.credentialTotp(dto)
        this.TwoFA_TOTPSecret = null
        this.TwoFA_TOTP_QRCode_ImageData = null
        await this.get2FAInfos()
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    
    async generateRecoveryCodes() {
      try {
        const res = await user.credentialRecoveryCodesGenerate()
        //console.log("recovery codes generated", res)
        //showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
        this.recoveryCodesGenerated = res.codes
        this.recoveryCodesId = res.id
        const res2 = await user.credentialEnable(this.recoveryCodesId)
        //console.log("credentialEnable", res2)
        await this.get2FAInfos()
        showToastSuccess(this.$t('account.personalSettings.2FAMethods.twoFactMethodSuccessfullyEnabled'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    async enableOTP() {
      try {
        const res = await user.enableEmailOtp()
        await this.get2FAInfos()
        showToastSuccess(this.$t('account.personalSettings.2FAMethods.twoFactMethodSuccessfullyEnabled'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    async deleteCredential(id) {
      try {
        const res = await user.deleteCredential(id)
        //console.log("deleteCredential", res)
        await this.get2FAInfos()
        showToastSuccess(this.$t('account.personalSettings.2FAMethods.twoFactMethodSuccessfullyDeleted'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    async webauthnStartRegistration() {
      try {
        const res = await user.webauthnStartRegistration()
        //console.log("webauthnStartRegistration", res)
        const webAuthnRequestId = res.headers['x-webauthnrequestid']    
        //console.log("webAuthnRequestId", webAuthnRequestId)
        //showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
        const creationOptions = parseCreationOptionsFromJSON(res.data)
        //console.log("creationOptions", creationOptions)
        const output = await create(creationOptions)
        //console.log("webauthnStartRegistration output", output)

        this.webauthnFinishRegistration(webAuthnRequestId, output)
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    async webauthnFinishRegistration(XWebAuthnRequestId, req) {
      try {
        const res = await user.webauthnFinishRegistration(XWebAuthnRequestId, req)
        //console.log("webauthnStartRegistration", res)
        //showToastSuccess(this.$t('account.personalSettings.successfullUpdate'))
        await this.get2FAInfos()
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    async uploadCsv() {
      try {
        const selectedType = this.csvTypes.find(item => item.display === this.selectedCsvType)
        const formData = new FormData()
        formData.append('type', selectedType.value)
        formData.append('file', this.selectedFile)

        /*const response = await this.$axios.post(
          `${apiUrl}/identities/import`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data;boundary="boundary"',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );*/
        const response = await identities.importPasswordIdentities(formData)
        //console.debug('Upload successful:', response.data);
        showToastSuccess(this.$t('account.personalSettings.successfullCSVImport'))
        //this.$emit('identityRefresh')
        this.$router.replace('/identities/')        
      } catch (error) {
        console.error('Error uploading CSV:', error);
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      }
    },
    
  }
}
</script>

<style></style>
