<template>
  <div v-if="isLoggedIn">
    <v-app id="inspire">
      <v-app-bar
        app
        color="white"
        flat
        :height="$vuetify.breakpoint.smAndDown ? '128' : '56'"
      >
        <!--         <v-avatar
          :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'"
          size="32"
        ></v-avatar> -->
        <v-img
          lazy-src="https://assets.dedicated-id.com/logo-black.png"
          max-height="32"
          max-width="32"
          src="https://assets.dedicated-id.com/logo-black.png"
          class="mx-auto dedilogo"
          @click="redirectToLanding"
        >
        </v-img>
        <v-spacer></v-spacer>
        <v-alert
          v-if="!isExtensionInstalled"
          outlined
          dense
          type="error"
          class="ml-3 mt-3 mb-3"
        >
          <v-row align="center">
            <v-col class="d-flex grow" align="left" justify="center">
              {{ $t("onboarding.extensionIsNotInstalled") }}
            </v-col>
            <v-col class="d-flex shrink" align="center" justify="center">
              <v-btn :href="extensionDownloadLink">{{
                $t("onboarding.installExtensionBanner")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <v-spacer></v-spacer>

        <!-- <v-btn v-tooltip="$t('main.teamsHint')" icon to="/teams">
          <v-icon>mdi-account-group</v-icon>
        </v-btn> -->

        <v-btn
          id="webmail_button"
          v-tooltip="$t('main.webmailHint')"
          icon
          :href="webmailURL"
          target="_blank"
        >
          <v-icon>mdi-email</v-icon>
        </v-btn>

        <v-btn
          v-tooltip="$t('main.profileHint')"
          icon
          to="/user/personal-settings"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>

        <v-btn v-tooltip="$t('main.logoutHint')" icon @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main class="grey lighten-3">
        <v-container fluid>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="10">
              <v-sheet min-height="70vh" rounded="lg">
                <router-view></router-view>
              </v-sheet>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-main>

      <div class="versionBottom">
        <div>{{ backendVersion }}</div>        
      </div>
      <vue-cookie-accept-decline
          :debug="false"
          :disableDecline="true"
          :showPostponeButton="false"
          @clicked-accept="cookieClickedAccept"
          @clicked-decline="cookieClickedDecline"
          @clicked-postpone="cookieClickedPostpone"
          @removed-cookie="cookieRemovedCookie"
          @status="cookieStatus"
          elementId="dedicatedIDCookieBanner"
          position="bottom"
          ref="dedicatedIDCookieBanner"
          transitionName="slideFromBottom"
          type="bar"
        >
          <!-- Optional -->
          <template #postponeContent>&times;</template>

          <!-- Optional -->
          <template #message>
            {{ $t("cookie.bannerDescription") }}
            <a :href="cookiePolicyURL" target="_blank">{{
              $t("cookie.learnMore")
            }}</a>
          </template>

          <!-- Optional -->
          <template #declineContent>{{ $t("cookie.optOut") }}</template>

          <!-- Optional -->
          <template #acceptContent>{{ $t("cookie.acceptPolicy") }}</template>
        </vue-cookie-accept-decline>
    </v-app>
  </div>
</template>

<script>
import Roles from "../Roles.js";
import store from "../store";
import { showToastError } from "@/event-bus";
import server from "@/services/api/server.js";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";

export default {
  components: {
    VueCookieAcceptDecline,
  },

  data: () => ({
    backendVersion: "",
    webmailURL: import.meta.env.VITE_WEBMAIL_URL,
    landingURL: import.meta.env.VITE_LANDING_URL,
    extensionDownloadLink: import.meta.env.VITE_EXTENSION_DOWNLOAD_URL,
    extensionId: import.meta.env.VITE_CHROME_EXTENSION_ID, // Replace with your extension's actual ID
    cookiePolicyURL: import.meta.env.VITE_COOKIEPOLICY_URL,
    hotharSiteId: import.meta.env.VITE_EXTENSION_HOTJAR_SITEID,
    isChrome: false,
    useForcedClassMethodForExtensionCheck: false,
    useClassMethodForExtensionCheck: false,
    isExtensionInstalled: true,
    actualTab: "/dashboard",
    menus: [
      /* todo temp removed {
        name: 'menus.dashboard',
        route: '/dashboard',
        allowedRoles: [Roles.USER, Roles.ADMIN]
      }, */
      {
        name: "menus.identities",
        route: "/identities",
        allowedRoles: [Roles.USER, Roles.ADMIN],
      },
      {
        name: "menus.warehouses",
        route: "/warehouses",
        allowedRoles: [Roles.ADMIN],
      },
      // {
      //   name: 'menus.clients',
      //   route: '/clients',
      //   allowedRoles: [Roles.USER, Roles.ADMIN]
      // },
      {
        name: "menus.account",
        route: "/user/personal-settings",
        allowedRoles: [Roles.USER, Roles.ADMIN],
      },
    ],
  }),
  computed: {
    isLoggedIn() {
      return !!this.$store.state.currentUser;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    hasMatchedMenuRoles() {
      return this.menus.filter((item) => {
        return this.hasMatchedRoles(
          item.allowedRoles,
          this.$store.state.currentUser.profile.forge_roles
        );
      });
    },
    isSharedSMSWallRoute() {
      return this.$route.path.startsWith("/smswall/");
    },
    sharedSMSWallRoute() {
      // Generate the correct route for the tab
      return this.$route.path;
    },
  },
  async mounted() {
    this.webmailURL = import.meta.env.VITE_WEBMAIL_URL;
    try {
      const version = await server.getVersion();
      //console.log("backend version", version);
      //console.log("cookiePolicyURL", this.cookiePolicyURL);
      
      //this.backendVersion = rsspVersion.versionStringShort
      this.backendVersion = version.versionString;
    } catch (error) {
      showToastError("Can't get DedicatedID version info" + " " + error);
    }
    if (this.isChrome && !this.useClassMethodForExtensionCheck) {
      this.checkExtension();
    }
  },
  created() {
    // Set the initial value of actualTab based on the current route
    if (this.isSharedSMSWallRoute) {
      this.actualTab = this.sharedSMSWallRoute;
    } else {
      // Set actualTab to some default tab path if not on SharedSMSWall
      this.actualTab = "/dashboard"; // Change this to your default tab path
    }

    this.isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
    this.useClassMethodForExtensionCheck =
      !this.isChrome || this.useForcedClassMethodForExtensionCheck;
    if (this.useClassMethodForExtensionCheck) {
      if (document.readyState === "complete") {
        // The document is already fully loaded
        this.onDocumentLoad();
      } else {
        // Add an event listener for the document's readiness change
        document.addEventListener(
          "readystatechange",
          this.onDocumentStateChange
        );
      }
    }
  },
  destroyed() {
    // Remove the event listener when the component is destroyed
    if (this.useClassMethodForExtensionCheck) {
      document.removeEventListener(
        "readystatechange",
        this.onDocumentStateChange
      );
    }
  },
  methods: {
    onDocumentStateChange() {
      if (document.readyState === "complete") {
        this.onDocumentLoad();
        document.removeEventListener(
          "readystatechange",
          this.onDocumentStateChange
        );
      }
    },
    async logout() {
      await this.$auth.logout();
    },
    hasMatchedRoles(itemRoles, currentUserRoles) {
      const matchedRoles = currentUserRoles.filter((role) =>
        itemRoles.includes(role)
      );
      return !!matchedRoles.length;
    },
    redirectToLanding() {
      // console.debug('redirecting to landing page')
      window.location.href = this.landingURL;
    },
    async checkExtensionImage(extensionId) {
      const imageUrl = `chrome-extension://${extensionId}/images/logo-white.svg`;

      // console.debug('checking imageUrl exists', imageUrl)

      return new Promise((resolve) => {
        const image = new Image();
        image.src = imageUrl;

        image.onload = () => {
          resolve(true); // Extension is installed, image loaded successfully
        };

        image.onerror = () => {
          resolve(false); // Extension is not installed, image failed to load
        };
      });
    },
    hasBodyClass(className) {
      const classList = document.body.classList;
      /* console.debug('body.classList.length', classList.length)
      console.debug('body.classList', classList)
      console.debug(classList.contains(className)) */

      if (classList.contains(className)) {
        return true; // The specified class is present in the body
      } else {
        return false; // The specified class is not present in the body
      }
    },
    async checkExtension() {
      if (this.isChrome) {
        // console.debug('Checking extensions installed in Chrome')
        this.isExtensionInstalled = await this.checkExtensionImage(
          this.extensionId
        );
        // const isExtensionInstalled = this.hasBodyClass('dedicatedIDExtensionInstalled')
        store.commit("setisExtensionInstalled", this.isExtensionInstalled);

        /* if (this.isExtensionInstalled) {
          console.debug('Extension is installed.')
        } else {
          console.debug('Extension is not installed.')
        } */
        return this.isExtensionInstalled;
      } /* else {
        return this.hasBodyClass('dedicatedIDExtensionInstalled')
      } */
    },
    onDocumentLoad() {
      // console.debug('Checking extensions installed via classList method')
      this.isExtensionInstalled = this.hasBodyClass(
        "dedicatedIDExtensionInstalled"
      );
      store.commit("setisExtensionInstalled", this.isExtensionInstalled);
      /* if (this.isExtensionInstalled) {
        console.debug('Extension is installed.')
      } else {
        console.debug('Extension is not installed.')
      } */
    },
    handleExtensionInstalled() {},
    initHotjar() {      
      /*const hotjarVersion = 6;
      Hotjar.init(this.hotharSiteId, hotjarVersion);*/
    },
    cookieClickedAccept() {
      //console.log("cookieClickedAccept")
    },
    cookieClickedDecline() {
      //console.log("cookieClickedDecline")
    },
    cookieClickedPostpone() {
      //console.log("cookieClickedPostpone")
    },
    cookieStatus(status) {
      //console.log("cookieStatus", status)
      if (status === 'decline') {

      } else {
        this.initHotjar()
      }
    },
    cookieRemovedCookie() {
      //console.log("cookieRemovedCookie")
    },
  },
};
</script>

<style scoped>
.dedilogo {
  cursor: pointer;
}
.menutabs {
  max-width: 630px;
}

.versionBottom {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-color: inherit;
  opacity: 0.65;
  font-size: 15px;
}
</style>
