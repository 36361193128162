<template>
  <div class="mx-16">
    <v-banner
      class="mb-6"
      icon="mdi-warehouse"
    >
      {{ $t('warehouse.createOrUpdate.title') }}
    </v-banner>
    <v-form ref="warehouseForm" v-model="warehouseFormValid">
      <v-row>
        <v-col
          md="4"
        >
          <v-text-field
            v-model="location"
            :label="$t('warehouse.createOrUpdate.location')"
            :counter="maxCharacters"
            :rules="[rules.required,rules.minMax]"
          >
          </v-text-field>
        </v-col>

        <v-col md="6">
          <v-text-field
            v-model="streetAddress"
            :label="$t('warehouse.createOrUpdate.streetAddress')"
            :counter="maxCharacters"
            :rules="[rules.required,rules.minMax]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="locality"
            :label="$t('warehouse.createOrUpdate.locality')"
            :counter="maxCharacters"
            :rules="[rules.required,rules.minMax]"
          >
          </v-text-field>
        </v-col>

        <v-col md="4">
          <v-text-field
            v-model="region"
            :label="$t('warehouse.createOrUpdate.region')"
            :counter="maxCharacters"
            :rules="[rules.minMax]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="4"
        >
          <!-- <v-text-field
            v-model="country"
            :label="$t('warehouse.createOrUpdate.country')"
            :counter="maxCharacters"
            :rules="[rules.required,rules.minMax]"
          >
          </v-text-field> -->
          <v-select
            v-model="country"
            :label="$t('warehouse.createOrUpdate.country')"
            :items="countries"
            prepend-icon="mdi-map"
            single-line
          ></v-select>
        </v-col>

        <v-col
          md="2"
        >
          <v-text-field

            v-model="postalCode"
            :label="$t('warehouse.createOrUpdate.postalCode')"
            :counter="maxCharacters"
            :rules="[rules.required,rules.minMax]"
          >
          </v-text-field>
        </v-col>

        <v-col
          md="4"
        >
          <v-text-field
            v-model="phoneNumber"
            :label="$t('warehouse.createOrUpdate.phoneNumber')"
            type="tel"
            :rules="[rules.required,rules.minMax]"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4" justify="center">
        <v-col md="8">
          <v-slider
            v-model="priority"
            min="1"
            :label="$t('warehouse.createOrUpdate.priority')"
            thumb-label
            :rules="[rules.required]"
          >
          </v-slider>
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        :loading="btnLoading"
        :disabled="!warehouseFormValid"
        @click="createOrUpdateWarehouse"
      >
        {{ $t('main.create') }}
      </v-btn>
      <v-btn text class="mx-4"
             @click="$refs.clientForm.reset()"
      >
        {{ $t('main.clear') }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import admin from '../../services/api/admin.js'
import { showToastSuccess, showToastError } from '../../event-bus'
import server from '@/services/api/server.js'
export default {
  data() {
    return {
      warehouseFormValid: false,
      btnLoading: false,
      // props for validation
      minCharacters: 1,
      maxCharacters: 255,
      rules: {
        required: (v) => !!v || !isNaN(parseFloat(v)) || this.$t('validation.form.requiredField'),
        minMax: (v) => ((v && v.length >= this.minCharacters) && (v && v.length <= this.maxCharacters)) || this.$t('validation.form.mustBeBetween', { min: this.minCharacters, max: this.maxCharacters })
      },
      // input modelling
      location: '',
      priority: null,
      phoneNumber: null,
      streetAddress: '',
      locality: '',
      region: '',
      postalCode: '',
      country: '',
      countries: []
    }
  },

  async mounted() {
    const countriesObj = await server.getCountries()
    Object.entries(countriesObj).forEach(element => {
      const [key, value] = element
      this.countries.push({
        text: key,
        value: value
      })
    })
  },
  methods: {
    async createOrUpdateWarehouse() {
      this.btnLoading = true
      // csunya de kell ez a string concationation mert ellenben unexpected string expression
      const streetAddressWithTemplate = this.streetAddress + '\nSuite #[[${' + 'tag}]]'
      const wareHouseDto = {
        location: this.location,
        priority: this.priority,
        phoneNumber: this.phoneNumber,
        streetAddressTemplate: streetAddressWithTemplate,
        locality: this.locality,
        postalCode: this.postalCode,
        countryCode: this.country
      }
      try {
        await admin.createWarehouse(wareHouseDto)
        showToastSuccess(this.$t('warehouse.createOrUpdate.successfulCreationMsg'))
      } catch (error) {
        console.error(error)
        showToastError(this.$t('warehouse.createOrUpdate.errorWhileCreatingMsg'))
      } finally {
        this.btnLoading = false
      }
    }
  }
}

</script>

<style>
</style>
