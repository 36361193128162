import createAxios from './api'

const PATH = 'sms'

export default {

  async getSMSItems(phoneNumber, q, pageRequest) {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/list/${phoneNumber}`,
      {
        params: {
          q,
          ...pageRequest
        }
      })
    return resp.data
  }

}
