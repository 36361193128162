import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import TheWelcome from '../views/welcome/TheWelcome.vue'
import NotFound from '../views/NotFound.vue'
import WarehousesView from '../views/warehouses/WarehousesView.vue'
import WarehouseCreateOrUpdate from '../views/warehouses/WarehouseCreateOrUpdate.vue'
// import ClientsView from '../views/clients/old_ClientsView.vue'
import CreateOrUpdateClient from '../views/clients/ClientCreateOrUpdate.vue'
// import ClientBrowserExtension from '../views/clients/ClientBrowserExtension.vue'
// import AdminCreateWebmail from '../views/clients/AdminCreateWebmail.vue'
// import AdminCreateMailServer from '../views/clients/AdminCreateMailServer.vue'
import UserView from '../views/user/UserView.vue'
import UserSettings from '../views/user/UserSettings.vue'
import UserIdentities from '../views/user/UserIdentities.vue'
import CreateIdentity from '../views/identity/CreateIdentity.vue'
import UpdateIdentity from '../views/identity/UpdateIdentity.vue'
import SharedSMSWall from '../views/sms/SharedSMSWall.vue'

import Teams from '../views/teams/Teams.vue'
import CreateTeam from '../views/teams/CreateTeam.vue'
import UpdateTeam from '../views/teams/UpdateTeam.vue'

import Role from '../Roles.js'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  /* todo temporary removed
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  },
  */
  {
    path: '/welcome',
    name: 'welcome',
    component: TheWelcome,
    meta: {
      layout: 'blank',
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  },
  {
    path: '/',
    redirect: 'welcome'
  },
  {
    path: '/warehouses',
    name: 'warehouses',
    component: WarehousesView,
    children: [
      {
        path: '',
        redirect: '/warehouses/create-warehouse',
        meta: {
          allowedRoles: [Role.ADMIN]
        }
      },
      {
        path: 'create-warehouse',
        name: 'create-warehouse',
        component: WarehouseCreateOrUpdate,
        meta: {
          allowedRoles: [Role.ADMIN]
        }
      }
    ],
    meta: {
      allowedRoles: [Role.ADMIN]
    }
  },
  {
    path: '/smswall/:phoneNumber',
    name: 'sharedsmswall',
    component: SharedSMSWall,
    meta: {
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  },
  {
    path: '/identities',
    name: 'identities',
    component: UserIdentities,
    children: [
      {
        path: '/identities/add',
        name: 'CreateIdentity',
        components: { dialog: CreateIdentity },
        meta: {
          allowedRoles: [Role.ADMIN, Role.USER]
        }
      },
      {
        path: '/identities/:identityId/update',
        name: 'UpdateIdentity',
        components: { dialog: UpdateIdentity },
        meta: {
          allowedRoles: [Role.ADMIN, Role.USER]
        }
      }
    ]
  },
  {
    path: '/teams',
    name: 'teams',
    component: Teams,
    children: [
      {
        path: '/teams/add',
        name: 'CreateTeam',
        components: { dialog: CreateTeam },
        meta: {
          allowedRoles: [Role.ADMIN, Role.USER]
        }
      },
      {
        path: '/teams/:teamId/update',
        name: 'UpdateTeam',
        components: { dialog: UpdateTeam },
        meta: {
          allowedRoles: [Role.ADMIN, Role.USER]
        }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: UserView,
    children: [
      {
        path: '',
        redirect: '/user/personal-settings'
      },
      {
        path: 'personal-settings',
        name: 'personal-settings',
        component: UserSettings
      },
      /* {
        path: 'identities',
        name: 'identities',
        component: UserIdentities
      }, */
      {
        path: 'create-client',
        name: 'create-client',
        component: CreateOrUpdateClient
      }
      // {
      //   path: 'browser-extension',
      //   name: 'browser-extension',
      //   component: ClientBrowserExtension,
      //   meta: {
      //     allowedRoles: [Role.ADMIN]
      //   }
      // },
      // {
      //   path: 'webmail',
      //   name: 'webmail',
      //   component: AdminCreateWebmail,
      //   meta: {
      //     allowedRoles: [Role.ADMIN]
      //   }
      // },
      // {
      //   path: 'mail-server',
      //   name: 'mail-server',
      //   component: AdminCreateMailServer,
      //   meta: {
      //     allowedRoles: [Role.ADMIN]
      //   }
      // }
    ],
    meta: {
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  },
  {
    path: '/not-found',
    name: 'not-Found',
    component: NotFound,
    meta: {
      layout: 'blank',
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  },
  {
    path: '/forge-login-callback',
    meta: {
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  },
  {
    path: '/permission-denied',
    name: 'permission-denied',
    component: () => import('@/views/PermissionDenied.vue'),
    meta: {
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  },
  {
    path: '*',
    redirect: 'not-Found',
    meta: {
      allowedRoles: [Role.ADMIN, Role.USER]
    }
  }
]

function createRouter(auth) {
  const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes
  })

  router.beforeEach(async (to, from, next) => {
    // console.log('router.beforeEach', to, from, next)
    if (to.path === '/forge-login-callback') {
      try {
        let state = await auth.handleLoginCallback()
        store.commit('setCurrentUser', await auth.getUser())
        // console.log('state', state)
        // todod: temporary removing dashboard redirect
        if (state === 'dashboard') {
          state = 'identities'
        }
        next({
          name: state,
          replace: true
        })
      } catch (error) {
        console.error('Exception occured during forge-login-callback', error)
        next({ name: 'welcome' })
      }

      return
    }

    const user = await auth.getUser()

    if (user) {
      const { allowedRoles } = to.meta
      if (allowedRoles) {
        const matchedRoles = store.state.currentUser.profile.forge_roles.filter(role => allowedRoles.includes(role))
        if (!matchedRoles.length) {
          next({ name: 'permission-denied' })
          return
        }
      }
    }

    if (to.name === 'welcome') {
      if (user) {
        // next({ name: 'dashboard' })
        next({ name: 'identities' })
      } else {
        next()
      }

      return
    }

    if (!user) {
      next({ name: 'welcome', query: { redirect: to.name } })

      return
    }

    next()
  })

  auth.addUserUnloaded(() => {
    router.push({ name: 'welcome' })
  })

  return router
}

export default createRouter
