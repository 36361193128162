import Vue from 'vue'

const eventBus = new Vue()

function showToastSuccess(message) {
  eventBus.$emit('showToast', { icon: 'mdi-check', type: 'success', message })
}

function showToastError(message) {
  eventBus.$emit('showToast', { icon: 'mdi-block-helper', type: 'error', message })
}

function showConfirmationDialog(title, text) {
  eventBus.$emit('showConfirmationDialog', { title, text })
  return new Promise(resolve => {
    eventBus.$once('confirmationAccept', data => resolve(data.value))
  })
}

function showConfirmationDialogWithCheckBox(title, text, checkboxText) {
  eventBus.$emit('showConfirmationDialogWithCheckBox', { title, text, checkboxText })
  return new Promise(resolve => {
    eventBus.$once('confirmationAccept', data => resolve(data.value))
  })
}

function showRequestLoadingDialog(status) {
  eventBus.$emit('showRequestLoadingDialog', { status })
}

function showOTPDialog(title) {
  // console.debug('inside showOTPDialog, emmitting now')
  eventBus.$emit('showOTPDialog', { title })
  return new Promise(resolve => {
    eventBus.$once('otpTyped', data => resolve(data.value))
  })
}

export default eventBus
export { showToastSuccess, showToastError, showConfirmationDialog, showRequestLoadingDialog, showConfirmationDialogWithCheckBox, showOTPDialog }
