<template>
  <div>
    <v-banner
      class="mb-6"
      icon="mdi-application-settings-outline"
    >
      {{ $t('clients.createOrUpdate.createTitle') }}
    </v-banner>
    <v-form
      ref="clientForm" v-model="clientFormValid"
    >
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            {{ $t('clients.createOrUpdate.stepOne') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            {{ $t('clients.createOrUpdate.stepTwo') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            {{ $t('clients.createOrUpdate.stepThree') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <!-- BASIC INFO -->
          <v-stepper-content step="1">
            <v-card class="my-6 mx-1" elevation="0">
              <v-text-field
                v-model="clientName"
                :label="$t('clients.createOrUpdate.name')"
                :counter="maxCharacters"
                :rules="[rules.required,rules.minMax]"
              >
              </v-text-field>

              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="publicAccess"
                    :rules="[rules.required]"
                    :label="$t('clients.createOrUpdate.publicAccess')"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-select
                    v-if="isAdmin"
                    v-model="chosenClientType"
                    :items="clientTypes"
                    :label="$t('clients.createOrUpdate.clientTypeLabel')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <div max-width="280" class="pl-3">
                  <v-combobox
                    v-if="chosenClientType ==='SERVICE'"
                    v-model="selectedClientMode"
                    :items="clientModes"
                    :label="$t('clients.createOrUpdate.modes')"
                    outlined
                    dense
                  ></v-combobox>
                </div>
              </v-row>
            </v-card>

            <v-row class="mx-1 mt-8 mb-2">
              <v-btn
                class="px-4"
                color="primary"
                @click="e1 = 2"
              >
                {{ $t('main.continue') }}
              </v-btn>
              <v-btn
                text class="mx-4"
                @click="$refs.clientForm.reset()"
              >
                {{ $t('main.clear') }}
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!-- URLS -->
          <v-stepper-content step="2">
            <v-card class="my-6" elevation="0">
              <v-text-field
                v-model="logoUri"
                :label="$t('clients.createOrUpdate.logoUri')"
                :counter="maxCharacters"
                :rules="[rules.url]"
              >
              </v-text-field>
              <v-text-field
                v-model="clientUri"
                :label="$t('clients.createOrUpdate.clientUri')"
                :counter="maxCharacters"
                :rules="[rules.url]"
              >
              </v-text-field>
              <v-text-field
                v-model="policyUri"
                :label="$t('clients.createOrUpdate.policyUri')"
                :counter="maxCharacters"
                :rules="[rules.url]"
              >
              </v-text-field>
              <v-text-field
                v-model="tosUri"
                :label="$t('clients.createOrUpdate.tosUri')"
                :counter="maxCharacters"
                :rules="[rules.url]"
              >
              </v-text-field>

              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="newUriToManage"
                    :label="$t('clients.createOrUpdate.managedUris')"
                    :counter="maxCharacters"

                    @keyup.enter="addManageUri"
                  >
                    <!-- TODO lehetne jobb , torles -->
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-list>
                    <v-subheader v-if="managedUris.length === 0" class="subheading">
                      {{ $t('clients.createOrUpdate.noManagedUrisYet') }}
                    </v-subheader>
                    <v-subheader v-else class="subheading">{{ $t('clients.createOrUpdate.listOfManagedTitle') }}</v-subheader>
                    <v-list-item v-for="(m,index) in managedUris" :key="index">
                      <v-list-item-content>{{ m }}</v-list-item-content>
                      <v-btn fab ripple x-small color="red" @click="removeManageduri(i)">
                        <v-icon class="white--text">mdi-close</v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>

            <v-row class="mx-1 mt-8 mb-2">
              <v-btn
                class="px-4"
                color="primary"
                @click="e1 = 3"
              >
                {{ $t('main.continue') }}
              </v-btn>
              <v-btn
                text
                class="mx-4"
                @click="e1--"
              >
                {{ $t('main.cancel') }}
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card class="my-6" elevation="0">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="minimumLengthPwdRule"
                    type="number"
                    :rules="[rules.minAsInteger]"
                    :label="$t('clients.createOrUpdate.pwdRule.minLength')"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="upperPwdRule"
                    type="number"
                    :rules="[rules.minAsInteger]"
                    :label="$t('clients.createOrUpdate.pwdRule.minUpper')"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="lowerPwdRule"
                    type="number"
                    :rules="[rules.minAsInteger]"
                    :label="$t('clients.createOrUpdate.pwdRule.minLower')"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="digitPwdRule"
                    type="number"
                    :rules="[rules.minAsInteger]"
                    :label="$t('clients.createOrUpdate.pwdRule.minDigit')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="customChars"
                    :label="$t('clients.createOrUpdate.pwdRule.customChars')"
                    :rules="[rules.minMax]"
                    placeholder=".-:!$"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="customPwdRule"
                    type="number"
                    :rules="[rules.minAsInteger]"
                    :label="$t('clients.createOrUpdate.pwdRule.minCustom')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>

            <!--

 -->
            <v-row class="mx-1 mt-8 mb-2">
              <v-btn
                color="primary"
                class="px-4"
                :loading="btnLoading"
                :disabled="!clientFormValid"
                @click="createOrUpdateClient"
              >
                {{ $t('main.create') }}
              </v-btn>

              <v-btn text class="mx-4" @click="e1--">
                {{ $t('main.cancel') }}
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-spacer></v-spacer>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Role from '../../Roles.js'
import client from '@/services/api/client.js'
import { showToastSuccess, showToastError } from '@/event-bus'
import clientTypes from './clientTypes.js'
export default {

  data() {
    return {
      // props for the clientDto
      clientTypes: clientTypes,
      chosenClientType: 'IDENTITY',
      clientName: '',
      publicAccess: false,
      redirectUris: [],
      logoUri: '',
      clientUri: '',
      policyUri: '',
      tosUri: '',
      newUriToManage: '',
      managedUris: [],
      upperPwdRule: null,
      lowerPwdRule: null,
      digitPwdRule: null,
      customPwdRule: null,
      customChars: '',
      minimumLengthPwdRule: 6,
      trusted: false,
      selectedClientMode: 'Regular',
      clientModes: [
        'Regular', 'Browser Extension', 'Mail Server', 'Webmail'
      ],
      // props for validation
      e1: 1,
      clientFormValid: false,
      btnLoading: false,
      minCharacters: 1,
      maxCharacters: 255,
      validUrlExpression: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      rules: {
        required: (v) => !!v || !isNaN(parseFloat(v)) || this.$t('validation.form.requiredField'),
        minMax: (v) => ((v && v.length >= this.minCharacters) && (v && v.length <= this.maxCharacters)) || this.$t('validation.form.mustBeBetween', { min: this.minCharacters, max: this.maxCharacters }),
        url: (v) => (this.validUrlExpression.test(v)) || this.$t('validation.form.invalidUrl'),
        minAsInteger: (v) => (+v >= 0) || this.$t('validation.form.cantBeNegative')
      }
    }
  },
  computed: {
    ...mapGetters(['hasRole']),
    isAdmin() {
      return this.hasRole(Role.ADMIN)
    }
  },
  methods: {
    async createOrUpdateClient() {
      // passwordGeneration object compose TODO could be better
      const pwdrules = {
        length: this.minimumLengthPwdRule,
        rules: [
          {
            name: 'UPPER',
            min: this.upperPwdRule
          },
          {
            name: 'LOWER',
            min: this.lowerPwdRule
          },
          {
            name: 'DIGIT',
            min: this.digitPwdRule
          },
          {
            name: 'CUSTOM',
            chars: this.customChars,
            min: this.customPwdRule
          }
        ]
      }

      // client dto compose

      this.redirectUris.push(window.location.origin)
      const clientDto = {
        name: this.clientName,
        publicAccess: this.publicAccess,
        type: this.chosenClientType,
        redirectUris: this.redirectUris,
        logoUri: this.logoUri,
        clientUri: this.clientUri,
        policyUri: this.policyUri,
        tosUri: this.tosUri,
        managedUris: this.managedUris,
        passwordGeneration: pwdrules
      }
      if (this.selectedClientMode === 'Mail Server') {
        clientDto.roles = ['mail-server']
        clientDto.clientCredentialsGrantAllowed = true
      }
      if (this.isAdmin) {
        clientDto.trusted = this.trusted
      }
      this.btnLoading = true
      try {
        await client.createClient(clientDto)
        showToastSuccess(this.$t('clients.createOrUpdate.successfulCreation'))
      } catch (error) {
        showToastError(this.$t('clients.createOrUpdates.errorWhileCreating'))
        console.log(error)
      } finally {
        this.btnLoading = false
      }
      // TODO: error have to be more precise
    },
    addManageUri() {
      const value = this.newUriToManage
      try {
        this.validUrlExpression.test(value)
      } catch (error) {
        console.error(error)
      }
      if (this.validUrlExpression.test(value) && !this.managedUris.includes(value)) {
        this.managedUris.push(value)
      }
      this.newUriToManage = ''
    },
    removeManageduri(index) {
      this.managedUris.splice(index, 1)
    }
  }
}
</script>

<style>
</style>
