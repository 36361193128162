<template>
  <div class="pt-1 pb-1">
    <v-label>
      <span>
        <span :class="getDataTypeColoring()"> {{ translatedDataType }} </span>{{ ' ' + label }}</span>
    </v-label>
    <div class="d-flex align-center">
      <template v-if="!haveToDecrypt">
        <template v-if="!canEdit">
          <p>{{ value }}</p>
        </template>
        <template v-else>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="birthDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                prepend-icon="mdi-calendar"
                readonly
                :disabled="currentDataType.value === 'NONE'"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              no-title
              scrollable
              :max="maxDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="cancel"
              >
                {{ $t('main.cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="onBirthDateChanged"
              >
                {{ $t('main.ok') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </template>

        <template v-if="mode === 'view'">
          <!-- EVERYTHING ELSE -->
          <v-btn
            v-if="(currentDataType.value === 'PROVIDED') || (canGenerate && currentDataType.value === 'GENERATED')"
            v-tooltip="$t('account.identities.dialog.generateHint')" class="d-inline ml-3" fab small @click="forge"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
      </template>

      <template v-if="haveToDecrypt">
        <div class="decrypt-needed-text">
          <span v-html="$t('account.identities.dialog.authoriseAccessPrivateDate')"></span>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!value"
          v-tooltip="$t('account.identities.dialog.decrypHint')" class="decrypt-needed-btn"
          fab small right top
          @click="decrypt"
        >
          <v-icon>mdi-key</v-icon>
        </v-btn>
      </template>

      <data-type-toggle-outline
        class="pl-3"
        :default-data-type="currentDataType.value"
        :can-be-generated="canGenerate"
        :can-be-provided="canProvide"
        :can-be-real="canBeReal"
        @datatypechanged="switchDataType"
      ></data-type-toggle-outline>

      <v-spacer></v-spacer>
    </div>
    <!--     </v-col>
    </v-row> -->
  </div>
</template>

<script>
import identityDataTypes from './identityDataTypes.js'
import DataTypeToggleOutline from '@/components/DataTypeToggleOutline.vue'
export default {
  name: 'BirthDateSelection',
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: String,
      default: '',
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false,
      required: true
    },
    canGenerate: {
      type: Boolean,
      default: true
    },
    canProvide: {
      type: Boolean,
      default: false
    },
    canBeReal: {
      type: Boolean,
      default: false
    },
    canForge: {
      type: Boolean,
      default: false
    },
    identityEncrypted: {
      type: Boolean,
      default: false
    },
    defaultDataType: {
      type: String,
      required: false,
      default: 'GENERATED'
    }
  },
  emits: ['forge', 'save', 'datatypechanged', 'decrypt'],
  data() {
    return {
      birthDate: this.value,
      menu: false,
      mode: 'view',
      inEditMode: false,
      generatedMode: identityDataTypes[0],
      providedMode: identityDataTypes[1],
      realMode: identityDataTypes[2],
      customMode: identityDataTypes[3],
      noneMode: identityDataTypes[4],
      currentDataType: identityDataTypes.find(x => x.value === this.defaultDataType)
    }
  },
  computed: {
    haveToDecrypt() {
      const have2decrypt = this.currentDataType ? (this.currentDataType.value === 'REAL' && this.identityEncrypted) : false
      return have2decrypt
    },
    translatedDataType() {
      if (this.currentDataType.value === this.providedMode.value) {
        return this.$t('account.identities.dialog.providedDatatype')
      }
      if (this.currentDataType.value === this.generatedMode.value) {
        return this.$t('account.identities.dialog.generatedDatatype')
      }
      if (this.currentDataType.value === this.realMode.value) {
        return this.$t('account.identities.dialog.realDatatype')
      }
      if (this.currentDataType.value === this.customMode.value) {
        return this.$t('account.identities.dialog.customDatatype')
      }
      if (this.currentDataType.value === this.noneMode.value) {
        return this.$t('account.identities.dialog.noneDatatype')
      }
      return ''
    },
    maxDate() {
      // Calculate today's date
      const today = new Date()

      // Set the maximum date to today's date
      return today.toISOString().substr(0, 10)
    }
  },
  watch: {
    defaultDataType(newType) {
      // console.log('defaultDataType watch, newType', newType)
      this.currentDataType = identityDataTypes.find(x => x.value === newType)
    }
  },
  methods: {

    async forge() {
      // console.log('birthdate field forge')
      this.$emit('forge')
    },
    async save() {
      // console.log('field save', this.birthDate)
      this.mode = 'view'
      this.$emit('save', this.birthDate)
    },
    cancel() {
      this.mode = 'view'
      this.menu = false
    },
    onBirthDateChanged(value) {
      // console.log('onBirthDateChanged', this.birthDate)
      this.$refs.menu.save(this.birthDate)
      this.save()
    },
    switchDataType(mode) {
      // console.log('switchDataType', mode)
      this.currentDataType = mode
      const newType = this.currentDataType.value
      // console.log('newType', newType)
      this.$emit('datatypechanged', newType)
    },
    getDataTypeColoring() {
      if (!this.currentDataType) { return 'tertiary' }
      switch (this.currentDataType.value) {
        case 'REAL':
          return 'data-type-real'
        case 'PROVIDED':
          return 'data-type-provided'
        case 'GENERATED':
          return 'data-type-generated'
        case 'CUSTOM':
          return 'data-type-custom'
        case 'NONE':
          return 'data-type-none'
        default:
          return 'tertiary'
      }
    },
    decrypt() {
      this.$emit('decrypt')
    }
  }
}
</script>

  <style scoped>
    p {
      margin: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      min-height: 34px;
      white-space: pre;
    }

    v-fab {
      --color: var(--v-text-color);
      --background: none;
      --box-shadow: none;
      margin-left: 0;
      margin-right: 0;
    }

  </style>

  <style>
    .data-type-real {
      --color: var(--v-dataTypeReal-base);
      color: var(--v-dataTypeReal-base);
      z-index: 100;
    }
    .data-type-generated {
      --color: var(--v-generated-base);
      color: var(--v-generated-base);
      z-index: 100;
    }
    .data-type-provided {
      --color: var(--v-provided-base);
      color: var(--v-provided-base);
      z-index: 100;
    }
    .data-type-none {
      --color: var(--v-none-base);
      color: var(--v-none-base);
      z-index: 100;
    }  
    .decrypt-needed-btn {
      z-index: 100;
      color: var(--v-secondary) !important;
    }
    .decrypt-needed-text {
      color: var(--v-step350-base);
      font-size: 14px;
      margin: auto auto;
    }
  </style>
