<template>
  <div>
    <v-banner class="mb-6">
      <div class="display-1 text-center pt-2">{{ $t('account.title') }}</div>
    </v-banner>
    <v-row>
      <!-- <v-col md="3">
        <v-tabs
          vertical
          class="v-tabs-slider-wrapper-right"
        >
          <v-tab v-for="tab in hasMatchedMenuRoles" :key="tab.id" :to="tab.to" exact>
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col> -->
      <v-col>
        <v-container class="pr-8">
          <router-view></router-view>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Roles from '@/Roles.js'

export default {
  data() {
    return {
      tabs: [
        /* {
          id: '0',
          name: this.$t('account.tabs.settings'),
          to: '/user/personal-settings',
          roles: [Roles.USER, Roles.ADMIN]

        }, */
        {
          id: '0',
          name: this.$t('account.tabs.identities'),
          to: '/user/identities',
          roles: [Roles.USER, Roles.ADMIN]
        }/*,
        {
          id: '1',
          name: this.$t('account.tabs.clients'),
          to: '/user/create-client',
          roles: [Roles.USER, Roles.ADMIN]

        } */
        // {
        //   id: '3',
        //   name: this.$t('clients.tabs.extensions'),
        //   to: '/user/browser-extension',
        //   roles: [Roles.ADMIN]
        // },
        // {
        //   id: '4',
        //   name: this.$t('clients.tabs.webmail'),
        //   to: '/user/webmail',
        //   roles: [Roles.ADMIN]
        // },
        // {
        //   id: '5',
        //   name: this.$t('clients.tabs.mailserver'),
        //   to: '/user/mail-server',
        //   roles: [Roles.ADMIN]
        // }
      ]
    }
  },
  computed: {
    hasMatchedMenuRoles() {
      return this.tabs.filter(item => {
        return this.hasMatchedRoles(item.roles, this.$store.state.currentUser.profile.forge_roles)
      })
    }
  },
  methods: {
    hasMatchedRoles(itemRoles, currentUserRoles) {
      const matchedRoles = currentUserRoles.filter(role => itemRoles.includes(role))
      return !!matchedRoles.length
    }
  }
}
</script>

<style>
.v-tabs-slider-wrapper-right {
  left: auto !important;
  right: 0;
}
</style>
