import createAxios from './api'
import { showToastError, showOTPDialog } from '@/event-bus'
import i18n from '@/i18n.js'

const PATH = 'users'

export default {
  async createUser(userDto) {
    const axios = await createAxios(false)
    const resp = await axios.post(PATH, userDto)
    return resp.data
  },
  async updateUser(userDto) {
    const axios = await createAxios(true)
    const resp = await axios.put(`${PATH}/self`, userDto)
    return resp.data
  },
  async getUserData(otpTitle, decrypt = false) {
    let axios = await createAxios()
    try {
      const resp = await axios.get(`${PATH}/self`, {
        params: {
          decrypt
        }
      })
      return resp.data
    } catch (error) {
      if (decrypt && error.response?.status === 403 && error.response.data?.code === 'OTP_NEEDED') {
        // console.debug('decrypt needed, calling showOTPDialog')
        const otp = await showOTPDialog(otpTitle)
        // console.debug('returned otp', otp)
        if (otp.length === 0) {
          showToastError(i18n.t('decryption.otpNeededForProfile'))
          return null
        }
        axios = await createAxios()
        try {
          const resp = await axios.get(`${PATH}/self`, {
            headers: {
              'X-OTP': otp
            },
            params: {
              decrypt
            }
          })
          // console.debug('resp after dercyption', resp)
          return resp.data
        } catch (error) {
          const message = error.response.data.message ? error.response.data.message : 'Server error'
          // console.debug('resp after dercyption', error.response.data.message)
          showToastError(i18n.t('decryption.errorDuringDecrpytion', { message: message }))
          console.error('decryption error occured', error)
          // throw new Error('OTP is needed')
          return null
        }
      }
      throw error
    }
  },

  async getMyUserMeta() {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/self/meta`)
    return resp.data
  },
  async getdeletionEffect() {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/self/deletionEffect`)
    return resp.data
  },
  async deleteUser() {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/self`)
    return resp.data
  },
  async changePassword(pswdDto) {
    const axios = await createAxios(false)
    const resp = await axios.post(`${PATH}/self/password`, pswdDto)
    return resp.data
  },
  async deleteAddress(id) {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/addresses/${id}`)
    return resp.data
  },
  async updateAddress(id, addressDto) {
    const axios = await createAxios(true)
    const resp = await axios.put(`${PATH}/addresses/${id}`, addressDto)
    return resp.data
  },
  async createAddress(addressDto) {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/self/addresses/`, addressDto)
    return resp.data
  },
  async deletePhoneNumber(id) {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/phoneNumbers/${id}`)
    return resp.data
  },
  async updatePhoneNumber(id, phoneNumberDto) {
    const axios = await createAxios(true)
    const resp = await axios.put(`${PATH}/phoneNumbers/${id}`, phoneNumberDto)
    return resp.data
  },
  async createPhoneNumber(phoneNumberDto) {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/self/phoneNumbers/`, phoneNumberDto)
    return resp.data
  },
  async getProps() {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/self/props/`)
    return resp.data
  },
  async getProp(propName) {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/self/props/${propName}`)
    return resp.data
  },
  async setProps(dto) {
    const axios = await createAxios(true)
    const resp = await axios.put(`${PATH}/self/props`, dto)
    return resp.data
  },
  async credentialTOTPGenerate() {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/credentials/totp/generate`)
    return resp.data
  },
  async credentialTotp(dto) {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/credentials/totp`, dto)
    return resp.data
  },  
  async credentialEnable(id) {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/credentials/${id}/enable`)
    return resp.data
  },
  async deleteCredential(id) {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/credentials/${id}`)
    return resp.data
  },
  async credentialRecoveryCodesGenerate() {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/credentials/recovery/generate`)
    return resp.data
  },
  async twoFactorInfo() {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/credentials/twoFactorInfo`)
    return resp.data
  },  
  async enableEmailOtp() {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/credentials/emailOtp`)
    return resp.data
  },
  async webauthnStartRegistration() {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/credentials/webauthn/startRegistration`)    
    return resp
  },
  async webauthnFinishRegistration(XWebAuthnRequestId, req) {
    const axios = await createAxios(true)
    const config = {
      headers: {
        'X-WebAuthnRequestId': XWebAuthnRequestId
      }      
    };
    const resp = await axios.post(`${PATH}/credentials/webauthn/finishRegistration`, req, config)
    return resp.data
  },

  

  
}
