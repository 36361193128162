<template>
  <div class="pa-6">
    <!-- DIALOG -->
    <router-view name="dialog" @identityRefresh="fetchIdentities"></router-view>

    <v-row align="stretch" class="ma-0 pa-0">
      <v-card v-if="showExtensionBlockVisible" align="stretch" width="100%" class="pt-6 pl-6 mb-6">
        <v-row class="px-0 py-0">
          <v-col cols="12" md="6" class="pl-0">
            <v-card-title class="text-dashboard-h1 pt-0">
              <span>{{ $t('onboarding.extension.title') }}</span>
              <v-spacer></v-spacer>
              <v-btn v-tooltip="$t('onboarding.closeButton')" icon class="tutorialCloseButton" @click="showDownloadExtensionBlock = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              {{ $t('onboarding.extension.explainer') }}
            </v-card-text>
            <v-btn
              color="primary"
              class="my-6 mx-4 mt-3"
              @click="downloadExtension"
            >
              {{ $t('onboarding.extension.action') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-row align="stretch" class="ma-0 pa-0">
      <v-card v-if="showNoPersonalDetailsFilledBlockVisible" align="stretch" width="100%" class="pt-6 pl-6 mb-6">
        <v-row class="px-0 py-0">
          <v-col cols="12" md="6" class="pl-0">
            <v-card-title class="text-dashboard-h1 pt-0">
              <span>{{ $t('onboarding.noPersonalDetailsFilled.title') }}</span>
              <v-spacer></v-spacer>
              <v-btn v-tooltip="$t('onboarding.closeButton')" icon class="tutorialCloseButton" @click="dismissNoPersonalDetailsFilledBlock">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              {{ $t('onboarding.noPersonalDetailsFilled.explainer') }}
            </v-card-text>
            <v-btn
              color="primary"
              class="my-6 mx-4 mt-3"
              @click="gotoPersonalSettings"
            >
              {{ $t('onboarding.noPersonalDetailsFilled.action') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <!--     <v-row align="stretch" class="ma-0 pa-0">
      <v-card v-if="showMobileAppBlock" align="stretch" width="100%" class="pt-6 pl-6 mt-6">
        <v-row class="px-0 py-0">
          <v-col cols="12" class="pl-0">
            <v-card-title class="text-dashboard-h1 pt-0">
              {{ $t('onboarding.mobileApp.title') }}
            </v-card-title>
            <v-card-text class="mt-3">
              {{ $t('onboarding.mobileApp.explainer') }}
            </v-card-text>
            <v-btn
              color="primary"
              class="my-6 mx-4 mt-3"
              @click="showMobileAppBlock = false"
            >
              {{ $t('onboarding.okUnderstand') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
 -->
    <v-row align="stretch" class="ma-0 pa-0">
      <v-card v-if="showQuickGuideBlockVisible" align="stretch" width="100%" class="pt-6 pl-6  mb-6">
        <v-row class="px-0 py-0">
          <v-col cols="12" md="6" class="pl-0">
            <v-card-title class="text-dashboard-h1 pt-0">
              <span>{{ $t('onboarding.shortGuide.title') }}</span>
              <v-spacer></v-spacer>
              <v-btn v-tooltip="$t('onboarding.closeButton')" icon class="tutorialCloseButton" @click="dismissQuikGuide">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-3">
              <p class="pa-0" v-html="$t('onboarding.shortGuide.explainer')"></p>
            </v-card-text>
            <v-btn
              color="primary"
              class="my-6 mx-4 mt-3"
              @click="setupFirstidentity"
            >
              {{ $t('onboarding.shortGuide.action') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <v-row align="stretch" class="ma-0 pa-0">
      <v-col cols="12" md="12" class="ma-0 pa-0">
        <v-card v-if="showOKBlockVisible" align="stretch" class="pl-6 pt-6 mt-6 mb-6">
          <v-row align="stretch" justify="space-between">
            <v-col cols="12" md="6" class="pl-0 d-flex flex-column">
              <v-card-title class="text-dashboard-h1 pt-0">
                <span>{{ $t('onboarding.requirementsMeet.title') }}</span>
                <v-spacer></v-spacer>
                <v-btn v-tooltip="$t('onboarding.closeButton')" icon class="tutorialCloseButton" @click="dismissOKBlock">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="mt-3">
                {{ $t('onboarding.requirementsMeet.explainer') }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- CONTENT -->
    <v-banner
      class="mb-6"
      icon="mdi-account-supervisor"
    >
      {{ $t('account.identities.title') }}
    </v-banner>

    <v-row>
      <v-btn color="primary" class="ml-3" outlined to="/identities/add">{{ $t('account.identities.addNewIdentityButton') }}</v-btn>
      <v-btn id="resetTutoralBlocks" color="primary" class="ml-3 hiddenTutorialButton" outlined @click="resetTutoralBlocks">{{ $t('account.identities.resetOnboardingBlocks') }} </v-btn>
    </v-row>

    <v-row>
      <v-text-field
        v-model="searchIdentity"
        class="mx-4"
        :label="$t('main.search')"
        append-icon="mdi-magnify"
        single-line
        hide-details
        clearable
        @change="fetchIdentities"
      ></v-text-field>
    </v-row>

    <!-- ORDERING -->
    <v-row class="ml-1 mt-6">
      <template v-if="identities.length > 0">
        <div class="justify-center align-center">
          <a
            style="text-decoration: none !important"
            :class="sortByCreatedAt ? 'bold' : null"
            @click="changeSortByCreatedAt"
          >{{ $t('account.identities.orderByCreatedAt') }}</a>
          <v-btn
            size="small"
            icon
            @click="changeSortByCreatedAt"
          >
            <v-icon
              color="text"
              size="small"
            >
              {{ sortByCreatedAtSortOrder == "desc" ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
            </v-icon>
          </v-btn>
        </div>

        <div class="justify-center align-center">
          <a
            style="text-decoration: none !important"
            :class="sortByDisplayName ? 'bold' : null"
            @click="changeSortByDisplayName"
          >{{ $t('account.identities.orderByDisplayName') }}</a>
          <v-btn
            size="small"
            icon
            @click="changeSortByDisplayName"
          >
            <v-icon
              color="text"
              size="small"
            >
              {{ sortByDisplayNameSortOrder == "desc" ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-row>

    <v-row
      justify="start"
      class="mt-6"
    >
      <v-col
        v-for="(i,index) in currentidentitiesToList" :key="index"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card height="100%" min-height="200" cols="2" class="clickable-card d-flex flex-column" @click="redirectToUpdatePage(i.id, $event)">
          <v-card-title>
            <v-avatar slot="start" class="avatar mr-3">
              <v-img :src="`https://i.pravatar.cc/64?u=${i.imageId}`" />
            </v-avatar>
            {{ i.displayName }}
          </v-card-title>

          <v-card-subtitle ref="identities">
            <div class="pt-3">
              <div>
                <v-chip
                  v-for="(chipText, dindex) in formatDomainBadges(i.domains)" :key="dindex"
                  class="ma-1"
                >
                  {{ chipText }}
                </v-chip>
              </div>
            </div>
            <!-- <template v-if="haveToShowMore(i.domains)">
              <div class="ion-justify-content-center ion-align-items-center">
                <a
                  style="text-decoration: none !important"
                  @click="viewMoreDomainsVisible = !viewMoreDomainsVisible"
                >{{ viewMoreDomainsVisible ? 'Show less' : 'Show all' }}</a>
                <v-btn
                  class="icon-only"
                  size="small"
                  @click="viewMoreDomainsVisible = !viewMoreDomainsVisible"
                >
                  <v-icon
                    color="text"
                    size="small"
                    :icon="!viewMoreDomainsVisible ? chevronDownOutline : chevronUpOutline"
                  ></v-icon>
                </v-btn>
              </div>
            </template> -->
          </v-card-subtitle>
          <v-spacer></v-spacer>

          <v-card-actions class="justify-end mb-2 mr-2">
            <v-label>
              {{ $t('account.identities.dialog.createdAt', {createdAt: formatDate(i.createdAt)}) }}
            </v-label>
            <!-- update identity -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  x-small
                  fab
                  ribble
                  v-bind="attrs"
                  class="mx-2"
                  :to="'/identities/' + i.id + '/update'"
                  v-on="on"
                >
                  <v-icon color="grey" v-on="on">mdi-id-card</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('account.identities.showDetailsTooltip') }}</span>
            </v-tooltip>
            <!-- delete -->
            <v-btn
              v-tooltip="$t('main.delete')"
              x-small
              fab
              ribble
              color="red"
              @click.stop="deleteIdentity(index)"
            >
              <v-icon color="white">mdi-delete-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-btn v-if="nextpagetoken" class="mt-6" @click="loadNextPage"><v-icon>mdi-chevron-right</v-icon>{{ $t('main.loadMore') }}</v-btn>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import identities from '@/services/api/identities.js'
import user from '@/services/api/user.js'
import { showToastSuccess, showToastError, showConfirmationDialogWithCheckBox } from '@/event-bus'
import debounce from 'lodash.debounce'
import { EventSourcePolyfill } from 'event-source-polyfill';

export default {

  name: 'UserIdentities',

  data() {
    return {
      searchIdentity: null,
      nextpagetoken: null,
      page: 1,
      identityPagination: {
        sortBy: ['name']
      },
      loadingItems: false,
      identities: [],
      textFields: [],
      // dialog
      editMode: false,
      showPassword: false,
      dialogNewIdentityMode: false,
      displayName: '',
      displayNameInvalidAnimation: false,
      teamId: 'NONE',
      locale: 'en',
      // tutorial blocks
      extensionDownloadLink: import.meta.env.VITE_EXTENSION_DOWNLOAD_URL,
      showAllBlock: false,
      showDownloadExtensionBlock: true,
      showMobileAppBlock: true,
      showQuickGuideBlock: true,
      showNoPersonalDetailsFilledBlock: true,
      showOKBlock: true,
      // showOKBlockVisible: false,
      tutorialFinished: null,
      sortByCreatedAt: true,
      sortByCreatedAtSortOrder: 'desc',
      sortByDisplayName: false,
      sortByDisplayNameSortOrder: 'desc',
      dialogUpdateLoading: false,
      password: '',
      confPassword: '',
      changePwdFormValid: false,
      dialogTitle: '',
      userDataFormValid: false,
      passwordFormValid: false,
      phoneFieldValid: false,
      oldPassword: '',
      countries: [],
      addresses: [],
      phoneNumbers: [],
      selectedAddressIndex: -1,
      editedAddress: {
        id: '',
        label: '',
        streetAddress: '',
        locality: '',
        postalCode: '',
        region: '',
        countryCode: ''
      },
      editedPhoneNumber: {
        id: '',
        label: '',
        number: '',
        inputValue: '',
        isBlurred: false
      },
      // viewMoreDomainsVisible: false,
      isPhoneprofileDataAuthorised: false,
      savePhoneValid: false,
      isNewAddress: false,
      selectedPhoneIndex: -1,
      isNewPhone: false,
      rules: {
        required: (v) => !!v || !isNaN(parseFloat(v)) || this.$t('validation.form.requiredField'),
        pwdsMatches: (value) => value === this.password || this.$t('validation.form.pwdsMustMatcH')
      },
      selectedUserInfo: {
        id: '',
        canEdit: false,
        name: '',
        username: '',
        email: '',
        gender: '',
        birthdate: '',
        phoneNumber: '',
        postalCode: '',
        region: ''
      }
    }
  },
  computed: {
    // ...mapGetters(['isExtensionInstalled']),
    isExtensionInstalled() {
      // console.log('this.$store.state.isExtensionInstalled', this.$store.state.isExtensionInstalled)
      return this.$store.state.isExtensionInstalled != null ? this.$store.state.isExtensionInstalled : false
    },
    currentidentitiesToList() {
      /* const first = (this.page - 1) * 20

      const lastt = first + 20

      return this.identities.slice(first, lastt) */
      return this.identities
    },
    canEdit() {
      return this.selectedUserInfo.canEdit
    },
    showExtensionBlockVisible() {
      return !this.isExtensionInstalled && this.showDownloadExtensionBlock
    },
    showNoPersonalDetailsFilledBlockVisible() {
      return this.showNoPersonalDetailsFilledBlock && (!this.showExtensionBlockVisible && this.isExtensionInstalled)
    },
    showQuickGuideBlockVisible() {
      return this.showQuickGuideBlock && !this.showExtensionBlockVisible && this.isExtensionInstalled
    },
    showOKBlockVisible() {
      return this.showOKBlock && !this.showQuickGuideBlock && !this.showNoPersonalDetailsFilledBlock
    }
  },

  watch: {
    searchIdentity() {
      this.searchIdentityWatcher()
    }/*,
    isExtensionInstalled(value) {
      console.log('watcher for isExtensionInstalled', value)
    } */
  },
  async beforeMount() {
    await this.fetchIdentities()
  },
  async mounted() {
    const props = await user.getProps()
    // console.log('props', props)

    this.showNoPersonalDetailsFilledBlock = props.onboardingPersonalDataVisible === undefined || props.onboardingPersonalDataVisible === 'true'
    this.showQuickGuideBlock = props.onboardingExplainerVisible === undefined || props.onboardingExplainerVisible === 'true'
    this.showOKBlock = props.onboardingReadyToForgeVisible === undefined || props.onboardingReadyToForgeVisible === 'true'
    const usr = await Vue.prototype.$auth.getUser()
    // console.log(usr)
    this.locale = usr.profile.locale || 'en'

    await this.initEventSource()
  },
  beforeDestroy() {
    if (this.sse) {
      this.sse.close()
    }
  },
  methods: {
    searchIdentityWatcher: debounce(function() {
      this.fetchIdentities()
    }, 500),

    async fetchIdentities() {
      //console.log('fetchIdentities called')
      const pageReq = {
        sortField: this.sortByCreatedAt ? 'createdAt' : 'displayName',
        sortOrder: this.sortByCreatedAt ? this.sortByCreatedAtSortOrder : this.sortByDisplayNameSortOrder,
        maxResults: 3 * 5
      }
      pageReq.nextPageToken = this.nextpagetoken || null
      const resp = await identities.getUserIdentities(this.searchIdentity, pageReq)
      this.identities = resp.items.map(identity => {
        return {
          ...identity,
          isEditing: false, // Initialize isEditing as false
          editedDomain: ''
        }
      })
      // this.identities = (resp.items)
      // console.log(this.identities)
      this.identities.forEach((item, index) => {
        this.textFields[index] = this.$refs[`textFieldRef_${index}`]
      })
      this.nextpagetoken = resp.nextPageToken
    },
    async deleteIdentity(index) {
      const conf = await showConfirmationDialogWithCheckBox(this.$t('account.identities.confirmDelete'), this.$t('account.identities.deleteDetails'), this.$t('account.identities.deleteDetailsCheckbox'))
      if (!conf) {
        this.btnLoading = false
        return
      }

      try {
        await identities.deleteIdentity(this.identities[index].id)
        showToastSuccess(this.$t('account.identities.deleteSucces'))
        // this.$router.push('identities')
        // window.location.reload()
        await this.fetchIdentities()
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.personalSettings.errorWhileUpdating'))
      } finally {
        this.btnLoading = false
      }
    },
    async loadNextPage() {
      this.page++
      const pageReq = {
      }
      pageReq.pageToken = this.nextpagetoken || null
      const resp = await identities.getUserIdentities(this.searchIdentity, pageReq)
      const newIden = resp.items
      const mapped = newIden.map(identity => {
        return {
          ...identity,
          isEditing: false, // Initialize isEditing as false
          editedDomain: ''
        }
      })
      this.identities = this.identities.concat(mapped)
      this.nextpagetoken = resp.nextPageToken
      // console.log(this.identities)
    },
    formatDomainBadges(domains) {
      if (!domains || (domains.length <= 4)) {
        return domains
      }

      const remains = domains.length - 4 + ' more...'
      return [domains[0], domains[1], domains[2], domains[3], remains]
    },
    formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      return new Date(dateString).toLocaleDateString(this.locale, options)
    },
    tutorialFinishedClick() {
      this.showOKBlock = false
    },
    downloadExtension() {
      // this.showDownloadExtensionBlock = false //explainer should open because user needs to read instructions afte downloading the extension
      window.open(this.extensionDownloadLink, '_blank')
    },
    async gotoPersonalSettings() {
      this.showNoPersonalDetailsFilledBlock = false

      await this.dismissNoPersonalDetailsFilledBlock()

      this.$router.push('/user/personal-settings')
    },
    async setupFirstidentity() {
      this.showQuickGuideBlock = false

      await this.dismissQuikGuide()
      const identity = {
        displayName: this.$t('onboarding.myFirstIdentityDisplayName')
      }

      const res = await identities.createDomainIdentity(identity)
      this.$emit('identityRefresh')
      this.$router.push('/identities/' + res.id + '/update')
    },
    async updateProp(dto) {
      try {
        await user.setProps(dto)
      } catch {

      }
    },
    async dismissNoPersonalDetailsFilledBlock() {
      this.showNoPersonalDetailsFilledBlock = false
      const dto = {
        onboardingPersonalDataVisible: 'false'
      }
      await this.updateProp(dto)
    },
    async dismissQuikGuide() {
      this.showQuickGuideBlock = false
      const dto = {
        onboardingExplainerVisible: 'false'
      }
      await this.updateProp(dto)
    },
    async dismissOKBlock() {
      this.showOKBlock = false
      const dto = {
        onboardingReadyToForgeVisible: 'false'
      }
      await this.updateProp(dto)
    },
    async resetTutoralBlocks() {
      this.showDownloadExtensionBlock = true
      this.showNoPersonalDetailsFilledBlock = true
      this.showQuickGuideBlock = true
      this.showOKBlock = true
      const dto = {
        onboardingPersonalDataVisible: 'true',
        onboardingExplainerVisible: 'true',
        onboardingReadyToForgeVisible: 'true'
      }
      await this.updateProp(dto)
    },
    redirectToUpdatePage(id, event) {      
      // Check if the click target was the card itself and not an interactive element within it
      // if (event.target.classList.contains('clickable-card')) {
      this.$router.push('/identities/' + id + '/update')
      // }
    },
    changeSortByCreatedAt() {
      this.sortByCreatedAt = true
      this.sortByCreatedAtSortOrder = this.sortByCreatedAtSortOrder === 'asc' ? 'desc' : 'asc'
      this.sortByDisplayName = false
      this.fetchIdentities()
    },
    changeSortByDisplayName() {
      this.sortByDisplayName = true
      this.sortByDisplayNameSortOrder = this.sortByDisplayNameSortOrder === 'asc' ? 'desc' : 'asc'
      this.sortByCreatedAt = false
      this.fetchIdentities()
    },     
    async initEventSource1() {
      const authUser = await Vue.prototype.$auth.getUser()
      //console.debug('Identities list initEventSource authUser', authUser)

      const token = await Vue.prototype.$auth.getIdToken()

      // eslint-disable-next-line
      this.sse = new EventSource(import.meta.env.VITE_VUE_APP_FORGE_API_URL + '/identities/notifications/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      this.sse.onmessage = (e) => {
        const newMessage = JSON.parse(e.data)
        //console.debug('SSE message', newMessage)
        if (newMessage.type === 'CREATED') {
          this.fetchIdentities()
          showToastSuccess(this.$t('account.identities.sse.newIdentityCreated'))
        } else {
          if (newMessage.type === 'UPDATED') {
            const updatedID = newMessage.identityId
            //console.debug("updatedID", updatedID)
            //console.debug("route.params.identityId", this.$route.params.identityId)
            this.fetchIdentities()
            const routeIdentityID = this.$route.params.identityId
            if (routeIdentityID) {
              if (routeIdentityID === updatedID) { 
                showToastSuccess(this.$t('account.identities.sse.editedIdentityUpdated'))
              } else {
                //dont't show toast because could be annoying in the modal
              }
            } else {
              showToastSuccess(this.$t('account.identities.sse.identityUpdated'))
            }                      
          } else {
            console.debug('Unknown SSE event type', newMessage.type)
          }
        }
      }
    },
    async initEventSource() {
      const authUser = await Vue.prototype.$auth.getUser()
      //console.debug('Identities list initEventSource authUser', authUser)

      const token = await Vue.prototype.$auth.getIdToken()

      // eslint-disable-next-line
      this.sse = new EventSourcePolyfill(import.meta.env.VITE_VUE_APP_FORGE_API_URL + '/identities/notifications/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      this.sse.addEventListener("message", this.sseMessage);       
    },
    sseMessage(e) {
      const newMessage = JSON.parse(e.data)
      //console.debug('SSE message', newMessage)
      if (newMessage.type === 'CREATED') {
        this.fetchIdentities()
        showToastSuccess(this.$t('account.identities.sse.newIdentityCreated'))
      } else 
      if (newMessage.type === 'DELETED') {
        this.fetchIdentities()
        showToastSuccess(this.$t('account.identities.sse.identityDeleted'))
      } else {
        if (newMessage.type === 'UPDATED') {
          const updatedID = newMessage.identityId
          const routeIdentityID = this.$route.params.identityId
          //console.debug("updatedID", updatedID)
          //console.debug("route.params.identityId", routeIdentityID)
          this.fetchIdentities()          
          if (routeIdentityID) {
            if (routeIdentityID === updatedID) { 
              showToastSuccess(this.$t('account.identities.sse.editedIdentityUpdated'))
            } else {
              //dont't show toast because could be annoying in the modal
            }
          } else {
            showToastSuccess(this.$t('account.identities.sse.identityUpdated'))
          }                      
        } else {
          console.debug('Unknown SSE event type', newMessage.type)
        }
      }      
    }

  }
}

// {
//     "id": "0d2e9dc4-7286-4c30-9be5-6215e3fbdc87",
//     "identityName": "asd",
//     "domains": [
//         "www.asdasd.com"
//     ],
//     "name": "Juhász Tamás",
//     "givenName": "Tamás",
//     "familyName": "Juhász",
//     "username": "juhasz.tamas-870622",
//     "email": "juhasz.tamas-870622@gmail.com",
//     "gender": "male",
//     "birthdate": "1999-01-03",
//     "phoneNumber": "+420721482610",
//     "streetAddress": "Rastenfeld 151\nSuite #JUR886",
//     "locality": "Rastenfeld",
//     "postalCode": "3532",
//     "country": "Austria"
// }
</script>

<style scoped>
.bold {
 font-weight: bold;
}
.tutorialCloseButton {
  position: absolute;
  top: 8px;
  right: 8px;
}
.hiddenTutorialButton {
  display: none;
}
</style>
