<template>
    <v-container fluid>
      <v-dialog v-model="visible" max-width="900px" @input="backToParent($event)">
        <v-card v-if="teamInfo" class="px-3 py-3">
          <v-card-title v-if="!loading && teamInfo" primary-title class="justify-left pt-8">
                {{ teamInfo.displayName }}                 
          </v-card-title>
  
          <v-card-text v-if="!loading" class="pt-0 mt-0">
            <v-row>
              <v-col cols="12" sm="6">
                <strong>Member Count:</strong>
                {{ teamInfo.memberCount }}
              </v-col>
              <v-col cols="12" sm="6">
                <strong>Created At:</strong>
                {{ teamInfo.createdAt }}
              </v-col>
            </v-row>            

            <v-row>
              <v-col cols="12" sm="6">
                <strong>Invitation Count:</strong>
                {{ teamInfo.invitationCount }}
              </v-col>
            </v-row>
            
          </v-card-text>  
          <v-card-actions class="pt-3">
            <v-btn class="v-btn-back text-center" color="primary" @click="backClick">
              {{ $t('main.close') }}
            </v-btn>
            <v-btn
                  type="submit"
                  color="success"
                  input-class="ml-0 mr-6"
                >
                  {{ $t('main.update') }}
                </v-btn>
          </v-card-actions>
  
          <v-spacer />
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
    import user from '@/services/api/user.js'
  import teams from '@/services/api/team.js'
  import { showToastSuccess, showToastError, showConfirmationDialog, showConfirmationDialogWithCheckBox } from '@/event-bus'

  
  export default {
    name: 'UpdateTeam', 
  
    data() {
      return {
        visible: false,
        teamInfo: {},
        underEdit: '',
        btnLoading: false,
        loading: true,
        updatingIdentity: false,
        displayName: '',
        displayNameInvalidAnimation: false,
        userDataFormValid: false,

        minCharacters: 1,
        maxCharacters: 255,
        rules: {
          required: (v) => !!v || !isNaN(parseFloat(v)) || this.$t('validation.form.requiredField'),
          pwdsMatches: (value) => value === this.password || this.$t('validation.form.pwdsMustMatcH')
        },
        isEditing: false,

      }
    },
    computed: {
      /*canEdit() {
        return this.mode === 'view' && (this.selectedIdentity && this.selectedIdentity.canEdit)
      }, */    
    },    
    async mounted() {
      this.userMeta = await user.getMyUserMeta()
      console.log('meta', this.userMeta)
      this.teamInfo = await teams.getTeam(this.$route.params.teamId)
      if (!this.teamInfo) {
        showToastError(this.$t('account.identities.identityLoadError'))
        return
      }
      console.log('teamInfo mounted', this.teamInfo)
      
      this.loading = false
      this.visible = true
    },
    /* async beforeMount() {
      console.log('add identy before mounted')
    }, */
    methods: {
      back() {
        this.visible = false
        this.$emit('identityRefresh')
        this.$router.back()
      },
      backClick() {
        this.visible = false
        this.back()
      },
  
      backToParent(e) {
        if (!e) {
          this.$emit('identityRefresh')
          this.$router.go(-1)
        }
      },  
   
      async saveAddress(value, field) {
        this.selectedIdentity = await identities.updateAddress(this.selectedIdentity.id, {
          type: 'GENERATED',
          field,
          value
        }, true)
      },      
    }
  }
  </script>
  
  <style scoped>

  </style>
  