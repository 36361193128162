<template>
    <v-container fluid>
      <v-dialog v-model="visible" max-width="465px" @input="backToParent($event)">
        <v-card class="px-6 py-6">
          <v-card-title primary-title class="text-center pt-8">
            {{ $t('account.teams.addNewIdentityTitle') }}
          </v-card-title>
  
          <v-card-text class="text-center">
            <v-row>
              <v-col :class="{ 'shake-animation': displayNameInvalidAnimation }">
                <v-text-field v-model="displayName" :label="$t('account.teams.displayName')" outlined></v-text-field>
              </v-col>
            </v-row>

          </v-card-text>
  
          <v-card-actions class="pt-14">
            <v-btn class="v-btn-back text-center" @click="backClick">
              {{ $t('main.back') }}
            </v-btn>
            <v-btn color="primary" class="v-btn-done text-center" :disabled="displayName.trim().length === 0" @click="confirm">
              {{ $t('main.save') }}
            </v-btn>
          </v-card-actions>
  
          <v-spacer />
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import teams from '@/services/api/team.js'
  import { showToastSuccess, showToastError } from '@/event-bus'
  
  export default {
    name: 'CreateTeam',
    data() {
      return {
        visible: true,
        displayName: '',
        displayNameInvalidAnimation: false
      }
    },
    methods: {
      cancel() {
        this.back()
      },
      back() {
        this.$router.back()
      },
      backClick() {
        this.visible = false
        this.back()
      },
 
      backToParent(e) {
        if (!e) {
          this.$router.go(-1)
        }
      },
      async confirm() {
        if (!this.displayName.trim().length) {
          this.displayNameInvalidAnimation = true
          setTimeout(() => (this.displayNameInvalidAnimation = false), 500)
          return
        }
  
        const dto = {
          displayName: this.displayName
        } 
  
        try {
          const createdTeam = await teams.createTeam(dto)
          const newID = createdTeam.id
          console.log("createdTeam", createdTeam)
          this.visible = false
          this.$emit('teamRefresh')
          this.$router.replace('/teams/' + newID + '/update')
        } catch (error) {
          console.error(error)
          showToastError(this.$t('account.teams.addNewTeamError'))
        }
      }
    }
  }
  </script>
  