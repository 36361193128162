import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import Vuex from 'vuex'
import vuetify from '../shared/plugins/vuetify'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import createAuth from './services/auth/auth.js'
import i18n from './i18n.js'
import store from './store'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(FloatingVue)
Vue.use(VueCookieAcceptDecline)

FloatingVue.options.themes.tooltip.html = true

const globalOptions = {
  mode: 'international',
  preferredCountries: ['GB', 'US', 'HU'],
  validCharactersOnly: true,
  invalidMsg: 'The specified phone number is invalid',
  dropdownOptions: {
    showSearchBox: true
  },
  inputOptions: {
    required: true
  }
}
Vue.use(VueTelInput, globalOptions) // Define default global options here (optional)

async function main() {
  const auth = await createAuth()
  Vue.prototype.$auth = auth

  if (await auth.getUser()) {
    const usr = await auth.getUser()
    store.commit('setCurrentUser', usr)
    // console.log('auth.getUser', usr)
    try {
      const locale = usr.profile.locale || 'en'
      // console.debug('current locale', locale)
      i18n.locale = locale // Set the new locale
      vuetify.framework.lang.current = locale // update Vuetify's language setting
    } catch {
      i18n.locale = 'en' // Set the new locale
      vuetify.framework.lang.current = i18n.locale // update Vuetify's language setting
    }
  }
  auth.addUserUnloaded(() => store.commit('removeCurrentUser'))
  auth.addUserLoaded(userLoaded)

  const router = createRouter(auth)
  new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

main()

async function userLoaded() {
  // console.log('userLoaded')
  const usr = await Vue.prototype.$auth.getUser()
  store.commit('setCurrentUser', usr)
  // console.log('auth.getUser', usr)
  try {
    const locale = usr.profile.locale || 'en'
    // console.debug('current locale', locale)
    i18n.locale = locale // Set the new locale
    vuetify.framework.lang.current = locale // update Vuetify's language setting
  } catch {
    i18n.locale = 'en' // Set the new locale
    vuetify.framework.lang.current = i18n.locale // update Vuetify's language setting
  }
}
