<template>
  <v-main>
    <v-container fill-height fluid>
      <v-layout column align-center justify-center>
        <v-spacer></v-spacer>
        <h1 class="mb-2 display-1 text-xs-center">{{ $t('welcome.title') }}</h1>
        <v-btn large @click="login">
          {{ $t('welcome.loginOrRegister') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  beforeMount() {
    this.login()
  },
  methods: {
    login() {
      this.$auth.login(this.$route.query.redirect || 'dashboard')
    }
  }
}
</script>
