<template>
  <div class="pa-6">
    <!-- DIALOG -->
    <router-view name="dialog"></router-view>

    <v-btn to="/identities">
      <v-icon
        left
        color="text"
        size="small"
      >
        mdi-chevron-left
      </v-icon>
      {{ $t('main.goToIdentities') }}
    </v-btn>

    <!-- CONTENT -->
    <v-banner
      class="mb-6"
      icon="mdi-message-text"
    >
      {{ dynamicTitle }}
    </v-banner>

    <v-row
      justify="start"
    >
      <v-col>
        <v-list>
          <v-list-item
            v-for="(smsItem, index) in smsItems"
            :key="index"
            class="rounded-box"
          >
            <v-list-item-content>
              <v-row>
                <v-col cols="auto" class="from-text">{{ smsItem.from }}</v-col>
                <v-col cols="auto" class="date-text">{{ formatDate(smsItem.createdAt) }}</v-col>
                <v-col cols="auto" class="text-message">{{ smsItem.text }}</v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-btn v-if="showLoadMore" class="mt-3" @click="loadNextPage"><v-icon>mdi-chevron-right</v-icon>{{ $t('main.loadMore') }}</v-btn>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import sms from '@/services/api/sms.js'
import debounce from 'lodash.debounce'
import { EventSourcePolyfill } from 'event-source-polyfill';

export default {

  name: 'SharedSMSWall',

  data() {
    return {
      searchSMS: null,
      nextpagetoken: null,
      messageVisible: false,
      selectedMessage: null,
      phoneNumber: '',
      loadingItems: false,
      locale: '',
      sse: null,
      smsItems: []
    }
  },
  computed: {
    showLoadMore() {
      return this.nextpagetoken !== undefined && this.nextpagetoken !== null
    },
    dynamicTitle() {
    // Use the $t method to get the translation and interpolate the dynamic phone number
      return this.$t('sms.title', { phoneNumber: this.phoneNumber })
    }
  },

  watch: {
    searchSMS() {
      this.searchIdentityWatcher()
    }
  },
  async beforeMount() {

  },
  beforeDestroy() {
    if (this.sse) {
      this.sse.close()
    }
  },
  async mounted() {
    this.phoneNumber = this.$route.params.phoneNumber
    // console.log('this.phoneNumber', this.phoneNumber)
    const usr = await Vue.prototype.$auth.getUser()
    this.locale = usr.profile.locale || 'en'
    // console.log('current locale', this.locale)
    await this.fetchSMSs()
    this.initEventSource(this.phoneNumber)
  },
  methods: {
    searchSMSWatcher: debounce(function() {
      this.fetchSMSs()
    }, 500),

    async fetchSMSs() {
      const pageReq = {
        maxResults: 5
      }
      pageReq.nextPageToken = this.nextpagetoken || null
      const resp = await sms.getSMSItems(this.phoneNumber, undefined, pageReq)
      // console.log('resp', resp)
      this.smsItems = resp.items
      // console.log(this.smsItems)
      this.nextpagetoken = resp.nextPageToken
    },

    async  loadNextPage() {
      this.page++
      const pageReq = {
      }
      pageReq.pageToken = this.nextpagetoken || null
      const resp = await sms.getSMSItems(this.phoneNumber, pageReq)
      const newItems = resp.items
      this.smsItems = this.smsItems.concat(newItems)
      this.nextpagetoken = resp.nextPageToken
      // console.log(this.smsItems)
    },
    formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      return new Date(dateString).toLocaleDateString(this.locale, options)
    },
    async initEventSource(phoneNumber) {
      const authUser = await Vue.prototype.$auth.getUser()
      console.debug('SMSWall initEventSource authUser', authUser)

      const token = await Vue.prototype.$auth.getIdToken()

      // eslint-disable-next-line
      this.sse = new EventSourcePolyfill(import.meta.env.VITE_VUE_APP_FORGE_API_URL + '/sms/stream/' + phoneNumber, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      this.sse.addEventListener("message", this.sseMessage);       
    },
    sseMessage(e) {
      const newMessage = JSON.parse(e.data)
      console.debug('SSE message', newMessage)
      this.smsItems.unshift(newMessage)
    }

  }
}

/* {
  "items": [
    {
      "id": "1",
      "createdAt": "2023-10-04T09:47:30.747Z",
      "from": "+36705555555",
      "to": "+447401154761",
      "text": "Teszt üzenet"
    }
  ]
} */
</script>

<style scoped>
.rounded-box {
  border-radius: 10px; /* Add rounded corners to the list item */
  margin: 10px 0;
  padding: 10px;
  background-color: #f3f3f3; /* Background color for the list item */
}

.from-text {
  color: #007bff; /* Change text color for "From" */
}

.date-text {
  color: darkgrey; /* Change text color for datetime */
}

.text-message {
  color: black; /* Change text color for message */
}
</style>
