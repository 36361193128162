import createAxios from './api'

const PATH = 'teams'

export default {
  async createTeam(teamDto) {
    const axios = await createAxios(true)
    const resp = await axios.post(PATH, teamDto)
    return resp.data
  },
  async getTeam(id) {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/${id}`)
    return resp.data
  },
  async deleteTeam(id) {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/${id}`)
    return resp.data
  },  
  async inviteMembers(teamId, dto) {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/${teamId}/invitations`, dto)
    return resp.data
  },
  async deleteMember(teamId, userId) {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/${teamId}/members/${userId}`)
    return resp.data
  },
  async listTeams() {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}`)
    return resp.data
  }
}
