import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentUser: null,
    isExtensionInstalled: null
  },

  getters: {
    isSignedIn: state => !!state.currentUser,
    hasRole: state => role => state.currentUser && state.currentUser.profile.forge_roles.includes(role),
    isExtensionInstalled: state => !!state.isExtensionInstalled
  },

  mutations: {
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser
    },

    removeCurrentUser(state) {
      state.currentUser = null
    },

    setisExtensionInstalled(state, value) {
      state.isExtensionInstalled = value
    }

  }
})

export default store
