<template>
  <div>
    <v-banner class="mb-6">
      <div class="display-1 text-center pt-2">{{ $t('warehouse.title') }}</div>
    </v-banner>
    <v-row>
      <v-col md="3">
        <v-tabs
          v-model="activetab"
          vertical
          class="v-tabs-slider-wrapper-right"
        >
          <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.to" exact>
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col>
        <v-container class="pr-8">
          <router-view></router-view>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      activetab: 1,
      tabs: [
        {
          id: '1',
          name: this.$t('warehouse.tabs.createNew'),
          to: '/warehouses/create-warehouse'
        }
      ]
    }
  }
}
</script>

<style>
</style>
