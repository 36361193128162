<template>
    <div class="pa-6">
      <!-- DIALOG -->
      <router-view name="dialog" @teamRefresh="fetchTeams"></router-view>  
 
      <!-- CONTENT -->
      <v-banner
        class="mb-6"
        icon="mdi-account-group"
      >
        {{ $t('account.teams.title') }}
      </v-banner>
  
      <v-row>
        <v-btn color="primary" class="ml-3" outlined to="/teams/add">{{ $t('account.teams.addNewTeamButton') }}</v-btn>
      </v-row> 

      
      <v-row v-if="teams"
        justify="start"
        class="mt-6"
      >
        <v-col
          v-for="(i,index) in teams" :key="index"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="3"
        >
          <v-card height="100%" min-height="200" cols="2" class="clickable-card d-flex flex-column" @click="redirectToUpdatePage(i.id, $event)">
            <v-card-title>
              {{ i.displayName }}
            </v-card-title>
  
            <v-card-subtitle>
              <div>{{ $t('account.teams.memberCount', {memberCount: i.memberCount ? i.memberCount : 0}) }}</div>
              <div>{{ $t('account.teams.invitationCount', {invitationCount: i.invitationCount ? i.invitationCount : 0}) }}</div>
            </v-card-subtitle>

            <v-spacer></v-spacer>
  
            <v-card-actions class="justify-end mb-2 mr-2">
              <v-label>
                {{ $t('account.identities.dialog.createdAt', {createdAt: formatDate(i.createdAt ? i.createdAt : null)}) }}
              </v-label>
              <!-- update identity -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    x-small
                    fab
                    ribble
                    v-bind="attrs"
                    class="mx-2"
                    :to="'/teams/' + i.id + '/update'"
                    v-on="on"
                  >
                    <v-icon color="grey" v-on="on">mdi-id-card</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('account.teams.showDetailsTooltip') }}</span>
              </v-tooltip>
              <!-- delete -->
              <v-btn
                v-tooltip="$t('main.delete')"
                x-small
                fab
                ribble
                color="red"
                @click.stop="deleteTeam(index)"
              >
                <v-icon color="white">mdi-delete-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </template>
  
  <script>
  import Vue from 'vue'
  import teams from '@/services/api/team.js'
  import user from '@/services/api/user.js'
  import { showToastSuccess, showToastError, showConfirmationDialog } from '@/event-bus'
  import debounce from 'lodash.debounce'
  import { EventSourcePolyfill } from 'event-source-polyfill';
  
  export default {
  
    name: 'Teams',
  
    data() {
      return {        
        teams: [],
        locale: 'en'
      }
    },
    computed: {
    },  
    /*async beforeMount() {
      await this.fetchTeams()
    },*/
    async mounted() {
      const usr = await Vue.prototype.$auth.getUser()
      // console.log(usr)
      this.locale = usr.profile.locale || 'en'

      await this.fetchTeams()
    },

    methods: { 
      async fetchTeams() {
        this.teams = await teams.listTeams()
        console.debug("teams", this.teams)   
      },
      async deleteTeam(index) {
        const conf = await showConfirmationDialog(this.$t('account.teams.confirmDelete'), this.$t('account.teams.deleteDetails'))
        if (!conf) {
          this.btnLoading = false
          return
        }
  
        try {
          await teams.deleteTeam(this.teams[index].id)
          showToastSuccess(this.$t('account.teams.deleteSucces'))
          await this.fetchTeams()
        } catch (error) {
          console.error(error)
          showToastError(this.$t('account.teams.errorWhileUpdating'))
        } finally {
          this.btnLoading = false
        }
      },
      formatDate(dateString) {
        if (!dateString) {
          return ''
        }
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }
        try
        {
          return new Date(dateString).toLocaleDateString(this.locale, options)
        } catch (error) {
          console.error("date error", dateString)
          return dateString
        }        
      },
      redirectToUpdatePage(id, event) {      
        this.$router.push('/teams/' + id + '/update')
        // }
      }     
    }
  }   
</script>