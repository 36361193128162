import { Log, UserManager, WebStorageStateStore } from 'oidc-client'

class Auth {
  constructor() {
    const url = window.location.origin
    /* Don't use this in prod:
    Log.Level = Log.DEBUG
    Log.logger = console */
    this.userManager = new UserManager({
      authority: `${import.meta.env.VITE_VUE_APP_FORGE_API_URL}/openid`,
      client_id: 'forge',
      redirect_uri: `${url}/forge-login-callback`,
      silent_redirect_uri: `${url}/forge-login-callback-silent.html`,
      post_logout_redirect_uri: `${url}`,
      response_type: 'code',
      scope: 'openid',
      // prompt: 'login',
      monitorSession: false,
      userStore: new WebStorageStateStore({ store: window.localStorage })
    })

    this.userManager.metadataService.getEndSessionEndpoint().then(url => { this.canEndSession = !!url })
    this.userManager.clearStaleState()
  }

  async getUser() {
    const usr = await this.userManager.getUser()
    return usr
  }

  async login(state) {
    await this.userManager.signinRedirect({ state })
  }

  async handleLoginCallback() {
    const user = await this.userManager.signinRedirectCallback()

    return user.state
  }

  async logout() {
    await this.userManager.revokeAccessToken()
    await this.userManager.removeUser()
    // if (this.canEndSession) {
    //   await this.userManager.signoutRedirect()
    // } else {
    //   await this.userManager.removeUser()
    // }
  }

  async getAccessToken(forceRefresh = false) {
    const user = await this._getRefreshingUser(forceRefresh)
    return user.access_token
  }

  async getIdToken(forceRefresh = false) {
    const user = await this._getRefreshingUser(forceRefresh)
    return user.id_token
  }

  addUserLoaded(func) {
    this.userManager.events.addUserLoaded(func)
  }

  addUserUnloaded(func) {
    this.userManager.events.addUserUnloaded(func)
  }

  async _getRefreshingUser(forceRefresh = false) {
    let user = await this.userManager.getUser()
    if (forceRefresh || user.expired) {
      try {
        user = await this.userManager.signinSilent()
      } catch (error) {
        await this.userManager.removeUser()
        throw error
      }
    }

    return user
  }
}

async function createAuth() {
  return new Auth()
}

export default createAuth
