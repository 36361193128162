import createAxios from './api'
import { showToastError, showOTPDialog } from '@/event-bus'
import i18n from '@/i18n.js'

const PATH = 'identities'
const dataDecryptOTPTitle = i18n.t('decryption.dataDecrypt')

export default {
  async retryWithOTP({ axios, otpTitle, method, url, req }) {
    const otp = await showOTPDialog(otpTitle);
  
    if (otp.length === 0) {
      showToastError(i18n.t('decryption.otpNeededForData'));
      return null;
    }
  
    try {
      const config = {
        headers: {
          'X-OTP': otp
        },
        params: {
          decrypt: true
        }
      };
      let resp = undefined
      if (method === 'get') { 
        resp = await axios.get(url, config);
      } else
      if (method === 'put') { 
        resp = await axios.put(url, req, config);
      }
      
      return resp.data;
    } catch (error) {
      const message = error.response?.data?.message || 'Server error';
      console.log('resp after decryption', error.response?.data?.message);
      showToastError(i18n.t('decryption.errorDuringDecryption', { message: message }));
      console.error('decryption error occurred', error);
      return null;
    }
  },

  async generalCall(otpTitle, url, method, decrypt, req) {
    const axiosInstance = await createAxios()   
    const config = {
      params: {
        decrypt
      }
    }

    try {
      let resp = undefined
      if (method === 'get') { 
        resp = await axiosInstance.get(url, config)
      } else
      if (method === 'put') { 
        resp = await axiosInstance.put(url, req, config)
      }

      //console.log("axios resp data", resp.data)
      return resp ? resp.data : undefined
    } catch (error) {
      if (decrypt && error.response?.status === 403 && error.response?.data?.code === 'OTP_NEEDED') {
        return this.retryWithOTP({
          axios: axiosInstance,
          otpTitle,
          method: method,
          url: url,
          req
        })
      }
  
      throw error
    }
  },

  // TODO: may need renaming
  async changePasswordDomainIdentity(id, newPwd) {
    const axios = await createAxios(true)
    await axios.put(`${PATH}/${id}/password`, { password: newPwd })
  },

  async getUseIdentityForDomain(domain) {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/selected/${domain}`)
    return resp.data
  },

  async getUserIdentities(q, pageRequest) {
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/self`,
      {
        params: {
          q,
          ...pageRequest
        }
      })
    return resp.data
  },

  async getUserIdentity(id) {
    if (!id) {
      return undefined
    }
    const axios = await createAxios(true)
    const resp = await axios.get(`${PATH}/${id}`)
    return resp.data
  },

  async deleteIdentity(id) {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/${id}`)
    return resp.data
  },

  async createDomainIdentity(domainIdentity) {
    const axios = await createAxios()
    const resp = await axios.post('identities', domainIdentity)
    return resp.data
  },

  async updateName(id, req, decrypt = false) {    
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/name`, 'put', decrypt, req)
  },

  async updateUsername(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/username`, 'put', decrypt, req)
  },

  async updateEmail(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/email`, 'put', decrypt, req)
  },

  async updatePhoneNumber(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/phoneNumber`, 'put', decrypt, req)    
  },

  async updatePassword(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/password`, 'put', decrypt, req)
  },

  async updateGender(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/gender`, 'put', decrypt, req)
  },

  async updateBirthdate(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/birthdate`, 'put', decrypt, req)
  },

  async updateAddress(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/address`, 'put', decrypt, req)
  },

  async updateImage(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/image`, 'put', decrypt, req)
  },

  async updateDisplayName(id, req, decrypt = false) {
    return await this.generalCall(dataDecryptOTPTitle, `identities/${id}/displayName`, 'put', decrypt, req)
  },

  async listMyDomainIdentities(q, domain, pageRequest) {
    const axios = await createAxios(true, false)
    const resp = await axios.get('identities/self', {
      params: {
        q,
        domain,
        ...pageRequest
      }
    })

    return resp.data
  },

  async selectDomainIdentity(id, req) {
    const axios = await createAxios()
    const resp = await axios.put(`identities/${id}/select`, req)
    return resp.data
  },

  async getSelectedDomainIdentity(domain) {
    const axios = await createAxios()
    try {
      const resp = await axios.get(`identities/selected/${domain}`)
      return resp.data
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return null
      }

      throw error
    }
  },  

  async getIdentity(otpTitle, id, decrypt = false) {
    return await this.generalCall(otpTitle, `identities/${id}`, 'get', decrypt)    
  },

  async deleteDomain(id, domain) {
    const axios = await createAxios(true)
    const resp = await axios.delete(`${PATH}/${id}/domains/${domain}`)
    return resp.data
  },

  async addDomain(id, req) {
    const axios = await createAxios(true)
    const resp = await axios.post(`${PATH}/${id}/domains`, req)
    return resp.data
  },
  
  async importPasswordIdentities(formData) {
    const axios = await createAxios(true)

    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        // Add the required headers for multipart/form-data
        if (config.headers['Content-Type'] && config.headers['Content-Type'].startsWith('multipart/form-data')) {
          config.headers['Content-Type'] = 'multipart/form-data; boundary="boundary"';
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    )

    const resp = await axios.post(
      `${PATH}/import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;boundary="boundary"',
        },
      }
    )

    return resp.data
  },

}
