<template>
  <v-container fluid>
    <v-dialog v-model="visible" max-width="465px" @input="backToParent($event)">
      <v-card class="px-6 py-6">
        <v-card-title primary-title class="text-center pt-8">
          {{ $t('account.identities.addNewIdentity.title') }}
        </v-card-title>

        <v-card-text class="text-center">
          <v-row>
            <v-col :class="{ 'shake-animation': displayNameInvalidAnimation }">
              <v-text-field v-model="displayName" :label="$t('account.identities.addNewIdentity.displayName')" outlined></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-text-field v-model="domain" :label="$t('account.identities.addNewIdentity.domain')"></v-text-field>
            </v-col>
          </v-row> -->
          <!-- temporary team hide -->
          <!-- <v-row v-if="userMeta.teams.length">
            <v-col>
              <v-select v-model="teamId" label="Team">
                <v-list-item value="NONE">None</v-list-item>
                <v-list-item v-for="team in userMeta.teams" :key="team.id" :value="team.id">{{ team.label }}</v-list-item>
              </v-select>
            </v-col>
          </v-row> -->
        </v-card-text>

        <v-card-actions class="pt-14">
          <v-btn class="v-btn-back text-center" @click="backClick">
            {{ $t('main.back') }}
          </v-btn>
          <v-btn color="primary" class="v-btn-done text-center" :disabled="displayName.trim().length === 0" @click="confirm">
            {{ $t('main.save') }}
          </v-btn>
        </v-card-actions>

        <v-spacer />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import user from '@/services/api/user.js'
import identities from '@/services/api/identities.js'
import { showToastSuccess, showToastError } from '@/event-bus'

export default {
  name: 'CreateIdentity',
  data() {
    return {
      visible: true,
      displayName: '',
      domain: '',
      displayNameInvalidAnimation: false,
      teamId: 'NONE',
      userMeta: {
        teams: []
      }
    }
  },
  async mounted() {
    this.userMeta = await user.getMyUserMeta()
  },
  methods: {
    cancel() {
      this.back()
    },
    back() {
      this.$router.back()
      // this.$router.go(0)
      // this.back()
    },
    backClick() {
      this.visible = false
      this.back()
    },

    backToParent(e) {
      if (!e) {
        this.$router.go(-1)
      }
    },
    async confirm() {
      if (!this.displayName.trim().length) {
        this.displayNameInvalidAnimation = true
        setTimeout(() => (this.displayNameInvalidAnimation = false), 500)
        return
      }

      const identity = {
        displayName: this.displayName
      }
      /* if (this.domain && this.domain.length > 0) {
        identity.domain = this.domain
      } */

      if (this.teamId !== 'NONE') {
        identity.teamId = this.teamId
      }

      try {
        const createdIdentity = await identities.createDomainIdentity(identity)
        const newID = createdIdentity.id
        console.log(createdIdentity, createdIdentity.id)
        this.visible = false
        this.$emit('identityRefresh')
        this.$router.replace('/identities/' + newID + '/update')
      } catch (error) {
        console.error(error)
        showToastError(this.$t('account.identities.addNewIdentityError'))
      }
    }
  }
}
</script>
